<h2 mat-dialog-title>Table Settings</h2>
<mat-dialog-content class="mat-typography">
    <h3>Column View Controls</h3>
    <mat-list role="list" class="view-list">
        <mat-list-item role="listitem" *ngFor="let col of viewCols">
            <mat-checkbox [(ngModel)]="col.checked">{{col.header}}</mat-checkbox>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="resetSettings()">Reset</button>
    <button mat-button (click)="saveSettings()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
import { Component, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { GlobalScope } from "../../services/GlobalScope";
import { Subscription } from "rxjs";
import { OptionsService } from "../../services/Options";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
    selector: 'header',
    templateUrl: 'header.html',
    styleUrls: ['./header.css']
})
export class Header implements OnDestroy {

    timeIntervals = [{label: "10s", value: 10000}, {label: "30s", value: 30000}, {label: "1m", value: 60000}];
    @Input()
    public title: string;
    @Input()
    public opened: boolean;

    @Input()
    public logoutDisabled: boolean;

    @Input()
    public eventsEnabled: boolean;

    @Input()
    public autoRefreshEnabled: boolean;

    @Input()
    public eventCount: number;

    @Input()
    public events:any[];

    @Input()
    public username: string;

    @Output()
    public onSideToggle: EventEmitter<any> = new EventEmitter();
    @Output()
    public onLogout: EventEmitter<any> = new EventEmitter();
    loading:boolean;
    
    subscription: Subscription;
    autoRefresh: boolean = false;
    timer: any;
    interval: number;
    visible: boolean = true;

    constructor(public route: ActivatedRoute, public router: Router, public options: OptionsService){
        var me = this;
        GlobalScope.loading$.subscribe(val => {
            me.loading = val;
        });
        this.interval = parseInt(this.options.getItem(OptionsService.SENET_AUTO_REFRESH_INTERVAL, GlobalScope.refreshInterval));
        //this.autoRefresh = this.options.parseBoolean(this.options.getItem(OptionsService.SENET_AUTO_REFRESH, false));
        GlobalScope.autoRefreshVal = this.autoRefresh;
        if(this.autoRefresh){
            this.setTimer();
        }
        else{
            this.cancelTimer();
        }
    }
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.visible = params.hideNav ==  true ||  params.hideNav ==  "true" ? false : true;
            //console.log(this.visible); 
          }
        );
    }
    toggleOpen(): void {
        this.onSideToggle.emit();
        GlobalScope.sideNavChange.next(this.opened);
    }

    logout(){
        this.onLogout.emit();
    }

    setTimer(){
        if(!this.autoRefreshEnabled){
            return;
        }
        if(this.timer != undefined){
            return;
        }
        console.log("timer started: " + this.interval);
        this.timer = setInterval(() => {
            GlobalScope.autoRefresh.next(this.autoRefresh);
        }, this.interval);
    }

    cancelTimer(){
        if(this.timer){
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    setTimeInterval(interval){
        this.interval = interval;
        this.options.setItem(OptionsService.SENET_AUTO_REFRESH_INTERVAL, this.interval);
        this.cancelTimer();
        if(this.autoRefresh){
            GlobalScope.autoRefresh.next(this.autoRefresh);
            this.setTimer();
        }
    }

    toggleAutoRefresh(){
        this.autoRefresh = !this.autoRefresh;
        GlobalScope.autoRefreshVal = this.autoRefresh;
        this.options.setItem(OptionsService.SENET_AUTO_REFRESH, this.autoRefresh);
        if(this.autoRefresh){
            GlobalScope.autoRefresh.next(this.autoRefresh);
            this.setTimer();
        }
        else{
            this.cancelTimer();
        }
    }

    ngOnDestroy(){
        this.cancelTimer();
    }

    navigateToLink(link){
        this.router.navigateByUrl(link).catch((e) => {
            console.log(e);
        });
    }
}
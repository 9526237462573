import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface LookUp {
    id: number;
    collectionId: string;
    entryId: string;
    value: any;
    created: string;
    updated: string;
}
@Injectable()
export class AESLookUpService implements DataTableSearchService<LookUp>, CRUDService<LookUp>{

    constructor(private http: HttpClient) { }

    create(object: LookUp): Observable<Message> {
        const href = 'lookup';
        var requestUrl = `${href}`

        return this.http.post<Message>(requestUrl, object);
    }
    edit(object: LookUp): Observable<Message> {
        const href = 'lookup';
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object);
    }
    delete(object: LookUp): Observable<Message> {
        const href ='lookup';
        var params = new HttpParams().set("id", object.id);
        var requestUrl = `${href}`

        return this.http.delete<Message>(requestUrl, {
            params : params
        });
    }
    get(id): Observable<LookUp> {
        const href = 'lookup';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id.toString());
        }

        return this.http.get<LookUp>(requestUrl, {
            params : params
        });
    }
    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any, columns: any): Observable<SenetData<LookUp>> {
        const href = 'lookup';
        
        var offset = page * limit
        var requestUrl = `${href}`;

        var params = new HttpParams();

        if(sort != undefined && sort != ""){
            params = params.set("sort", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortDir", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }
        if (columns != undefined) {
            params = params.set("columns", columns);
        }
        if(customFilter && customFilter.id != undefined){
            params = params.set("id", customFilter.id);
        }   
        return this.http.get<SenetData<LookUp>>(requestUrl, {
            params : params
        });
    }
}
import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface SystemSettings {
    id: string;
    acctId: number;
    value : any;
    username: string;
}
@Injectable()
export class AESSystemSettingsService implements DataTableSearchService<SystemSettings>, CRUDService<SystemSettings>{

    constructor(private http: HttpClient) { }

    create(object: any): Observable<Message> {
        const href = 'settings';
        var params = new HttpParams();
        var requestUrl = `${href}`

        return this.http.post<Message>(requestUrl, object, {
            params : params
        });
    }
    edit(object: any): Observable<Message> {
        const href = 'settings';
        var params = new HttpParams();
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object, {
            params : params
        });
    }
    get(id): Observable<SystemSettings> {
        const href = 'settings';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id.toString());
        }

        return this.http.get<SystemSettings>(requestUrl, {
            params : params
        });
    }

    delete(object: SystemSettings): Observable<Message> {
        const href = 'settings';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(object != undefined){
            params = params.set("id", object.id.toString());
            if(object.acctId != undefined){
                params = params.set("acctId", object.acctId.toString());
            }
            if(object.username != undefined){
                params = params.set("username", object.username.toString());
            }
        }

        return this.http.delete<Message>(requestUrl, {
            params : params
        });
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any): Observable<SenetData<SystemSettings>> {
        const href = 'settings';
        var offset = page * limit
        var requestUrl = `${href}`;

        var params = new HttpParams();

        if(sort != undefined && sort != ""){
            params = params.set("sort", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortDir", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }

        if(customFilter && customFilter.acctId != undefined){
            params = params.set("acctId", customFilter.acctId);
        }

        return this.http.get<SenetData<SystemSettings>>(requestUrl, {
            params : params
        });
    }
    getItemFromServer(id : string) : Observable<any>{
        const href = 'settings';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id);
        }
        return this.http.get<any>(requestUrl, { params : params });
    }    
    getItem(id : string, defaultVal: any, callback){
        var sub = this.getItemFromServer(id);
        sub.subscribe((resp) => {
            var val = resp['value'];
            if(val == undefined){
                val = defaultVal;
            }
            callback(val);
        }, (err) =>{
            callback(defaultVal);
        }
        );
    }    
}
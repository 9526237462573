import { Component, Input, OnInit, Host } from "@angular/core";
import { SQLConnection } from "src/services/AESSQLConnectionService";
import { DataTableSearchService, DataTableOptions } from "aes-datatables";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Utils } from "src/services/Utils";
import { ColumnConfig } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { DetailsNavView } from "aes-common";

@Component({
    selector: 'connection-info-panel',
    templateUrl: 'connectiondetails_Info.html',
    styleUrls: ['./connectiondetails_Info.scss']
})
export class ConnectionDetailsInfoPanel implements OnInit {

    dtOptions:DataTableOptions  = {pageSize : 100};

    @Input()
    public connection: SQLConnection;


    @Input()
    public id: any;

    gapSize: string = "10px";

    httpClient : DeviceEvents

    columnCfgs: ColumnConfig[];

    buttons: ButtonConfig[] = [];

    parent: DetailsNavView;

    constructor(public http: HttpClient,@Host() parent: DetailsNavView){
        this.parent = parent;
    }

    ngOnInit(){
        if(this.id == "Devices"){
            this.columnCfgs = [{
                header: "Device",
                field: "devEui",
                defaultSort: true,
            }, {
                header: "Last Uplink Time",
                field: "lastUplinkTime",
                renderContent: function (col: ColumnConfig, element: any) {
                    var date = new Date(element.lastUplinkTime);
                    return date.toLocaleString();
                }
            },{
                header: "Type",
                field: "devType",
                renderContent: Utils.renderContent
            },{
                header: "Name",
                field: "name",
                visible: true,
                renderContent: Utils.renderContent
            },{
                header: "Created",
                field: "dateCreated",
                visible: false,
                renderContent: Utils.renderContent
            },{
                header: "Owner",
                field: "owner",
                visible: false,
                renderContent: Utils.renderContent
            },{
                header: "Application",
                field: "application",
                visible: true,
                renderContent: Utils.renderContent
            },{
                header: "Profile",
                field: "deviceProfileName",
                filterable: true,
                visible: true,
                renderContent: function (col: ColumnConfig, element: any) {
                    if(element.deviceProfileName){
                        return element.deviceProfileName
                    }
                    return element.deviceProfile
                }
            },{
                header: "Profile ID",
                field: "deviceProfile",
                filterable: true,
                visible: false,
                renderContent: Utils.renderContent
            },{
                header: "Location",
                field: "deviceLocation",
                visible: true,
                renderContent: Utils.renderContent
            },{
                header: "Device Class",
                field: "deviceClass",
                visible: true,
                renderContent: Utils.renderContent
            },{
                header: "Tags",
                field: "tags",
                visible: true,
                renderContent: Utils.renderContent
            }];
        }
        else{
            this.columnCfgs = [{
                header: "Time",
                field: "txtime",
                defaultSort: true,
                renderContent: function(col: ColumnConfig, element: any){
                    var date = new Date(element.txtime);
                    return date.toLocaleString();
                }
            },{
                header: "Device",
                field: "devEui",
                renderContent: Utils.renderContent
            },{
                header: "Type",
                field: "devType",
                renderContent: Utils.renderContent
            },{
                header: "Group",
                field: "group",
                visible: false,
                renderContent: Utils.renderContent
            },{
                header: "Location",
                field: "deviceLocation",
                visible: true,
                renderContent: Utils.renderContent
            },{
                header: "Device Class",
                field: "deviceClass",
                visible: true,
                renderContent: Utils.renderContent
            }];
        }
        this.httpClient = new DeviceEvents(this.http, this.id, this.connection.connectionId);
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

class DeviceEvents implements DataTableSearchService<any>{

    key: string;
    connId: number
    http: HttpClient

    constructor(http: HttpClient, key, connId) { 
        this.key = key;
        this.connId = connId;
        this.http = http;
    }

    search(sort: string, order: string, page: number, search: string, limit: number): Observable<SenetData<any>> {
        var href = '/connections/devices';
        if(this.key == "Events"){
            href += "/events";
        }
        var offset = page * limit
        var requestUrl = `${href}?connectionType=SQL&connectionId=${this.connId}`;
        if(sort != undefined && sort != ''){
            requestUrl += `&sort=${sort}`;
        }
        if(!isNaN(limit)){
            requestUrl += `&limit=${limit}`;
        }
        if(order != undefined && order != ''){
            requestUrl += `&sortDir=${order}`;           
        }
        if(!isNaN(offset)){
            requestUrl += `&offset=${offset}`;
        }
        if (search != undefined && search != "") {
            requestUrl += `&search=${search}`;
        }

        return this.http.get<SenetData<any>>(requestUrl);
    }
}
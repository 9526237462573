import { Component } from "@angular/core";
import { DetailsViewReg } from "aes-common";
import { DetailsConfig } from "aes-common";
import { ActivatedRoute } from "@angular/router";
import { AESSQLConnectionService, SQLConnection } from "src/services/AESSQLConnectionService";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: 'connectiondetails',
    templateUrl: 'connectiondetails.html',
    styleUrls: ['./connectiondetails.scss']
})
export class ConnectionDetails {
    config:DetailsConfig;
    keyToIcon = {"Devices": "devices_other", "Events": "event"}
    connectionId: number;
    connection: SQLConnection;
    objectKeys = Object.keys;
    constructor(private route: ActivatedRoute, public httpClient: AESSQLConnectionService,  public snackBar: MatSnackBar){
    }

    ngOnInit() {
        this.connectionId = parseInt(this.route.snapshot.params["connectionId"]);
        this.httpClient.get(this.connectionId).subscribe(res => {
            this.connection = res;
            this.constructViews();
        }, err =>{
            this.openSnackBar("Unable to load connection: " + this.connectionId, undefined);
        });
    }

    constructViews(){
        var views = [];
        var testView = new DetailsViewReg("Devices", "Devices", this.keyToIcon["Devices"]);
        views.push(testView);
        testView = new DetailsViewReg("Events", "Events", this.keyToIcon["Events"]);
        views.push(testView);
        this.config = new DetailsConfig("connections", "Connection Details", views);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }
}
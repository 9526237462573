<h2 mat-dialog-title>{{editType}} Account</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <mat-tab-group>
        <mat-tab label="Account Data" class="account-tab">
            <form fxLayout="column" fxLayoutGap="10" #accountForm="ngForm" class="account-edit-form">
                <mat-form-field>
                    <input [disabled]="inProgress || editType == 'Edit'" matInput placeholder="Account ID" type="number" required [(ngModel)]="instance.acctId" name="account-edit-acctId-field">
                </mat-form-field>
                <mat-form-field>
                    <input [disabled]="inProgress" matInput placeholder="Name" required [(ngModel)]="instance.name" name="account-edit-name-field">
                </mat-form-field>
                <mat-form-field>
                    <input [disabled]="inProgress" matInput placeholder="Description" [(ngModel)]="instance.description" name="account-edit-description-field">
                </mat-form-field>
                <mat-form-field *ngIf="editType == 'Edit'">
                    <mat-select [disabled]="inProgress" placeholder="Connection" (ngModelChange)="handleConnectionChange($event)" [(ngModel)]="instance.defaultConnectionId" name="account-edit-connection-field">
                        <mat-option *ngFor="let connection of connections" [value]="connection.connectionId">
                            {{connection.host}}:{{connection.defaultDb}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-select [disabled]="inProgress" placeholder="SMTP Connection" [(ngModel)]="instance.smtpConnectionId" name="account-edit-smtpconnection-field">
                        <mat-option *ngFor="let stmpconnection of smtpConnections" [value]="instance.smtpConnectionId">
                            {{stmpconnection.host}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field  *ngIf="editType == 'Edit'">
                    <mat-select [disabled]="inProgress" placeholder="Default Device Profile" [(ngModel)]="instance.defaultDeviceProfileId" name="account-edit-deviceProfile-field">
                        <mat-option *ngFor="let profile of profiles" [value]="profile.id">
                            {{profile.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </mat-tab>
        <mat-tab label="Default Connection" class="account-tab">
            <form fxLayout="column" fxLayoutGap="10" #connectionForm="ngForm" class="account-edit-form">
                <mat-form-field>
                    <input [disabled]="inProgress" matInput placeholder="Host" required [(ngModel)]="connection.host" name="connection-edit-host-field">
                </mat-form-field>
                <mat-form-field>
                    <input [disabled]="inProgress" matInput placeholder="Database" required [(ngModel)]="connection.defaultDb" name="connection-edit-database-field">
                </mat-form-field>
                <mat-checkbox *ngIf="editType == 'Create'" [disabled]="inProgress" name="connection-edit-create-db" [(ngModel)]="connection.createDb">Create Database?
                </mat-checkbox>
                <mat-form-field>
                    <input  [disabled]="inProgress" matInput placeholder="Username" [(ngModel)]="connection.user" name="connection-edit-user-field">
                </mat-form-field>
                <mat-form-field>
                    <input [disabled]="inProgress" matInput placeholder="Password" type="password" [(ngModel)]="connection.password" name="connection-edit-password-field">
                </mat-form-field>
                <mat-form-field>
                    <input [disabled]="inProgress" matInput placeholder="Port" type="number" [(ngModel)]="connection.port" name="connection-edit-port-field">
                </mat-form-field>
                <mat-checkbox  *ngIf="editType == 'Create'" [disabled]="inProgress" name="connection-edit-create-dm" [(ngModel)]="connection.createTable">Create Tables From Database?
                </mat-checkbox>
            </form>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
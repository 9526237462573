<detailsview [config]="config" *ngIf="connection != undefined">
    <detailsimg>
        <mat-icon class="connection-img">power</mat-icon>
    </detailsimg>
    <detailsinfopnl id="info">
        <table class="info-table">
            <tr>
                <td>ID:</td>
                <td>{{connection.connectionId}}</td>
            </tr>
            <tr>
                <td>Host:</td>
                <td>{{connection.host}}</td>
            </tr>
            <tr>
                <td>Created:</td>
                <td>{{connection.creationDate}}</td>
            </tr>
            <tr *ngIf="connection.user">
                <td>User:</td>
                <td>{{connection.user}}</td>
            </tr>
            <tr *ngIf="connection.port != undefined">
                <td>Port:</td>
                <td>{{connection.port}}</td>
            </tr>
            <tr *ngIf="connection.defaultDb != undefined">
                <td>Database:</td>
                <td>{{connection.defaultDb}}</td>
            </tr>
            <tr>
                <td>Account:</td>
                <td>{{connection.acctId}}</td>
            </tr>
        </table>
    </detailsinfopnl>
    <div subviews>
    </div>
    <div views>
        <details-nav-view [id]="'Devices'">
            <connection-info-panel [connection]="connection" [id]="'Devices'">
            </connection-info-panel>
        </details-nav-view>
        <details-nav-view [id]="'Events'">
            <connection-info-panel [connection]="connection" [id]="'Events'">
            </connection-info-panel>
        </details-nav-view>
    </div>
</detailsview>
<div *ngIf="parent != undefined && parent.id == parent.selection">
    <div class="container" fxLayout="column" [fxLayoutGap]="gapSize">
        <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">
            <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100">
                <div card-title>
                    Configured State
                </div>
                <div card-body>
                    <datatable tableId="configuredStateTable" [dtOptions]="dtOptions"
                        [data]="data" [columnCfg]="columnCfgs" [tableHeight]="tableHeight"></datatable>
                </div>
            </senetcard>
        </div>
    </div>
</div>
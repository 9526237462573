<div class="container" fxLayout="column" [fxLayoutGap]="gapSize">
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100">
            <div card-title>
                Portal Users
            </div>
            <div card-body>
                <datatable tableId="aesUsersTable" [dtOptions]="dtOptions"
                    [searchService]="httpClient" [buttonCfg]="buttons" [columnCfg]="columnCfgs"></datatable>
            </div>
        </senetcard>
    </div>
</div>
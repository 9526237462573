import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { GlobalScope } from '../services/GlobalScope';

/** Pass untouched request through to the next request handler. */
@Injectable({
  providedIn: 'root'
})
export class SenetInterceptor implements HttpInterceptor {

  constructor(private globalScope:GlobalScope){
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    this.globalScope.startLoading();
    return next.handle(req)
    .pipe(
        finalize(() => {
          this.globalScope.stopLoading();
        })
      );
  }
}
import { Component, ViewChild, HostListener } from "@angular/core";
import { ActivatedRoute, Router} from "@angular/router";
import { AfterViewInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import { MatDialog } from "@angular/material/dialog";
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { AESRemoteFunctionBlocksService } from "../../../../services/AESRemoteFunctionBlocksService";
import { SenetAceEditor, ConfirmDialog } from "aes-common";
import { AESAccountService, Account } from "../../../../services/AESAccountService";
import { Utils } from "../../../../services/Utils";

@Component({
    selector: 'functionblockdetails',
    templateUrl: 'function-block-details.html',
    styleUrls: ['./function-block-details.scss']
})
export class FunctionBlockDetails implements AfterViewInit {
    @ViewChild('fbDetailsCodeEditor', {static: true}) fbCodeEditor!: SenetAceEditor;
    @ViewChild('fbDetailsInitEditor', {static: true}) fbInitEditor!: SenetAceEditor;
    @ViewChild("tabGroup", { static: false }) tabGroup: MatTabGroup;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) { 
        if((event.ctrlKey || event.metaKey) && event.key == 's'){
            event.preventDefault();
            this.save(false);
        }
    }

    instance: any;
    editType: string = "Create";
    titleSuffix: string;
    title: string;
    functionBlockId: string;
    functionBlockFilterId: string;
    inProgress: boolean;
    saveBlocked: boolean;
    error: string;
    mode : string = "ace/mode/javascript";
    isShared : boolean = false;
    types: string[] = AESRemoteFunctionBlocksService.FUNCTION_BLOCK_TYPES;
    codeChanged: boolean = false;
    changed: boolean = false;
    canShow: boolean = false;
    selectedTab: number = 0;
    accounts : Account[];
    //2 Code editors
    firstCodeChangeEvent1: boolean = true;
    firstCodeChangeEvent2: boolean = true;

    functionBlockDefault: string = "system.success();";

    decoderDefault: string = "system.success();";
    gapSize: string = "10px";
    private canGoBack: boolean;
    private oldInst: any;

    constructor(public dialog: MatDialog, private route: ActivatedRoute, public serve : AESRemoteFunctionBlocksService,
        public snackBar: MatSnackBar, public titleService: Title, private router: Router, private location: Location, public accountSearch : AESAccountService) {
        let selectedUrlTab = Number.parseInt(this.route.snapshot.queryParamMap.get('tab'));
        this.functionBlockId = this.route.snapshot.params["id"];
        this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);
        this.selectedTab = selectedUrlTab >= 0 ? selectedUrlTab : 0;
        this.instance = {};
        var acctId = undefined;
        if(this.route.snapshot.params["acctId"]){
            acctId = this.route.snapshot.params["acctId"];
        }

        if(this.functionBlockId == '-1'){
            this.instance.code = this.functionBlockDefault;
            if(this.instance.type == undefined){
                this.instance.type = this.types[0];
            } 
            this.handleTypeChange = this.handleTypeChange.bind(this);          
            this.oldInst = {...this.instance};       
            this.canShow = true;
        }else{
            this.serve.get(this.functionBlockId, acctId).subscribe(res => {
                var inst = res;
                if (inst != undefined) {
                    this.instance = {...inst};
                    this.editType = "Edit";
                    this.isShared = this.instance.acctId == null;
                    this.title = this.instance.name;
                }

                this.titleService.setTitle(this.title);//Overridden if defined in app-routing module.ts
                if(this.instance.name != undefined && this.instance.name != ""){
                    this.titleSuffix = " - " + this.instance.name;
                }
                if(this.instance.type == undefined){
                    this.instance.type = this.types[0];
                }
                this.functionBlockFilterId = this.instance.type + " " + this.instance.id;
                this.handleTypeChange = this.handleTypeChange.bind(this);
                this.oldInst = {...this.instance};          
                this.canShow = true;
            }, err =>{
                this.openSnackBar("Unable to load Function Block: " + this.functionBlockId, undefined);
            }); 
        }
        if(Utils.isAdminUser()){
            this.inProgress = true;
            var me = this;
            accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
            .subscribe(data => {
                me.accounts = data.data;
                me.accounts.splice(0, 0, {"name" : "None", "acctId" : -2, "connectionType" : "", "creationDate" : null, "desciption" : "", "defaultConnectionId": -1});
                if(me.accounts == undefined || me.accounts.length == 0){
                    me.error = "Unable to load accounts, please try again later or contact an administrator.";
                }
                else{
                    me.inProgress = false;
                    if(me.instance.acctId == undefined){
                        me.instance.acctId = data.data[0].acctId;
                    }
                }
            }, (err) => {
                me.error = "Unable to load accounts, please try again later or contact an administrator.";
            });
        }
    }

    isAdmin(){
        return Utils.isAdminUser();
    }

    ngOnInit() {    
    }
    ngAfterViewInit(){
        const tabGroup = this.tabGroup;
        if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
      
        tabGroup.selectedIndex = this.selectedTab;  
    }

    handleBack($event){
        $event.preventDefault();
        this.cancel();
    }
    handleTypeChange(type){
        if(this.editType == "Create"){
            if(type == "FunctionBlock"){
                this.instance.code = this.functionBlockDefault;
            }
            else{
                this.instance.code = this.decoderDefault;
            }
        }
    }
    selectedTabChanged(tabChangeEvent: MatTabChangeEvent){
        let index = tabChangeEvent.index;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {"tab": index},
            replaceUrl: true,
            queryParamsHandling: 'merge'

        });        
    }
    isDirty(){
        if(!this.changed && this.oldInst){
            this.changed = JSON.stringify(this.oldInst) != JSON.stringify(this.instance);
        }
        return this.changed;
    }      
    save(backOnSave:boolean = true) {
        this.error = "";
        if(this.inProgress || this.saveBlocked || !this.isDirty()){
            return;
        }
        if(this.isShared && !this.isAdmin()){
            this.error = "Cannot edit a shared Function Block";
            return;
        }

        let codeAnnErrors = this.fbCodeEditor.getAnnotationErrors();
        let initAnnErrors = this.fbInitEditor.getAnnotationErrors();
        if(codeAnnErrors.length > 0){
            let firstError = codeAnnErrors[0];
            let errLine = firstError.row + 1;
            let errCol = firstError.column + 1;
            let errText = firstError.text;
            this.error = "Code Error: " + errText + " at line " + errLine + " column " + errCol;
            return;
        }
        if(initAnnErrors.length > 0){
            let firstError = initAnnErrors[0];
            let errLine = firstError.row + 1;
            let errCol = firstError.column + 1;
            let errText = firstError.text;
            this.error = "Init Error: " + errText + " at line " + errLine + " column " + errCol;
            return;
        } 

        if(this.instance.id == undefined || this.instance.id == ""){
            this.error = "An ID is required";
            return;
        }
        if(this.instance.code == undefined || this.instance.code == ""){
            this.error = "Code is required";
            return;
        }
        if(this.instance.name == undefined || this.instance.name == ""){
            this.error = "An name is required";
            return;
        }
        if(this.instance.code.indexOf("system.success(") < 0){
            this.error = "A call to system.success() is required to ensure the function block succeeds";
            return;
        }
        if(this.instance.acctId == -2){//Clear account ID
            this.instance.acctId = null;
        }
        var me = this;
        var obs;
        this.inProgress = true;
        if(this.editType == "Create"){
            obs = this.serve.create(this.instance);
        }
        else{
            obs = this.serve.edit(this.instance);
        }

        obs.subscribe(resp => {
            me.oldInst = {...me.instance};
            me.inProgress = false;
            me.codeChanged = false;
            me.changed = false;
            if(backOnSave){
                me.goBack(me);
            }
        }, (err) => {
            me.inProgress = false;
            me.changed = false;            
            console.log(err);
            if(err && err.error && err.error.message){
                me.error = err.error.message;
                if(me.error == "Internal Server Error"){
                   me.error = "Server was unable to process your request";
                }
            }else{
                me.error = JSON.stringify(err);
                if(!me.error){
                    "An unknown server error occurred";
                }
            }
        });
    }
    onCodeChanged(codeId){
        if(!this.firstCodeChangeEvent1 && !this.firstCodeChangeEvent2){
            this.codeChanged = true;
        }
        if(codeId == 'fbCodeEditor'){
            this.firstCodeChangeEvent1 = false;
        }
        if(codeId == 'fbInitEditor'){
            this.firstCodeChangeEvent2 = false;
        }        
    }    
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    } 
    
    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    } 
    cancel(){
        let me = this;
        if(me.changed){
            me.dialog.open(ConfirmDialog, {
                width: '600px',
                data: {
                    "title" : "Unsaved Changes",
                    "message" : "There are unsaved changes, are you sure you want to leave?",
                    "success": function(){
                        me.goBack(me);
                    }
                }
            });             
        }else{
            me.goBack(me);
        } 
    }  
    goBack(me){
        if(me.canGoBack){
            me.location.back();
        }else{
            me.router.navigateByUrl("/configurations/functionblocks",{
                replaceUrl: true
            });
        }
    }     
}
import { DetailsViewReg } from "./detailsview_reg";

export class DetailsConfig{
    title: string;
    viewId: string;
    views: Array<DetailsViewReg>;
    viewMap: any;
    subViews: Array<DetailsViewReg>;
    hideSidenav: boolean;

    constructor(id, title, views, hideSidenav = false){
        this.viewId = id;
        this.title = title;
        this.views = views;
        this.hideSidenav = hideSidenav;
        if(this.views == undefined){
            this.views = [];
        }
        this.subViews = [];
        this.viewMap = {};
        for(var key in this.views){
            var view = this.views[key];
            this.viewMap[view.getId()] = view;
        }
    }

    addView(view: DetailsViewReg){
        if(this.viewMap[view.getId()] == undefined){
            this.viewMap[view.getId()] = view;
            this.views.push(view);
        }
    }

    addSubView(view:DetailsViewReg){
        this.subViews.push(view);
    }

    getSubViews(){
        return this.subViews;
    }

    getTitle(){
        return this.title;
    }

    getViews(): Array<DetailsViewReg>{
        return this.views;
    }
}
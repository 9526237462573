<h2 mat-dialog-title>Column Filters</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <mat-list role="list" class="view-list">
        <mat-list-item role="listitem" *ngFor="let col of columnDefs" [ngClass]="{'time-filter': isColumnTypeDate(col)}" >
            <mat-checkbox *ngIf="!isColumnTypeDate(col)" [(ngModel)]="col.checked"(ngModelChange)="onCheckboxChange($event, col)">{{col.header}}:</mat-checkbox>
            <input *ngIf="isColumnTypeString(col)" class="string-filter" mat-input type="text" [disabled]="!col.checked" [(ngModel)]="col.filter">
            
            <div *ngIf="isColumnTypeDate(col)" class="time-container">
                <mat-list>
                    <mat-list-item class="radio-select-item"> 
                        <mat-checkbox [(ngModel)]="col.checked"(ngModelChange)="onCheckboxChange($event, col)">{{col.header}}:</mat-checkbox>
                        <mat-radio-group [disabled]="!col.checked"  aria-label="Select an option" [(ngModel)]="col.filter.datetimeType">
                            <mat-radio-button value="Relative">Relative</mat-radio-button>
                            <mat-radio-button value="Absolute">Absolute</mat-radio-button>
                        </mat-radio-group>
                    </mat-list-item>                     
                    <mat-list-item class="time-filter-row-absolute" *ngIf="col.filter.datetimeType === 'Absolute'">
                        <mat-list class="absolute-list"  role="list">   
                            <mat-list-item class="time-filter-item" role="listitem">
                                <label>Start:</label>
                                <input class="fullwidth" type="datetime-local"  ngModel [(ngModel)]="col.filter.startDate" [disabled]="!col.checked" >                
                            </mat-list-item>                                           
                            <mat-list-item class="time-filter-item" role="listitem">
                                <label>End:</label>
                                <input class="fullwidth" type="datetime-local"  [disabled]="!col.checked" ngModel [(ngModel)]="col.filter.endDate" >                  
                            </mat-list-item>                       
                        </mat-list> 
                    </mat-list-item>
                    <mat-list-item *ngIf="col.filter.datetimeType === 'Relative'" class="time-filter-row-relative">
                        <mat-list role="list">                 
                            <mat-list-item class="time-filter-item" role="listitem">
                                <label>Last:</label>
                                <input class="halfwidth" type="number" min="0" ngModel [(ngModel)]="col.filter.start" [disabled]="!col.checked" >
                                <select [(ngModel)]="col.filter.startUnits" [disabled]="!col.checked" >
                                    <option *ngFor="let timeUnit of timeUnits" [value]="timeUnit.id">{{timeUnit.label}}</option>
                                </select>
                            </mat-list-item>  
                        </mat-list>
                    </mat-list-item>                    
                </mat-list>                                             
            </div>
            <select [(ngModel)]="col.filter" *ngIf="isColumnTypeList(col)"[disabled]="!col.checked" >
                <option *ngFor="let value of col.listValues" [value]="value">{{value}}</option>
            </select>     
        </mat-list-item>
    </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]>Cancel</button>
    <button mat-button (click)="applyColumnFilters()" cdkFocusInitial>Apply</button>
</mat-dialog-actions>
import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface SQLConnection {
    connectionId: number;
    creationDate: string;
    host: number;
    user: string;
    passwordEnc: string;
    port: number;
    defaultDb: string;
    createTable?:boolean;
    createDb?:boolean;
    acctId: number;
}
@Injectable()
export class AESSQLConnectionService implements DataTableSearchService<SQLConnection>, CRUDService<SQLConnection>{

    constructor(private http: HttpClient) { }

    create(object: SQLConnection): Observable<Message> {
        const href = 'connections';
        var params = new HttpParams().set("connectionType", "SQL");
        var requestUrl = `${href}`

        return this.http.post<Message>(requestUrl, object, {
            params : params
        });
    }
    edit(object: SQLConnection): Observable<Message> {
        const href = 'connections';
        var params = new HttpParams().set("connectionType", "SQL");
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object, {
            params : params
        });
    }
    delete(object: SQLConnection): Observable<Message> {
        const href = 'connections';
        var params = new HttpParams().set("connectionType", "SQL").set("connectionId", object.connectionId.toString());
        var requestUrl = `${href}`

        return this.http.delete<Message>(requestUrl, {
            params : params
        });
    }

    get(connectionId): Observable<SQLConnection> {
        const href = 'connections';
        var requestUrl = `${href}`;
        var params = new HttpParams().set("connectionType", "SQL");
        if(connectionId != undefined){
            params = params.set("connectionId", connectionId.toString());
        }

        return this.http.get<SQLConnection>(requestUrl, {
            params : params
        });
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any): Observable<SenetData<SQLConnection>> {
        const href = 'connections';
        var offset = page * limit
        var requestUrl = `${href}`;

        var params = new HttpParams().set("connectionType", "SQL");

        if(sort != undefined && sort != ""){
            params = params.set("sort", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortDir", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }

        if(customFilter && customFilter.acctId != undefined){
            params = params.set("acctId", customFilter.acctId);
        }

        return this.http.get<SenetData<SQLConnection>>(requestUrl, {
            params : params
        });
    }
}
<h2 mat-dialog-title>Device Data</h2>
<mat-dialog-content id="devdatadialog-content" class="mat-typography">
    <mat-tab-group>
        <mat-tab label="Dynamic State">
            <div class="container" fxLayout="column" [fxLayoutGap]="gapSize">
                <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">
                    <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100">
                        <div card-body>
                            <datatable tableId="eventDynamicStateTable" [dtOptions]="dynamicStateOptions"
                                [data]="dynamicState"  [columnCfg]="dynamicStateColumnCfgs"></datatable>
                        </div>
                    </senetcard>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Configured State">
            <div class="container" fxLayout="column" [fxLayoutGap]="gapSize">
                <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">
                    <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100">
                        <div card-body>
                            <datatable tableId="eventConfiguredStateTable" [dtOptions]="configuredStateOptions"
                                [data]="configuredState"  [columnCfg]="configuredStateColumnCfgs"></datatable>
                        </div>
                    </senetcard>
                </div>
            </div>
        </mat-tab>        
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
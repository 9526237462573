import { Component } from "@angular/core";
import { DetailsViewReg } from "aes-common";
import { DetailsConfig } from "aes-common";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Device, AESDevicesService } from "../../../services/AESDevicesService";
import { DeviceCommandDialog } from "../../../dialogs/device-command/device-command";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DeviceEditDialog } from "../../../dialogs/devicedialog/devicedialog";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'devicedetails',
    templateUrl: 'devicedetails.html',
    styleUrls: ['./devicedetails.scss']
})
export class DeviceDetails {
    config:DetailsConfig;
    keyToIcon = {"Events": "event"}
    devEui: string;
    device: Device;
    objectKeys = Object.keys;

    details = [];

    constructor(private route: ActivatedRoute, public httpClient: AESDevicesService,  public snackBar: MatSnackBar, public dialog: MatDialog, public titleService: Title){
    }

    ngOnInit() {
        this.devEui = this.route.snapshot.params["devEui"];
        let title = "Device Details - " + this.devEui;
        this.titleService.setTitle(title);//Overridden if defined in app-routing module.ts
        this.httpClient.get(this.devEui).subscribe(res => {
            this.device = res.data[0];
            this.constructViews();
        }, err =>{
            this.openSnackBar("Unable to load device: " + this.devEui, undefined);
        });
    }

    lunchSendCommand(){
        const dialogRef = this.dialog.open(DeviceCommandDialog, {
            width: '600px',
            data: {
                "device" : this.device
            }
        });
        var me = this;
        dialogRef.afterClosed().subscribe(() =>{});
    }

    launchEdit(){
        const dialogRef = this.dialog.open(DeviceEditDialog, {
            width: '600px',
            data: {
                "instance" : this.device
            }
        });
        var me = this;
        dialogRef.afterClosed().subscribe(() =>{
            me.httpClient.get(me.devEui).subscribe(res => {
                me.device = res.data[0];
                me.constructViews();
            }, err =>{
                me.openSnackBar("Unable to load device: " + me.devEui, undefined);
            });
        });
    }

    constructViews(){
        var views = [];
        var testView = new DetailsViewReg("Events", "Device Events", "event");
        views.push(testView);
        testView = new DetailsViewReg("Maps", "Device Map", "map");
        views.push(testView);
        testView = new DetailsViewReg("DynamicState", "Dynamic State", "dataset");
        views.push(testView); 
        testView = new DetailsViewReg("ConfiguredState", "Configured State", "hive");
        views.push(testView);               
        this.config = new DetailsConfig("devices", "Device Details", views, false);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    convertDate(d){
        return (new Date(d)).toLocaleString();
    }
}
<div class="contentContainer">
  <div *ngIf="loggedIn" class="contentContainer">
      <header [title]="title" [username]="username" [opened]="opened" [events]="events" [eventCount]="eventCount" (onSideToggle)="toggleOpened()" [eventsEnabled]="true" (onLogout)="logout()" [logoutDisabled]="env.LOGIN_DISABLED" [autoRefreshEnabled]="enableAutoRefresh"></header>
      <sidenav [opened]="opened" >
          <router-outlet *ngIf="!loading"></router-outlet>
          <mat-spinner class="body-loading-spinner" *ngIf="loading"></mat-spinner>
      </sidenav>
  </div>
  <mat-spinner class="body-loading-spinner" *ngIf="!loggedIn && loading"></mat-spinner>
  <div *ngIf="!loggedIn && !loading" class="contentContainer">
      <login (onLogin)="login($event)"></login>
  </div>
</div>
import { Component, Input, HostListener } from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import { ViewRegistration } from "aes-common";
import { ColumnConfig, ColumnDataType, DataTableOptions } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { AESLogsService, Logs } from "../../services/AESLogsService";
import { Utils } from "../../services/Utils";

@Component({
    template: "<a [routerLink]=\"['/diagnostics/logs/details',element.id]\" [queryParams]=\"{'hideNav': hideNav}\">{{renderMsg(element.msg)}}</a>"
})
export class LogDetailsLink{
    @Input()
    element: Logs;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}  
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    }
    renderMsg(msg){
        try{
            if(typeof msg == "object" && msg !== null){
                return JSON.stringify(msg);
            }
        } catch(e){}
        return msg;
    }
}

@Component({
    template: "<a [routerLink]=\"!isAdmin() ? ['/configurations/pipelines/details', element.pipeline] : ['/configurations/pipelines/details', element.id, element.acctId]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.pipeline}}</a>"
})
export class LogPipelineLink{
    @Input()
    element: Logs;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}
    isAdmin(){
        return Utils.isAdminUser();
    }      
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    }   
}
@Component({
    template: "<a [routerLink]=\"['/devices/details',element.device]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.device}}</a>"
})
export class LogDeviceLink{
    @Input()
    element: Logs;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}  
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    }   
}
@Component({
    selector: 'logs',
    templateUrl: 'logs.html',
    styleUrls: ['./logs.scss']
})
export class AESLogs {
    static view = new ViewRegistration("Logs", "/diagnostics/logs", "warning", []);

    dtOptions:DataTableOptions  = {pageSize : 100, columnFiltering: true, filtering: false, showSelectionColumn: false, multiSelect: false};

    columnCfgs: ColumnConfig[] = [{
        header: "ID",
        field: "id",
        visible: false
    },{
        header: "Time",
        field: "time",
        defaultSort: true,
        dataType: ColumnDataType.DATE,
        filterable: true,
        renderContent: function (col: ColumnConfig, element) {
            var date = new Date(element.time);
            return date.toLocaleString();
        }       
    }, {
        header: "Type",
        field: "type",
        filterable: true,
        dataType: ColumnDataType.LIST,
        listValues: ["ERROR","WARN","DEBUG","TRACE"]
    }, {
        header: "Container",
        field: "containerId",
        visible: false
    }, {
        header: "Caller",
        field: "caller",
        visible: false
    }, {
        header: "Line",
        field: "line",
        visible: false
    },{
        header: "Function Block",
        field: "functionBlock",
        visible: false
    }, {
        header: "Request ID",
        field: "requestId",
        visible: false
    }, {
        header: "Pipeline",
        field: "pipeline",
        contentComponent: LogPipelineLink,
        filterable: true
    }, {
        header: "Device",
        field: "device",
        contentComponent: LogDeviceLink,
        filterable: true
    },{
        header: "Application",
        field: "application",
        filterable: true
    },{
        header: "Account",
        field: "acctId",
        filterable: true,
        visible : false
    },{
        header: "Message",
        field: "msg",
        contentComponent: LogDetailsLink,
        filterable: true
    },{
        header: "Detailed Message",
        field: "detailedMsg",
        visible: false
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";
    customFilter : any;

    constructor(public dialog: MatDialog, public httpClient: AESLogsService, private route: ActivatedRoute) {
        if(Utils.isAdminUser() || true){
            this.columnCfgs.splice(2, 0, {
                header: "Customer Visible",
                field: "customerVisible",
                filterable : true
            })
        }
        var me = this;
        this.route.queryParams
            .subscribe(params => {
                me.customFilter = {
                    showSystemDiags : params["showSystemDiags"]
                }
            }
            );
        this.buttons = [];
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AESDevicesService, Device } from "../../services/AESDevicesService";
import { Utils } from "../../services/Utils";


@Component({
    selector: 'device-command',
    templateUrl: 'device-command.html',
    styleUrls: ['./device-command.css']
})
export class DeviceCommandDialog implements OnInit {

    inProgress: boolean;
    loading: boolean;

    device : Device;

    commandsCfg = {};
    commands = [];
    selectedCommand: string;
    paramKeys = [];
    commandInstance: any;
    error: string;
    showMessages: boolean;
    messages = [];
    appEui: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DeviceCommandDialog>, public devService: AESDevicesService, public snackBar: MatSnackBar) {
        
        this.device = data.device;
        this.appEui = this.device.application;

        this.inProgress = true;
        this.loading = true;
        devService.getDeviceCommands(this.device.devEui, this.appEui).subscribe((res) => {
            this.inProgress = false;
            this.loading = false;
            this.commandsCfg = res.downlinks;
            var keys = [];
            for (var k in this.commandsCfg){
                keys.push(this.commandsCfg[k].control);
            } 
            this.commands = keys;
            this.selectedCommand = this.commands[0];
            this.setParamKeys();
        }, () => {
            this.inProgress = false;
            this.loading = false;
            this.openSnackBar("Unable to load device commands, please contact the network administrator", undefined);
            this.dialogRef.close();
        });
        this.currentParams = this.currentParams.bind(this);
        this.setParamKeys = this.setParamKeys.bind(this);
        this.currentCommand = this.currentCommand.bind(this);
    }

    currentCommand() {
        var currentCommand = undefined;
        for(var k in this.commandsCfg){
            var command = this.commandsCfg[k];
            if(command.control == this.selectedCommand){
                currentCommand = command;
                break;
            }
        }
        return currentCommand
    }

    currentParams() {
        return this.currentCommand().parameters;
    }

    setParamKeys() {
        this.paramKeys = Object.keys(this.currentParams());
        console.log(this.paramKeys);
        this.commandInstance = {
            control: this.currentCommand,
            parameters: {

            }
        };
        for(let paramKey of this.paramKeys){
            var param = this.currentParams()[paramKey];
            if(param.default != undefined){
                if(param.type == "boolean"){
                    this.commandInstance.parameters[paramKey] = param.default + "";
                }
                else{
                    this.commandInstance.parameters[paramKey] = param.default;
                }
            }
        }
    }

    getParamType(param) {
        var type = "text";
        if (param.type == "integer" || param.type == "float") {
            type = "number";
        }

        return type;
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    ngOnInit() {

    }

    execute() {
        this.error = undefined;
        for(let paramKey of this.paramKeys){
            var param = this.currentParams()[paramKey];
            var cfg = this.commandInstance.parameters[paramKey];
            if(param.required && cfg == undefined){
                this.error = paramKey + " is required.";
                return;
            }
            if(param.type == "boolean"){
                this.commandInstance.parameters[paramKey] = this.commandInstance.parameters[paramKey] == "true";
            }
        }
        this.inProgress = true;
        var data = {
            command : {}
        };
        data.command = this.commandInstance;
        this.devService.sendDeviceCommands(this.device.devEui, data, this.appEui).subscribe((res) => {
            this.showMessages = true;
            this.inProgress = false;
            this.messages.push("Command completed successfully");
        }, (error) => {
            this.inProgress = false;
            this.error = error;
        });
    }
}
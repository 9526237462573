import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface Account {
    acctId: number;
    name: string;
    desciption: string;
    creationDate: Date;
    defaultConnectionId: number;
    connectionType: string;
}
@Injectable()
export class AESAccountService implements DataTableSearchService<Account>, CRUDService<Account>{

    constructor(private http: HttpClient) { }

    create(object: any): Observable<Message> {
        const href = 'accounts';
        var params = new HttpParams();
        var requestUrl = `${href}`

        return this.http.post<Message>(requestUrl, object, {
            params : params
        });
    }
    edit(object: any): Observable<Message> {
        const href = 'accounts';
        var params = new HttpParams();
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object, {
            params : params
        });
    }
    get(acctId): Observable<Account> {
        const href = 'accounts';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(acctId != undefined){
            params = params.set("acctId", acctId.toString());
        }

        return this.http.get<Account>(requestUrl, {
            params : params
        });
    }

    delete(object: Account): Observable<Message> {
        throw new Error("Not Implemented");
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any): Observable<SenetData<Account>> {
        const href = 'accounts';
        var offset = page * limit
        var requestUrl = `${href}`;

        var params = new HttpParams();

        if(sort != undefined && sort != ""){
            params = params.set("sort", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortDir", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }

        if(customFilter && customFilter.currentAccount){
            params = params.set("currentAccount", customFilter.currentAccount);
        }

        return this.http.get<SenetData<Account>>(requestUrl, {
            params : params
        });
    }
}
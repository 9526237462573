import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Utils } from './Utils';

@Injectable()
export class AppStateService {
    static logout:Subject<boolean> = new Subject<boolean>();
    static logout$ = AppStateService.logout.asObservable();
    
    static titleChange:Subject<string> = new Subject<string>();
    static titleChange$ = AppStateService.titleChange.asObservable();

    triggerLogout(){
        console.log("logout triggered");
        AppStateService.logout.next(true);
    }

    triggerTitleChange(newTitle){
        Utils.APPLICATION_TITLE = newTitle;
        AppStateService.titleChange.next(newTitle);
    }
}
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Account, AESAccountService } from "../../services/AESAccountService";
import { AESUserService } from "../../services/AESUserService";

interface RoleFields {
    label: string
    value: string
}

@Component({
    selector: 'userdialog',
    templateUrl: 'userdialog.html',
    styleUrls: ['./userdialog.css']
})
export class UserEditDialog {

    instance: any;
    editType: string = "Create";
    roles: RoleFields[] = [{ label: "User", value: "USER" }, { label : "Admin", value : "ADMIN"}];

    inProgress: boolean;
    accounts: Account[];
    error: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UserEditDialog>, private userService:AESUserService, private accountSearch: AESAccountService) {
        var inst = data.instance;
        if (inst != undefined) {
            this.instance = { ...inst };
            this.editType = "Edit";
        }
        else{
            this.instance = {};
        }
        if(this.instance.role == undefined){
            this.instance.role = this.roles[0].value;
        }
        if(this.instance.connectionType == undefined){
            this.instance.connectionType = "SQL";
        }
        this.inProgress = true;
        var me = this;
        accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
        .subscribe(data => {
            me.accounts = data.data;
            if(me.accounts == undefined || me.accounts.length == 0){
                me.error = "Unable to load accounts, please try again later or contact an administrator.";
            }
            else{
                if(me.instance.acctId == undefined){
                    me.instance.acctId = data.data[0].acctId;
                }
                me.inProgress = false;
            }
        }, (err) => {
        me.error = "Unable to load accounts, please try again later or contact an administrator.";
        });
    }

    save() {
        var obs;
        this.error = "";
        if(this.instance.username == undefined || this.instance.username == ''){
            this.error = "A username is required";
            return;
        }
        /*if((this.instance.password == undefined || this.instance.password == '') && this.editType == "Create"){
            this.error = "A password is required";
            return;
        }*/
        var me = this;

        if(this.editType == "Create"){
            obs = this.userService.create(this.instance);
        }
        else{
            obs = this.userService.edit(this.instance);
        }

        this.inProgress = true;
        obs.subscribe(resp => {
            this.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
           console.log(err);
           me.error = err.error.message;
           if(me.error == "Internal Server Error"){
               me.error = "Server was unable to process your request";
           }
           me.inProgress = false;
        });
    }
}
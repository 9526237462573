<h2 mat-dialog-title>{{editType}} Event Configuration</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm">
        <mat-form-field>
            <input [disabled]="inProgress || editType == 'Edit'" matInput placeholder="ID" required [(ngModel)]="instance.id" name="event-conf-edit-id">
        </mat-form-field>
        <mat-checkbox [disabled]="inProgress" class="chk-box-padding" name="event-conf-edit-state" [(ngModel)]="instance.state">Enabled
        </mat-checkbox>
        <mat-form-field class="chip-list">
            <mat-chip-list #eventConfEditEmailList aria-label="Email List">
              <mat-chip *ngFor="let email of emailList" (removed)="remove(email)">
                {{email}}
                <mat-icon matChipRemove >cancel</mat-icon>
              </mat-chip>
              <input placeholder="Email List"
                     [matChipInputFor]="eventConfEditEmailList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="true"
                     (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
        </mat-form-field>
        <mat-form-field *ngIf="isAdmin()">
            <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Account" [(ngModel)]="instance.acctId" name="event-conf-acctId-field">
                <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                    {{account.name}} ({{account.acctId}})
                </mat-option>
            </mat-select>
        </mat-form-field>
        <label>Event Conditions</label>
        <div *ngFor="let condition of instance.conditions; let i = index;" class="conditons-div">
            <div [fxFlex]="100" fxFlex.xs="100">
                <mat-form-field [fxFlex]="30" fxFlex.xs="30">
                    <input [disabled]="inProgress" matInput placeholder="Field" required [(ngModel)]="condition.field" (change)="fieldChange(condition)"
                        [name]="'event-config-edit-fieldname-' + i">
                </mat-form-field>
                <mat-select [disabled]="inProgress" class="data-type-select" placeholder="Data Type" [fxFlex]="18" fxFlex.xs="18" [(ngModel)]="condition.dataType" (selectionChange)="dataTypeChange(condition)" [name]="'event-config-datatype-' + i">
                    <mat-option *ngFor="let dataType of dataTypes" [value]="dataType">
                        {{dataType}}
                    </mat-option>
                </mat-select>
                <mat-select [disabled]="inProgress || condition.dataType == undefined" class="data-type-select comp-select" placeholder="Comparison" [fxFlex]="18" fxFlex.xs="18" (selectionChange)="dataTypeChange(condition)" [(ngModel)]="condition.comparison" [name]="'event-config-comparison-' + i">
                    <div *ngFor="let key of compKeys">
                        <mat-option *ngIf="comparisons[key].allowedTypes == undefined || comparisons[key].allowedTypes.indexOf(condition.dataType) >= 0" [value]="key">
                            {{comparisons[key].label}}
                        </mat-option>
                    </div>
                </mat-select>
                <mat-form-field [fxFlex]="32" fxFlex.xs="32" *ngIf="condition.dataType != 'Boolean' && condition.dataType != 'Date'" >
                    <input [disabled]="inProgress || condition.comparison == undefined || isDisabledComp(condition)" matInput [type]="condition.dataType == 'Number' ? 'number' : 'text'" placeholder="Threshold" required [(ngModel)]="condition.threshold"
                        [name]="'event-config-edit-threshold-' + i">
                </mat-form-field>
                <mat-form-field  [fxFlex]="32" fxFlex.xs="32" *ngIf="condition.dataType == 'Boolean'">
                    <mat-select [disabled]="inProgress || condition.comparison == undefined || isDisabledComp(condition)" placeholder="Threshold" [(ngModel)]="condition.threshold"  [name]="'event-config-edit-threshold-' + i">
                        <mat-option [value]="'true'">
                            true
                        </mat-option>
                        <mat-option [value]="'false'">
                            false
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div [fxFlex]="32" *ngIf="condition.dataType == 'Date'"></div>
                <button [name]="'event-config-edit-remove-' + i" [disabled]="inProgress || instance.conditions.length == 1" class="remove-button" [fxFlex]="4" [fxFlex.xs]="4" mat-icon-button (click)="removeOption(i)">
                    <mat-icon>remove_circle_outline</mat-icon>
                </button>
            </div>
            <div [fxFlex]="100" fxFlex.xs="100" *ngIf="condition.dataType == 'Date'">
                <div  [fxFlex]="18" fxFlex.xs="18" class="check-box-div">
                    <mat-checkbox [disabled]="inProgress || condition.comparison == undefined || isDisabledComp(condition)" placeholder="Relative Date" (ngModelChange)="handleRelativeDate($event, condition)" [(ngModel)]="condition.relativeDate"
                            [name]="'event-config-edit-relative-date-chk-' + i">Relative</mat-checkbox>
                </div>
                <mat-form-field [fxFlex]="72" fxFlex.xs="72" *ngIf="!condition.relativeDate">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="picker" required [(ngModel)]="condition.exactDate" [disabled]="inProgress || condition.comparison == undefined || isDisabledComp(condition)">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker" class="date-picker-button"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field  [fxFlex]="40" fxFlex.xs="40" *ngIf="condition.relativeDate">
                    <input [disabled]="inProgress || condition.comparison == undefined || isDisabledComp(condition)" matInput type="Number" placeholder="Threshold" required [(ngModel)]="condition.threshold"
                        [name]="'event-config-edit-threshold-' + i">
                </mat-form-field>
                <mat-form-field  [fxFlex]="32" fxFlex.xs="32" *ngIf="condition.relativeDate" class="dropdown-line-fix">
                    <mat-select [disabled]="inProgress || condition.comparison == undefined || isDisabledComp(condition)" placeholder="Relative Date Type" [(ngModel)]="condition.relativeDateScope"  [name]="'event-config-edit-relative-date-option-' + i">
                        <mat-option value="minute">
                            Minute(s)
                        </mat-option>
                        <mat-option value="hour">
                            Hour(s)
                        </mat-option>
                        <mat-option value="day">
                            Day(s)
                        </mat-option>
                        <mat-option value="month">
                            Month(s)
                        </mat-option>
                        <mat-option value="year">
                            Year(s)
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div [fxFlex]="100" fxFlex.xs="100">
                <div [fxFlex]="18" fxFlex.xs="18" class="check-box-div">
                    <mat-checkbox [disabled]="inProgress || condition.comparison == undefined || isDisabledComp(condition)" placeholder="Override Default Message" [(ngModel)]="condition.overrideMsg.override"
                            [name]="'event-config-edit-msg-chk-' + i">Override</mat-checkbox>
                </div>
                <mat-form-field [fxFlex]="50" fxFlex.xs="50">
                    <input [disabled]="inProgress || condition.comparison == undefined || condition.overrideMsg.exclude || !condition.overrideMsg.override || isDisabledComp(condition)" matInput [type]="condition.dataType == 'Number' ? 'number' : 'text'" placeholder="Message" [(ngModel)]="condition.overrideMsg.msg"
                        [name]="'event-config-edit-msg-' + i">
                </mat-form-field>
                <div [fxFlex]="18" fxFlex.xs="18" class="check-box-div">
                    <mat-checkbox [disabled]="inProgress || condition.comparison == undefined || !condition.overrideMsg.override || isDisabledComp(condition)" placeholder="Exclude Message" [(ngModel)]="condition.overrideMsg.exclude"
                            [name]="'event-config-edit-msg-exclude-chk-' + i">Exclude</mat-checkbox>
                </div>
            </div>
        </div>
        <div>
            <button name="event-config-add" [disabled]="inProgress" class="add-button" mat-icon-button (click)="addOption()">
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
        <div>
            <mat-checkbox [disabled]="inProgress" placeholder="Use Trigger Function Block" (change)="toggleExecuteFunctionBlock()" [(ngModel)]="enableExecuteFunctionBlock"
                            name="event-conf-edit-use-trigger-function-block">Use Trigger Function Block</mat-checkbox>
            <senet-ace-editor *ngIf="enableExecuteFunctionBlock" [(code)]="instance.executeFunctionBlockStr" [mode]="mode"></senet-ace-editor>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
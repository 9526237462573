<mat-card [ngClass]="{editdetails: isEditDetailsCard}">
    <mat-card-header>
        <mat-card-title>
            <ng-content select="[card-title]"></ng-content>
        </mat-card-title>
        <div class="toolbar">
            <ng-content select="[card-toolbar]"></ng-content>
        </div>
    </mat-card-header>
    <mat-card-content>
        <ng-content select="[card-body]"></ng-content>
    </mat-card-content>
    <ng-content select="mat-card-actions"></ng-content>
</mat-card>
import { Component, ViewChild } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Router} from "@angular/router";
import {MatTabGroup} from '@angular/material/tabs';
import { MatSnackBar } from "@angular/material/snack-bar";
import { AfterViewInit } from "@angular/core";
import { Title } from '@angular/platform-browser';
import { js_beautify } from 'js-beautify';
import { SenetAceEditor } from "aes-common";
import { AESLogsService } from "../../../services/AESLogsService";

@Component({
    selector: 'log-details',
    templateUrl: 'log-details.html',
    styleUrls: ['./log-details.scss']
})
export class LogDetails implements AfterViewInit {
    @ViewChild('logMessage', {static: true}) logMessage: SenetAceEditor;
    @ViewChild('detailedLogMessage', {static: true}) detailedLogMessage: SenetAceEditor;
    @ViewChild("tabGroup", { static: false }) tabGroup: MatTabGroup;

    instance: any;
    mode: string = "ace/mode/json";
    logEntryId: string;
    gapSize: string = "10px";
    inProgress: boolean = false;
    title: string = "Log Entry";
    titleSuffix: string;
    canShow: boolean = false;
    error: string;
    selectedTab: number = 0;
    private canGoBack: boolean;
    private oldInst: any;

    constructor(private location: Location, private route: ActivatedRoute, private router: Router, 
        public snackBar: MatSnackBar, public titleService: Title, private serve: AESLogsService) {
            
        let me = this;
        this.logEntryId = this.route.snapshot.params["id"];
        this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);
        this.instance = {};
        this.instance.msg = {};

        this.inProgress = true;
        this.serve.get(this.logEntryId).subscribe(res => {
            var inst = res;
            if (inst != undefined) {
                this.instance = {...inst};
            }

            
            if(this.instance.time != undefined && this.instance.time != ""){
                var date = new Date(this.instance.time);
                date.toLocaleString();
                this.titleSuffix = " - " + date.toLocaleString();
            }
            this.titleService.setTitle(this.title + this.titleSuffix);//Overridden if defined in app-routing module.ts
            var options = { indent_size: 2, space_in_empty_paren: true };
            try{
                if(typeof this.instance.msg == "object" && this.instance.msg !== null){
                    this.instance.msg = JSON.stringify(this.instance.msg);
                }
                this.instance.msg = js_beautify(this.instance.msg, options);
            }
            catch(e){};
            try{
                if(typeof this.instance.detailedMsg == "object" && this.instance.detailedMsg !== null){
                    this.instance.detailedMsg = JSON.stringify(this.instance.detailedMsg);
                }
                this.instance.detailedMsg = js_beautify(this.instance.detailedMsg, options);
            }
            catch(e){};
            this.inProgress = false;
            this.canShow = true;
        }, err =>{
            this.openSnackBar("Unable to load Log Entry: " + this.logEntryId, undefined);
            this.inProgress = false;
            this.canShow = true;
        });
                 
    }

    ngOnInit() {      
    }
    ngAfterViewInit(){  
        const tabGroup = this.tabGroup;
        if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
      
        tabGroup.selectedIndex = this.selectedTab;      
        this.logMessage.aceEditor.setReadOnly(true);
        this.logMessage.aceEditor.getSession().setUseWorker(false); 
        this.detailedLogMessage.aceEditor.setReadOnly(true);
        this.detailedLogMessage.aceEditor.getSession().setUseWorker(false); 
    }
    handleBack($event){
        let me = this;
        $event.preventDefault();
        me.goBack(me);
    }      
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    } 

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    } 
    goBack(me){
        if(me.canGoBack){
            me.location.back();
        }else{
            me.router.navigateByUrl("/diagnostics/logs",{
                replaceUrl: true
            });
        }
    }   
    
}

import { Component, Input, HostListener, ViewChild } from "@angular/core";
import { ViewRegistration } from "aes-common";
import { ColumnConfig, DataTableOptions, DataTable } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { EventsConfigService, EventConfig } from "../../../services/EventsConfigService";
import { EventConfigurationEditDialog } from "../../../dialogs/event-config-create-edit/event-config-create-edit";
import { MatDialog } from "@angular/material/dialog";

@Component({
    template: '<a (click)="showEditor($event, element)">{{element.id}}</a>'
})
export class ShowEventConfigurationEditorCell{
    @Input()
    element: EventConfig;

    @Input()
    col: ColumnConfig;

    dialogRef: any;

    @HostListener("click")showEditor(evt, element){
        evt.stopPropagation();
        if(!this.dialogRef){
            //this.table.buttons[1].click(this.table.dt, [element]);
            this.dialogRef = this.dialog.open(EventConfigurationEditDialog, {
                width: '600px',
                data: {
                    "instance": element
                }
            });
    
            this.dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    this.table.dt.refresh(this.table.dt, [element]);
                }
                this.dialogRef = undefined;
            });            
            console.log("Called click handler");
        }

    }

    constructor(public dialog: MatDialog, public table: EventsConfiguration){
    }    
}
@Component({
    selector: 'event-config',
    templateUrl: 'event-config.html',
    styleUrls: ['./event-config.scss']
})
export class EventsConfiguration {
    static view = new ViewRegistration("Event Configuration", "/events/configuration", "add_alert", []);

    dtOptions:DataTableOptions  = {pageSize : 100, showSelectionColumn: false, multiSelect: false};
    @ViewChild(DataTable, { static: true })
    dt : DataTable
    columnCfgs: ColumnConfig[] = [{
        header: "ID",
        field: "id",
        defaultSort: true,
        contentComponent: ShowEventConfigurationEditorCell
    },{
        header: "Email List",
        field: "emailList"
    },{
        header: "State",
        field: "state",
        renderContent(col : ColumnConfig, element : EventConfig){
            return element.state ? "Enabled" : "Disabled";
        }
    },{
        header: "Account",
        field: "acctId",
        visible : false
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: EventsConfigService) {
        var me = this;
        this.buttons = [ButtonConfig.createAddButton("Create Event Configuration", function(dt, selected){
            const dialogRef = me.dialog.open(EventConfigurationEditDialog, {
                width: '600px',
                data: {}
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }), ButtonConfig.createEditButton("Edit Event Configuration", function(dt, selected){
            const dialogRef = me.dialog.open(EventConfigurationEditDialog, {
                width: '600px',
                data: {
                    "instance": selected[0]
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        })];
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

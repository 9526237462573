import { Component } from "@angular/core";
import { ViewRegistration } from "aes-common";
import { Utils } from "../../../services/Utils";
import { AESAccountAPIKeys } from "./accountapikeys";

@Component({
    selector: 'accountapikeys',
    templateUrl: 'accountapikeys.html',
    styleUrls: ['./accountapikeys.scss']
})
export class AESAdminAccountAPIKeys extends AESAccountAPIKeys {
    static view = new ViewRegistration("Account API Keys", "/admin/accountapikeys", "private_connectivity", [], Utils.isAdminUser);
}

<div class="container" fxLayout="column" [fxLayoutGap]="gapSize" [ngClass]="{'hidden': !canShow}">
    <div align="end" class="edit-buttons">
        <button mat-button class="back-button" (click)="handleBack($event)">&lt;&nbsp;Back</button>
        <button class="edit-view-buttons" [disabled]="(inProgress || saveBlocked || isShared || !isDirty())" mat-button (click)="save()" cdkFocusInitial>Save</button>
        <button class="edit-view-buttons" mat-button (click)="cancel()">Cancel</button>
    </div>    
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">       
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100" [isEditDetailsCard]="true">            
            <div card-title>
                {{editType}} Codec{{titleSuffix}}                
            </div>            
            <div class="senet-card-body" card-body>
                <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
                <mat-tab-group #tabGroup (selectedTabChange)="selectedTabChanged($event)">  
                    <mat-tab label="Information">
                        <form fxLayout="column" fxLayoutGap="10" #infoForm="ngForm" class="edit-tab-form info-tab-form">
                            <mat-form-field *ngIf="editType != 'Create'">
                                <input [disabled]="inProgress || editType == 'Edit' || isShared" matInput placeholder="ID" required
                                    [(ngModel)]="instance.id" name="codec-ID-field">
                            </mat-form-field>
                            <mat-form-field>
                                <input [disabled]="inProgress || isShared" matInput placeholder="Name" required [(ngModel)]="instance.name"
                                    name="codec-name-field">
                            </mat-form-field>
                            <mat-form-field>
                                <input [disabled]="inProgress || isShared" matInput placeholder="Description" [(ngModel)]="instance.description"
                                    name="codec-description-field">
                            </mat-form-field>
                            <mat-form-field *ngIf="isAdmin()">
                                <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Account"
                                    [(ngModel)]="instance.acctId" name="codec-acctId-field">
                                    <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                                        {{account.name}}<span *ngIf="account.acctId != -1"> ({{account.acctId}})</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-checkbox [(ngModel)]="instance.downlinkCodeEnabled || isShared" name="codec-downlinkCodeEnabled-field" (ngModelChange)="handleDownlinkEnabled($event)">Downlink Code Enabled</mat-checkbox>
                        </form>
                    </mat-tab>                          
                    <mat-tab label="Codec Code">
                        <form fxLayout="column" fxLayoutGap="10" #codeForm="ngForm" class="edit-tab-form">
                        <senet-ace-editor #codecCodeEditor (codeChange)="onCodeChanged('codecCodeEditor')" [isForDialog]="false" [(code)]="instance.codecCode" [mode]="mode"></senet-ace-editor>
                    </form>
                    </mat-tab>
                    <mat-tab label="Downlink Code">
                        <form fxLayout="column" fxLayoutGap="10" #initForm="ngForm" class="edit-tab-form">
                        <senet-ace-editor #downlinkCodeEditor (codeChange)="onCodeChanged('downlinkCodeEditor')" [isForDialog]="false" [(code)]="instance.downlinkCode" [mode]="mode"></senet-ace-editor>
                    </form>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </senetcard>
    </div>
</div>

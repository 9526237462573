<div class="container" fxLayout="column" [fxLayoutGap]="gapSize"
    *ngIf="parent != undefined && parent.id == parent.selection">
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100">
            <div card-title>
                {{id}}
            </div>
            <div card-body>
                <datatable [tableId]="id + '_table'" [dtOptions]="dtOptions" [searchService]="httpClient" [buttonCfg]="buttons"
                    [columnCfg]="columnCfgs" tableHeight="190"></datatable>
            </div>
        </senetcard>
    </div>
</div>
<div class="container" fxLayout="column" [fxLayoutGap]="gapSize">
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">
        <senetcard [fxFlex]="calculateSize(10)" fxFlex.xs="10">
            <div card-title>
                API Docs
            </div>
            <div card-body>
                <div class="swagger-ui"></div>
            </div>
        </senetcard>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class Privileges {

    constructor() {
    }

    hasPrivileges(privs){
        return true;
    }
}
import { GlobalScope } from "../services/GlobalScope";

export class ViewRegistration {
    public title: string;
    public link: string;
    public icon: string;
    public subViews: Array<ViewRegistration>;
    public selected: boolean;
    private privs: Array<string>;
    private isVisible : any;
    
    constructor(title, link, icon, privs, isVisible?){
        this.title = title;
        this.link = link;
        this.icon = icon;
        this.subViews = [];
        this.privs = privs;
        this.isVisible = isVisible;
        this.selected = false;
        GlobalScope.addRegisteredView(this.title);
    }

    public isViewVisible(){
        if(this.isVisible != undefined && typeof this.isVisible === 'boolean'){
            return this.isVisible && !GlobalScope.isViewHidden(this.title);
        }
        else if(this.isVisible != undefined && typeof this.isVisible === 'function'){
            return this.isVisible() && !GlobalScope.isViewHidden(this.title);
        }

        return this.isVisible == undefined && !GlobalScope.isViewHidden(this.title);
    }

    public setSelected(selected: boolean){
        this.selected = selected;
    }
    public addSubView(reg:ViewRegistration){
        for(var key in this.subViews){
            var val = this.subViews[key];
            if(val.link == reg.link){
                return;
            }
        }
        this.subViews.push(reg);
    }

    public getPrivs(){
        if(this.privs == undefined){
            return [];
        }
        return this.privs;
    }

}
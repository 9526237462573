import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { isForInStatement } from "typescript";
import { ColumnConfig, ColumnDataType } from "../../columncfg";


const DATE_TIME_TYPE_ABSOLUTE = "Absolute";
const DATE_TIME_TYPE_RELATIVE = "Relative";

@Component({
    selector: 'columnfilters',
    templateUrl: 'columnfilters.html',
    styleUrls: ['./columnfilters.css']
})

export class ColumnFilterDialog{
    
    public colFilters: any
    public timeUnits = [
        {id: 'SECONDS', label: "seconds"},
        {id: 'MINUTES', label: "minutes"},
        {id: 'HOURS', label: "hours"},
        {id: 'DAYS', label: "days"}
    ]  
    columnDefs: any
    error: string
    dt: any
    constructor(@Inject(MAT_DIALOG_DATA) public data:any, public dialogRef: MatDialogRef<ColumnFilterDialog>){
        let cols : ColumnConfig[] = data.columns;
        this.colFilters = {};
        this.columnDefs = [];
        this.dt = data.dt;

        if(data.columnFilters){
            this.colFilters = data.columnFilters;
        }

        for (var key in cols){
            var val = cols[key];
            if(val.filterable){
                let checked = this.colFilters[val.field] ? true : false;
                let filter = this.colFilters[val.field] ?  this.colFilters[val.field] : "";
                let listValues = val.listValues;
                if(val.dataType == ColumnDataType.DATE){
                    if(!filter){
                        let dateIso = new Date().toISOString(); 
                        let tzOffset = new Date().getTimezoneOffset() * -1 * 60000;
                        let nowMs = new Date().getTime() + tzOffset;
                        let oneHrAgoMs = nowMs - 3600000;
                        let nowIso = new Date(nowMs).toISOString().split('Z')[0];
                        let oneHrAgoIso = new Date(oneHrAgoMs).toISOString().split('Z')[0];
                        console.log("Orig Date Iso: " + dateIso + " End: " + nowIso + " Start: " + oneHrAgoIso + " TZ Offset: " + tzOffset);                         
                        filter = {datetimeType: DATE_TIME_TYPE_RELATIVE, start: 0, startUnits:'HOURS', startDate: oneHrAgoIso, endDate: nowIso};   
                    }else{
                        //Treat existing filters with start and end as absolute
                        if(!filter.datetimeType && filter.start > 0 && filter.end > 0){
                            filter.datetimeType = DATE_TIME_TYPE_ABSOLUTE;
                        }
                    }
                }else if(val.dataType == ColumnDataType.LIST){
                    if(!filter){
                        filter = listValues[0]; 
                    }                    
                }
                this.columnDefs.push({"header": val.header, "dataType": val.dataType, "checked": checked, "field": val.field, "filter": filter, "listValues": listValues});
            }
        }
    }
    isColumnTypeDate(col): boolean { 
        return col.dataType.toLowerCase() == ColumnDataType.DATE; 
    }
    isColumnTypeString(col): boolean { 
        return col.dataType.toLowerCase() == ColumnDataType.STRING; 
    }
    isColumnTypeList(col): boolean { 
        return col.dataType.toLowerCase() == ColumnDataType.LIST; 
    }
    onCheckboxChange(evt, col){
        if(!col.checked){
            if(col.dataType == ColumnDataType.STRING){
                col.filter = "";
            }else if(col.dataType == ColumnDataType.LIST){
                col.filter = col.listValues[0];
            }else if(col.dataType == ColumnDataType.DATE){
                let dateIso = new Date().toISOString(); 
                let tzOffset = new Date().getTimezoneOffset() * -1 * 60000;
                let nowMs = new Date().getTime() + tzOffset;
                let oneHrAgoMs = nowMs - 3600000;
                let nowIso = new Date(nowMs).toISOString().split('Z')[0];
                let oneHrAgoIso = new Date(oneHrAgoMs).toISOString().split('Z')[0];
                console.log("Orig Date Iso: " + dateIso + " End: " + nowIso + " Start: " + oneHrAgoIso + " TZ Offset: " + tzOffset);                        
                col.filter = {datetimeType: DATE_TIME_TYPE_RELATIVE, start: 0, startUnits:'HOURS', startDate: oneHrAgoIso, endDate: nowIso};               
            }
        }
    }

    applyColumnFilters(){
        let newColFilters = {};
        let ninetyDaysMs = this.dt.MILLIS_IN_DAY * 90;
        this.error = "";
        for(var key in this.columnDefs){
            var columnDef = this.columnDefs[key];
            if(columnDef.checked){
                if(columnDef.dataType == ColumnDataType.DATE){
                    let now = new Date().getTime();
                    if(columnDef.filter.datetimeType === 'Relative'){
                        columnDef.filter.end = 0;
                        columnDef.filter.endUnits = columnDef.filter.startUnits;
                    }
                    let convertedFilter = columnDef.filter.datetimeType === 'Relative' ? this.dt.convertRelativeTimeFilter(columnDef.filter) : this.dt.convertAbsoluteTimeFilter(columnDef.filter);
                    let startMs = convertedFilter.start;
                    let endMs = convertedFilter.end;
                    if(startMs > endMs){
                        let dialogContext = this;
                        this.error = "The start time must be less than the end time value.";
                        setTimeout(function(){dialogContext.error = ''}, 2000);
                        return;
                    }else if(startMs > now){
                        let dialogContext = this;
                        this.error = "The start time can't be in the future.";
                        setTimeout(function(){dialogContext.error = ''}, 2000);
                        return;
                    }else if(endMs > now){
                        let dialogContext = this;
                        this.error = "The end time can't be in the future.";
                        setTimeout(function(){dialogContext.error = ''}, 2000);
                        return;
                    }else if(startMs < ninetyDaysMs){
                        let dialogContext = this;
                        this.error = "The start time can't be more than 90 days ago.";
                        setTimeout(function(){dialogContext.error = ''}, 2000);
                        return;
                    }
                }
                newColFilters[columnDef.field] = columnDef.filter;
            }
        }
        this.dialogRef.close(newColFilters);
    }

}
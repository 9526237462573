import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AESDeviceProfileService, DeviceProfile } from "../../services/AESDeviceProfileService";
import { AESDevicesService, DeviceUpdate } from "../../services/AESDevicesService";

@Component({
    selector: 'devicedialog',
    templateUrl: 'devicedialog.html',
    styleUrls: ['./devicedialog.css']
})
export class DeviceEditDialog {

    instance: DeviceUpdate;
    editType: string = "Create";

    inProgress: boolean;
    saveBlocked: boolean;
    error: string;
    profiles : DeviceProfile[];
    profilesMap = {};

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DeviceEditDialog>, public serve : AESDevicesService, public profileServe : AESDeviceProfileService) {
        var inst = {...data.instance};
        this.instance = { 
            devices : [inst.devEui],
            deviceProfile : inst.deviceProfile,
            name : inst.name,
            owner : inst.owner,
            tags : inst.tags,
            devType : inst.devType
        };
        var me = this;
        me.inProgress = true;
        profileServe.search(undefined, undefined, undefined, undefined, undefined, {})
        .subscribe(data => {
            me.profiles = data.data;
            me.profilesMap = {};
            if(me.profiles == undefined || me.profiles.length == 0){
                me.error = "Unable to load profiles, please try again later or contact an administrator.";
            }
            else{
                me.inProgress = false;
            }
        }, (err) => {
        me.error = "Unable to load profiles, please try again later or contact an administrator.";
        });
    }

    ngAfterViewInit(){
    }

    save() {
        this.error = "";
        var me = this;
        
        var obs = this.serve.edit(this.instance);

        this.inProgress = true;
        obs.subscribe(resp => {
            this.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
           console.log(err);
           me.error = err.error.message;
           if(me.error == "Internal Server Error"){
               me.error = "Server was unable to process your request";
           }
           me.inProgress = false;
        });
    }
}
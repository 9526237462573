import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Utils } from "../../services/Utils";
import { OptionsService} from "aes-common";
import { UserService } from "../../services/UserService";
import { AppStateService } from "../../services/AppStateService";
import { AppComponent } from "../../app/app.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AESDeviceProfileService, DeviceProfile } from "../../services/AESDeviceProfileService";


@Component({
    selector: 'settings',
    templateUrl: 'settings.html',
    styleUrls: ['./settings.scss']
})
export class AESSettings {
    gapSize: string = "10px";

    tempVal = {};
    Fields = [];
    precision = Utils.DECIMAL_PRECISION;
    tempPrecision = this.precision;
    geocodeField: any;
    tempGeocodeField: any;
    editorTabSpaceField: number;
    tempEditorTabSpaceField: number;
    editorTabsAsSpacesField: number;
    tempEditorTabsAsSpacesField: number;
    ogTitle = Utils.APPLICATION_TITLE;
    title = this.ogTitle;
    account: any;
    error: string;
    inProgress : boolean = false;
    profiles : DeviceProfile[];

    constructor(public dialog: MatDialog, public snackBar: MatSnackBar, private options:UserService, 
        public appStateService: AppStateService, public deviceProfiles : AESDeviceProfileService, private localStorageOptions:OptionsService) {
        this.options.getItem(Utils.SEN_GEOCODE_FIELD_KEY, undefined, (resp)=>{
            if(resp){
                this.geocodeField = resp;
                this.tempGeocodeField = this.geocodeField;
            }else{
                console.log("Unable to get geocodeField value from server.");
            }
        }); 
        this.options.getItem(Utils.SEN_ACE_TAB_SPACES_KEY, 4, (resp)=>{
            if(resp){
                this.editorTabSpaceField = resp;
                this.tempEditorTabSpaceField = this.editorTabSpaceField;
                this.localStorageOptions.setItem(Utils.SEN_ACE_TAB_SPACES_KEY, this.editorTabSpaceField);
            }else{
                console.log("Unable to get editor tab spaces value from server.");
            }
        });
        this.options.getItem(Utils.SEN_ACE_TABS_AS_SPACES_KEY, true, (resp)=>{
            if(resp){
                this.editorTabsAsSpacesField = resp;
                this.tempEditorTabsAsSpacesField = this.editorTabsAsSpacesField;
                this.localStorageOptions.setItem(Utils.SEN_ACE_TABS_AS_SPACES_KEY, this.editorTabsAsSpacesField);
            }else{
                console.log("Unable to get editor tab spaces value from server.");
            }
        });                   
        this.refresh();
    }

    refresh(){
        var me = this;
        this.inProgress = true;
        this.options.getAccountFromServer()
        .subscribe(data => {
            me.account = data;
            if(me.account == undefined){
                me.error = "Unable to load account, please try again later or contact an administrator.";
            }
            me.account.defaultDeviceProfileIdUpdate = me.account.defaultDeviceProfileId;
            me.deviceProfiles.search(undefined, undefined, undefined, undefined, undefined, {})
            .subscribe(data => {
                me.profiles = data.data;
                if(me.profiles == undefined || me.profiles.length == 0){
                    me.error = "Unable to load profiles, please try again later or contact an administrator.";
                }
                else{
                    me.inProgress = false;
                }
            }, (err) => {
            me.error = "Unable to load profiles, please try again later or contact an administrator.";
            });
        }, (err) => {
           me.error = "Unable to load account, please try again later or contact an administrator.";
        });
    }

    changeDefaultProfile(newValue){
        this.account.defaultDeviceProfileId =  newValue;
        var me = this;
        this.inProgress = true;
        this.options.updateAccount(this.account).subscribe(data =>{
            me.inProgress = false;
            me.refresh();
        }, (err) => {
            me.error = "Unable to save account changes, please try again later or contact an administrator.";
        });
    }

    changePrecision(newValue){
        Utils.DECIMAL_PRECISION = newValue;
        this.precision = newValue;
        this.options.setItem(Utils.SEN_DECIMAL_PRECISION_KEY, newValue);
    }
    changeGeocodeField(newValue){
        this.geocodeField = newValue;
        this.options.setItem(Utils.SEN_GEOCODE_FIELD_KEY, newValue);
    }
    changeEditorTabSpaceField(newValue){
        this.editorTabSpaceField = newValue;
        this.options.setItem(Utils.SEN_ACE_TAB_SPACES_KEY, newValue);
        this.localStorageOptions.setItem(OptionsService.SENET_ACE_TAB_SPACES_KEY, newValue);
        
    }   
    changeEditorTabsAsSpacesField(newValue){
        this.editorTabsAsSpacesField = newValue;
        this.options.setItem(Utils.SEN_ACE_TABS_AS_SPACES_KEY, newValue);
        this.localStorageOptions.setItem(OptionsService.SENET_ACE_TAB_SPACES_KEY, newValue);
        
    }        
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

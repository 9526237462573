import { DataTable } from "./datatable";
import { ConfirmDialog } from "aes-common";
import { MatDialog } from "@angular/material/dialog";

export interface ButtonConfig {
    icon: string;
    title?: string;
    selectionCondition? (dt: DataTable, selected: any[]);
    selection: ButtonConfig.SelectionType;
    click?(dt: DataTable, selected: any[]);
}

export namespace ButtonConfig {
    export enum SelectionType {
        NONE,
        SINGLE,
        MULTI
    }
    export function createAddButton(title, click : (dt: DataTable, selected: any[]) => any): ButtonConfig {
        return {
            icon: "add",
            selection: SelectionType.NONE,
            title: title,
            click: click
        }
    }
    export function createEditButton(title, click : (dt: DataTable, selected: any[]) => any): ButtonConfig {
        return {
            icon: "create",
            selection: SelectionType.SINGLE,
            title: title,
            click: click
        }
    }
    export function createDeleteButton(title: string, getMessage: (selected: any) => string, deleteAction, dialog: MatDialog): ButtonConfig {
        return {
            icon: "delete",
            selection: SelectionType.SINGLE,
            title: title,
            click: function(dt, selected){
                var message = getMessage(selected[0]);
                dialog.open(ConfirmDialog, {
                    width: '600px',
                    data: {
                        "title" : title,
                        "message" : message,
                        "success": function(){
                            if(deleteAction != undefined){
                                deleteAction(selected[0], dt);
                            }
                        }
                    }
                });
            }
        }
    }
}
import { Component, Input, OnInit} from "@angular/core";
import { AESPipelinesService } from "../../../../../services/AESPipelinesService";

@Component({
    selector: 'function-block-pipelines-panel',
    templateUrl: 'function-block-pipelines.html',
    styleUrls: ['./function-block-pipelines.scss']
})

export class FunctionBlockPipelinesPanel implements OnInit {
    @Input()
    public functionBlockFilterId: string;

    constructor(public httpClient: AESPipelinesService){
    }

    ngOnInit(){         
    }
}
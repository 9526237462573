import { Component, ElementRef, AfterViewInit } from "@angular/core";
import { ViewRegistration } from "aes-common";
import SwaggerUI from 'swagger-ui';

@Component({
    selector: 'apidocs',
    templateUrl: 'apidocs.html',
    styleUrls: ['./apidocs.scss']
})
export class APIDocs implements AfterViewInit {
    static view = new ViewRegistration("API Docs", "/docs/apidocs", "devices", []);
    gapSize: string = "10px";
    
    ui: SwaggerUI;

    constructor(private el: ElementRef) {
        
    }

    ngAfterViewInit(){
        var element = this.el.nativeElement.querySelector('.swagger-ui');
        this.ui = SwaggerUI({
            url: '/assets/swagger/swagger-test.json',
            domNode: element,
            deepLinking: true,
            presets: [
              SwaggerUI.presets.apis
            ],
      });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

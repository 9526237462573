<h2 mat-dialog-title>{{editType}} Settings</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm">
        <mat-form-field>
            <input [disabled]="inProgress || editType == 'Edit'" matInput placeholder="ID" required [(ngModel)]="instance.id" name="sys-settings-edit-id-field">
        </mat-form-field>
        <mat-form-field>
            <textarea *ngIf="instance.id != 'SEN_HIDDEN_VIEWS'" [disabled]="inProgress" matInput placeholder="Value" required [(ngModel)]="instance.valueStr" name="sys-settings-edit-value-field"></textarea>
            <mat-select [(ngModel)]="instance.value" ng-if="instance.id == 'SEN_HIDDEN_VIEWS'" multiple [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let view of views" [value]="view">{{view}}</mat-option>
            </mat-select>        
        </mat-form-field>
        <mat-form-field>
            <mat-select [disabled]="inProgress || acctIdSet" placeholder="Account" (ngModelChange)="processAccountUpdate($event)" [(ngModel)]="instance.acctId" name="sys-settings-edit-acctId-field">
                <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                    {{account.name}} <span *ngIf="account.acctId != -2">({{account.acctId}})</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="instance.acctId != -2">
            <mat-select [disabled]="inProgress" placeholder="User" [(ngModel)]="instance.username" name="sys-settings-edit-username-field">
                <mat-option *ngFor="let user of users" [value]="user.username">
                    {{user.username}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
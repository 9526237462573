import { Component, Input } from "@angular/core";
import {Router, ActivatedRoute} from '@angular/router';
import { ViewRegistration } from "../../views/view_reg";
import { OptionsService } from "../../services/Options";
import { Privileges } from "../../services/Privleges";
import { GlobalScope } from "../../services/GlobalScope";

@Component({
    selector: 'sidenav',
    templateUrl: 'sidenav.html',
    styleUrls: ['./sidenav.scss']
})
export class SideNav {
    @Input()
    public opened: boolean;
    public links = []
    public miniMode: boolean;
    public views:any;
    public selectedView: ViewRegistration;
    
    currentlyOver: string;
    visible: boolean = true;
    window = window;
    constructor(public route: ActivatedRoute, public router: Router, public options: OptionsService, public priv: Privileges) {
        this.views = {};
        this.miniMode = options.getItem(OptionsService.SENET_EXPAND_KEY, "false") == "true" ? true : false;
        for (var i = 0; i < this.router.config.length; i++) {
            var routePath:any = this.router.config[i];
            if(routePath != undefined && routePath.component != undefined && routePath.component.view != undefined){
                var path = routePath.path;
                var view: ViewRegistration = routePath.component.view;
                if(!priv.hasPrivileges(view.getPrivs())){
                    continue;
                }
                this.processPath(path, view);
            }
        }
        for(var key in this.views){
            let navView = this.views[key]; 
            let subviewActive = this.subViewActive(navView);
            if(subviewActive){
                navView.setSelected(true);
            }
            this.links.push(navView);
        }
    }
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.visible = params.hideNav ==  true ||  params.hideNav ==  "true" ? false : true;
            //console.log(this.visible); 
          }
        );
    }

    processPath(path, view){
        var root = path.split("/")[0];
        if(this.views[root] == undefined){
            this.views[root] = view;
        }
        else{
            this.views[root].addSubView(view);
        }
    }
    isActive(view){
        var link = view.link.split("/")[1];
        var v: ViewRegistration = this.views[link];
        if(view == this.selectedView && view.link){
            return true;
        }
        if(v == undefined){
            return false;
        }

        return false;
    }

    isOver(view){
        var link = view.link.split("/")[1];
        var v: ViewRegistration = this.views[link];
        if(v && this.currentlyOver == v.link){
            return true;
        }
    }

    shouldShowChildren(viewRegistration:ViewRegistration){
        let url = this.router.url;
        let subviewActive = this.subViewActive(viewRegistration);
        let shouldShow = viewRegistration.subViews != undefined && viewRegistration.subViews.length > 0 && viewRegistration.selected && viewRegistration.isViewVisible();

        return shouldShow;
    }
    subViewActive(view: ViewRegistration){
        var v: ViewRegistration = view;
        var url = this.window.location.pathname;
        if(url.indexOf("?") >= 0){
            url = url.split("?")[0];
        }
        if(v){
            for(var key in v.subViews){
                var sV: ViewRegistration = v.subViews[key];
                //console.log("Router URL: " + this.router.url + " SubView Link: " + sV.link);
                if(sV.selected || url == sV.link){
                    return true;
                }
            }
        }
        return false;
    }

    
    navigateToLink(viewRegistration: ViewRegistration, $event : MouseEvent){
        if(viewRegistration && !viewRegistration.link){
            viewRegistration.setSelected(!viewRegistration.selected);
        }
        if(viewRegistration && viewRegistration.link){
            if(this.selectedView && viewRegistration != this.selectedView){
                this.selectedView.setSelected(false);
            }
            this.selectedView = viewRegistration;
            this.selectedView.setSelected(true);
            if($event.ctrlKey || ($event.which == 2 || $event.button == 4 ) || $event.metaKey){
                window.open(viewRegistration.link, '_blank');
            }
            else if($event.shiftKey){
                window.open(viewRegistration.link, '_blank', "location=yes,height=1080,width=1080,scrollbars=yes,status=yes");
            }
            else {
                this.router.navigateByUrl(viewRegistration.link).catch((e) => {
                    console.log(e);
                });
            }
        }
    }

    checkFullActive(view : ViewRegistration){
        var url = this.router.url;
        if(url.indexOf("?") >= 0){
            url = url.split("?")[0];
        }
        return url == view.link
    }

    registerView(newView){
        this.links.push(newView);
    }

    toggleSize(){
        this.miniMode = !this.miniMode;
        GlobalScope.sideNavChange.next(this.miniMode);
        this.options.setItem(OptionsService.SENET_EXPAND_KEY, this.miniMode);
    }
}
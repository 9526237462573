<mat-toolbar color="primary" *ngIf="visible">
    <div class="logo">
        <a href="http://senetco.com">
            <img src="../../assets/img/light_logo.png">
        </a>
    </div>    
    <!--<span class="item-padding">{{title}}</span>-->
    <mat-progress-spinner *ngIf="loading" mode="indeterminate" color="accent" [style.zindex]="10000" diameter="35"></mat-progress-spinner>
    <span class="header-spacer"></span>
    <button  mat-icon-button (click)="toggleAutoRefresh()" *ngIf="autoRefreshEnabled">
        <mat-icon aria-label="Play" *ngIf="!autoRefresh">play_arrow</mat-icon>
        <mat-icon aria-label="Pause" *ngIf="autoRefresh">pause</mat-icon>
    </button>
        <div *ngIf="autoRefreshEnabled">
        <button [disabled]="!autoRefresh" mat-icon-button  class="time-dropdown" [matMenuTriggerFor]="intervalMnu"> 
                <mat-icon aria-label="PollingInterval">keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #intervalMnu="matMenu">
            <div *ngFor="let time of timeIntervals">
                <button mat-menu-item [class.selected]="interval == time.value" (click)="interval != time.value && setTimeInterval(time.value)">{{time.label}}</button>
            </div>
        </mat-menu>
    </div>
    <button mat-icon-button  [matMenuTriggerFor]="eventMnu" *ngIf="eventsEnabled"> 
        <mat-icon aria-label="Events" [matBadge]="eventCount" [matBadgeHidden]="eventCount == 0" matBadgeColor="warn" matBadgePosition="below after" matBadgeSize="medium">notifications</mat-icon>
    </button>
    <mat-menu #eventMnu="matMenu">
        <div *ngFor="let event of events">
            <button mat-menu-item [innerHTML]="event.info"></button>
        </div>
        <div *ngIf="eventCount == 0">
            <button mat-menu-item>No Events</button>
        </div>
    </mat-menu>
    <button mat-icon-button  [matMenuTriggerFor]="appMenu">
        <mat-icon aria-label="Account">account_circle</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu">
        <button *ngIf="username" mat-menu-item><mat-icon aria-label="User">account_circle</mat-icon>{{username}}</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="navigateToLink('settings')"><mat-icon aria-label="Settings">settings</mat-icon>Settings</button>
        <button mat-menu-item (click)="logout()" *ngIf="!logoutDisabled"><mat-icon aria-label="Logout">logout</mat-icon>Logout</button>
    </mat-menu>
</mat-toolbar>  
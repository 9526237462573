import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface Condition {
    field : string;
    comparison : string; 
    threshold : any;
}

export interface EventConfig {
    id : string;
    owner : string;
    conditions : Condition[];
    emailList? : string;
    state : boolean;
    executeFunctionBlock: any;
}

export enum EventThresholdDataType {
    String = "String",
    Number = "Number",
    Date = "Date",
    Boolean = "Boolean"
}

export const EventComparisons = { 
    "EXISTS" : {
        "label" : "Exists",
        "noInput" : true
    }, 
    "NOT EXISTS" : {
        "label" : "Does not Exist",
        "noInput" : true
    }, 
    "<" : {
        "label" : "Less Than",
        "noInput" : false,
        "allowedTypes" : [EventThresholdDataType.Number, EventThresholdDataType.Date]
    }, 
    "<=" : {
        "label" : "Less Than Equal To",
        "noInput" : false,
        "allowedTypes" : [EventThresholdDataType.Number, EventThresholdDataType.Date]
    },  
    ">" : {
        "label" : "Greater Than",
        "noInput" : false,
        "allowedTypes" : [EventThresholdDataType.Number, EventThresholdDataType.Date]
    },  
    ">=" : {
        "label" : "Greater Than Equal",
        "noInput" : false,
        "allowedTypes" : [EventThresholdDataType.Number, EventThresholdDataType.Date]
    },  
    "=" : {
        "label" : "Equals",
        "noInput" : false,
        "allowedTypes" : [EventThresholdDataType.Number, EventThresholdDataType.Date, EventThresholdDataType.String, EventThresholdDataType.Boolean]
    },  
    "!=" : {
        "label" : "Does Not Equal",
        "noInput" : false,
        "allowedTypes" : [EventThresholdDataType.Number, EventThresholdDataType.Date, EventThresholdDataType.String, EventThresholdDataType.Boolean]
    }, 
    "~=" : {
        "label" : "Contains",
        "noInput" : false,
        "allowedTypes" : [EventThresholdDataType.String]
    }, 
    "!~=" : {
        "label" : "Does Not Contain",
        "noInput" : false,
        "allowedTypes" : [EventThresholdDataType.String]
    }, 
};

@Injectable()
export class EventsConfigService implements DataTableSearchService<EventConfig>, CRUDService<EventConfig>{

    constructor(private http: HttpClient) { }

    create(object: EventConfig): Observable<Message> {
        var href = "events/config";

        return this.http.post<Message>(href, object);
    }

    edit(object: EventConfig): Observable<Message> {
        var href = "events/config";
        return this.http.put<Message>(href, object);
    }
    
    delete(object: EventConfig): Observable<Message> {
        var params = new HttpParams().set("id", object.id);
        return this.http.delete<Message>("events/config", { params : params });
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any): Observable<SenetData<EventConfig>> {
        const href = 'events/config';
        var offset = page * limit;
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(sort != undefined){
            params.set("sortField", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortOrder", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }

        return this.http.get<SenetData<EventConfig>>(requestUrl, { params : params });
    }
}
import { Component, Input, OnInit, Host, ViewChild } from "@angular/core";
import { DetailsNavView } from "aes-common";
import { Device } from "../../../../services/AESDevicesService";


@Component({
    selector: 'device-maps-panel',
    templateUrl: 'devicedetails_maps.html',
    styleUrls: ['./devicedetails_maps.scss']
})
export class DeviceDetailsMapsPanel implements OnInit {

    @Input()
    public device: Device;

    parent: DetailsNavView;

    constructor(@Host() parent: DetailsNavView){
        this.parent = parent;
    }

    ngOnInit(){
    }

}
import { DetailsNavView } from "./details-nav-view/details-nav-view";

export class DetailsViewReg {
    id: string;
    title: string;
    icon: string;
    view: DetailsNavView

    constructor(id, title, icon){
        this.id = id;
        this.title = title;
        this.icon = icon;
    }

    getId(){
        return this.id;
    }
}

import { Component, ViewChild, HostListener } from "@angular/core";
import { ActivatedRoute, Router} from "@angular/router";
import { AfterViewInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import { MatDialog } from "@angular/material/dialog";
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { SenetAceEditor, ConfirmDialog } from "aes-common";
import { AESLookUpService } from "../../../../services/AESLookUpService";

@Component({
    selector: 'lookup-details',
    templateUrl: 'lookup-details.html',
    styleUrls: ['./lookup-details.scss']
})
export class LookUpDetails implements AfterViewInit {
    @ViewChild('pipelineConfigEditor', {static: true}) pipelineConfigEditor: SenetAceEditor;
    @ViewChild("tabGroup", { static: false }) tabGroup: MatTabGroup;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) { 
        if((event.ctrlKey || event.metaKey) && event.key == 's'){
            event.preventDefault();
            this.save(false);
        }
    }

    glId: string;
    instance: any;
    editType: string = "Create";
    titleSuffix: string;
    title: string;

    inProgress: boolean = false;
    saveBlocked: boolean;
    error: string;
    mode : string = "ace/mode/javascript";
    codeChanged: boolean = false;
    changed: boolean = false;
    canShow: boolean = false;
    selectedTab: number = 0;
  
    // Code editor change trackers
    firstCodeChangeEvent1: boolean = true;

    gapSize: string = "10px";
    private canGoBack: boolean;
    private oldInst: any;

    constructor(public dialog: MatDialog, private route: ActivatedRoute,
        public snackBar: MatSnackBar, public titleService: Title, public serve : AESLookUpService, 
        private router: Router, private location: Location) {
            let me = this;
            this.glId = this.route.snapshot.params["id"];
            this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);
            this.instance = {};
            this.instance.value = {};

            if(this.glId == '-1'){
                this.oldInst = {...this.instance};
                this.canShow = true;   
            }else{
                this.inProgress = true;
                this.serve.get(this.glId).subscribe(res => {
                    var inst = res;
                    if (inst != undefined) {
                        this.instance = {...inst};
                        this.editType = "Edit";
                        this.title = this.instance.entryId;
                    }
    
                    this.titleService.setTitle(this.title);//Overridden if defined in app-routing module.ts
                    if(this.instance.entryId != undefined && this.instance.entryId != ""){
                        this.titleSuffix = " - " + this.instance.entryId;
                    }
 
                    try{
                        if(typeof this.instance.value == "string"){
                            this.instance.value = JSON.parse(this.instance.functionBlocks);
                        }
                    }
                    catch(e){
                        console.log(e);
                    }
                    this.instance.valueStr = JSON.stringify(this.instance.value, null);                
                    this.oldInst = {...this.instance};

                    this.inProgress = false;
                    this.canShow = true;
                }, err =>{
                    this.openSnackBar("Unable to load LookUp: " + this.glId, undefined);
                    this.inProgress = false;
                    this.canShow = true;
                }); 
            }
        
    }
    ngOnInit() {      
    }
    ngAfterViewInit(){ 
        const tabGroup = this.tabGroup;
        if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
      
        tabGroup.selectedIndex = this.selectedTab;         
    }
    isDirty(){
        if(!this.changed && this.oldInst){
            this.changed = JSON.stringify(this.oldInst) != JSON.stringify(this.instance);
        }
        return this.changed;
    }
    handleBack($event){
        $event.preventDefault();
        this.cancel();
    }
    selectedTabChanged(tabChangeEvent: MatTabChangeEvent){
        let index = tabChangeEvent.index;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {"tab": index},
            replaceUrl: true,
            queryParamsHandling: 'merge'

        });        
    }
    save(backOnSave:boolean = true) {
        var obs;
        this.error = "";
        if(this.inProgress || this.saveBlocked || !this.isDirty()){
            return;
        }
        let configAnnErrors = this.pipelineConfigEditor.getAnnotationErrors();
        if(configAnnErrors.length > 0){
            let firstError = configAnnErrors[0];
            let errLine = firstError.row + 1;
            let errCol = firstError.column + 1;
            let errText = firstError.text;
            this.error = "Value Error: " + errText + " at line " + errLine + " column " + errCol;
            return;
        }        
        if(this.instance.collectionId == undefined || this.instance.collectionId == ''){
            this.error = "A Collection ID is required";
            return;
        }
        if(this.instance.entryId == undefined || this.instance.entryId == ''){
            this.error = "A Entry ID is required";
            return;
        }
        if(this.instance.valueStr == undefined || this.instance.valueStr == ''){
            this.error = "Value are required";
            return;
        }
        try{
            this.instance.value = JSON.parse(this.instance.valueStr);
        }
        catch(e){
            this.error = "Value is not a valid JSON format";
            return;
        }
        var me = this;
        
        if(this.editType == "Create"){
            obs = this.serve.create(this.instance);
        }
        else{
            obs = this.serve.edit(this.instance);
        }

        this.inProgress = true;
        obs.subscribe(resp => {
            me.inProgress = false;
            me.changed = false;
            if(backOnSave){
                this.goBack(me);
            }
        }, (err) => {
            me.inProgress = false;
            me.changed = false;            
            console.log(err);
            if(err && err.error && err.error.message){
                me.error = err.error.message;
                if(me.error == "Internal Server Error"){
                   me.error = "Server was unable to process your request";
                }
            }else{
                me.error = JSON.stringify(err);
                if(!me.error){
                    "An unknown server error occurred";
                }
            }
        });
    }
    onCodeChanged(codeId){
        if(!this.firstCodeChangeEvent1){
            this.codeChanged = true;
        }
        if(codeId == 'pipelineConfigEditor'){
            this.firstCodeChangeEvent1 = false;
        }
       
    }    
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    } 

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    } 

    cancel(){
        let me = this;
        if(me.changed){
            me.dialog.open(ConfirmDialog, {
                width: '600px',
                data: {
                    "title" : "Unsaved Changes",
                    "message" : "There are unsaved changes, are you sure you want to leave?",
                    "success": function(){
                        me.goBack(me);
                    }
                }
            });             
        }else{
            me.goBack(me);
        } 
    }  
    goBack(me){
        if(me.canGoBack){
            me.location.back();
        }else{
            me.router.navigateByUrl("/metadata/lookup",{
                replaceUrl: true
            });
        }
    }   
    
}

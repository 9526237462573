import { Component, Input, HostListener,  ViewChild} from "@angular/core";
import {Router} from '@angular/router';
import { ViewRegistration } from "aes-common";
import { ColumnConfig, DataTable, DataTableOptions, ColumnDataType } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LookUp, AESLookUpService } from "../../../services/AESLookUpService";
import { Utils } from "../../../services/Utils";

@Component({
    template: '<a (click)="showEditor($event, element)">{{element.collectionId}}</a>'
})
export class ShowLookUpEditorCell{
    @Input()
    element: LookUp;

    @Input()
    col: ColumnConfig;

    dialogRef: any;

    @HostListener("click")showEditor(evt, element){
        evt.stopPropagation();
        this.router.navigateByUrl("/metadata/lookup/details/" + element.id);
    }
    constructor(public dialog: MatDialog, public table: AESLookUp, private router: Router){
    }    
}

@Component({
    selector: 'LookUp',
    templateUrl: 'lookup.html',
    styleUrls: ['./lookup.scss']
})
export class AESLookUp {
    static view = new ViewRegistration("Look Up Tables", "/metadata/lookup", "input", [], function(){
        return !Utils.isAdminUser();
    });

    dtOptions:DataTableOptions  = {pageSize : 100, sorting: false, columnFiltering: true, filtering: false, showSelectionColumn: false, multiSelect: false};

    columnCfgs: ColumnConfig[] = [{
        header: "Collection",
        field: "collectionId",
        contentComponent: ShowLookUpEditorCell,
        filterable: true,
        defaultSort: true
    },{
        header: "Entry ID",
        field: "entryId",
        filterable: true
    },{
        header: "Created",
        field: "created",
        dataType: ColumnDataType.DATE,
        renderContent: function(col: ColumnConfig, element: LookUp){
            var date = new Date(element.created);
            return date.toLocaleString();
        },
        filterable: true
    },{
        header: "Last Updated",
        field: "updated",
        dataType: ColumnDataType.DATE,
        renderContent: function(_, element: LookUp){
            var date = new Date(element.created);
            return date.toLocaleString();
        },
        filterable: true
    },{
        header: "Value",
        field: "value",
        visible : false,
        filterable : false,
        renderContent: function(col: ColumnConfig, element: LookUp){
            return JSON.stringify(element.value, undefined, 4);
        },
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    @ViewChild(DataTable, { static: false })dt : DataTable;

    constructor(public dialog: MatDialog, public httpClient: AESLookUpService,  
        public snackBar: MatSnackBar, private router: Router) {
        var me = this;
        this.buttons = [ButtonConfig.createAddButton("Create Entry", function(dt, selected){
            me.router.navigateByUrl("/metadata/LookUp/details/-1");
            /*
            const dialogRef = me.dialog.open(LookUpEditDialog, {
                width: '600px',
                data: {}
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
            */
        }), ButtonConfig.createEditButton("Edit Entry", function(dt, selected){
            me.router.navigateByUrl("/metadata/LookUp/details/" + selected[0].id);
            /*
            const dialogRef = me.dialog.open(LookUpEditDialog, {
                width: '600px',
                data: {
                    "instance": selected[0]
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
            */
        }), ButtonConfig.createDeleteButton("Delete Entry", function(selected){
            return "Are you sure you want to delete Entry " + selected.collectionId + " " + selected.entryId + "?";
        }, function(selected, dt){
            me.httpClient.delete(selected)
            .subscribe(resp => {
                me.openSnackBar(selected.username + " Deleted!", undefined);
                dt.refresh(dt, selected);
            }, (err) => {
               console.log(err);
               var message = err.error.message;
               if(message == undefined){
                message = "Unknown";
               }
               if(message == "Internal Server Error"){
                message = "Server was unable to process your request";
               }
               var error = "Error deleting entry: " + message;
               
               me.openSnackBar(error, undefined);
               dt.refresh(dt, selected);
            });
        },dialog)];
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

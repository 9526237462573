import { Component, Input, HostListener, ViewChild, OnInit } from "@angular/core";
import { ViewRegistration } from "aes-common";
import { ColumnConfig, DataTableOptions, DataTable } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { Utils } from "../../services/Utils";
import { AESSystemSettingsService, SystemSettings } from "../../services/AESSystemSettingsService";
import { SystemSettingsEditDialog } from "../../dialogs/systemsettingsdialog/systemsettingsdialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    template: '<a (click)="showEditor($event, element)">{{element.id}}</a>'
})
export class ShowAESSystemSettingsEditorCell{
    @Input()
    element: SystemSettings;

    @Input()
    col: ColumnConfig;

    dialogRef: any;

    @HostListener("click")showEditor(evt, element){
        evt.stopPropagation();
        if(!this.dialogRef){
            //this.table.buttons[1].click(this.table.dt, [element]);
            this.dialogRef = this.dialog.open(SystemSettingsEditDialog, {
                width: '600px',
                data: {
                    "instance": element
                }
            });
    
            this.dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    this.table.dt.refresh(this.table.dt, [element]);
                }
                this.dialogRef = undefined;
            });            
            console.log("Called click handler");
        }

    }
    constructor(public dialog: MatDialog, public table: AESSystemSettings){
    }    
}

@Component({
    template: '<span [matTooltip]="valueTitle" matTooltipPosition="right">{{value}}</span>'
})
export class AESSystemSettingValueCell implements OnInit{
    @Input()
    element: SystemSettings;

    @Input()
    col: ColumnConfig;

    dialogRef: any;
    value : string
    valueTitle : string;

    ngOnInit(){
        var value = this.element.value;
        if(typeof value === 'object' && !Array.isArray(value) && value !== null){
            value = JSON.stringify(value, undefined, 4);
        }
        this.valueTitle = value;
        if(typeof value == 'string' && value.length > 20){
            value = value.substring(0, 20) + "...";
        }
        this.value = value;
    }

    constructor(public dialog: MatDialog, public table: AESSystemSettings){
    }    
}

@Component({
    selector: 'system-settings',
    templateUrl: 'system-settings.html',
    styleUrls: ['./system-settings.scss']
})
export class AESSystemSettings {
    static view = new ViewRegistration("System Settings", "/admin/syssettings", "settings", [], Utils.isAdminUser);

    dtOptions:DataTableOptions  = {pageSize : 100, showSelectionColumn: false, multiSelect: false};
    @ViewChild(DataTable, { static: true })
    dt : DataTable    
    columnCfgs: ColumnConfig[] = [{
        header: "ID",
        field: "id",
        defaultSort: true,
        contentComponent: ShowAESSystemSettingsEditorCell
    },{
        header: "Value",
        field: "value",
        contentComponent : AESSystemSettingValueCell
    },{
        header: "Account",
        field: "acctId"
    },{
        header: "Username",
        field: "username"
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: AESSystemSettingsService, public snackBar: MatSnackBar) {
        var me = this;
        this.buttons = [ButtonConfig.createAddButton("Create Setting", function(dt, selected){
            const dialogRef = me.dialog.open(SystemSettingsEditDialog, {
                width: '600px',
                data: {}
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }), ButtonConfig.createEditButton("Edit Setting", function(dt, selected){
            const dialogRef = me.dialog.open(SystemSettingsEditDialog, {
                width: '600px',
                data: {
                    "instance": selected[0]
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }),
        ButtonConfig.createDeleteButton("Delete Setting", function(selected){
            return "Are you sure you want to delete this setting?";
        }, function(selected, dt){
            me.httpClient.delete(selected)
            .subscribe(resp => {
                me.openSnackBar("Setting Deleted!", undefined);
                dt.refresh(dt, selected);
            }, (err) => {
               console.log(err);
               var message = err.error.message;
               if(message == undefined){
                message = "Unknown";
               }
               if(message == "Internal Server Error"){
                message = "Server was unable to process your request";
               }
               var error = "Error deleting entry: " + message;
               
               me.openSnackBar(error, undefined);
               dt.refresh(dt, selected);
            });
        },dialog)];
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

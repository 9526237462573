import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmDialog } from "aes-common";
import { ViewRegistration, OptionsService } from "aes-common";
import { ColumnConfig, DataTableOptions, DataTable } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { EventsService, Event, CustomEventsFilter } from "../../services/EventsService";

export class EventsNode {
    static view = new ViewRegistration("Events", "", "notifications", []);
}

@Component({
    selector: 'events',
    templateUrl: 'events.html',
    styleUrls: ['./events.scss']
})
export class Events {
    static view = new ViewRegistration("Event History", "/events/history", "notifications", []);
    static EVENT_STATE_SELECTION = "EVENT_STATE_SELECTION";
    dtOptions:DataTableOptions  = {pageSize : 100, showSelectionColumn: false, multiSelect: false};
    /*
    {
        id: string,
        owner: string,
        state: string,
        information: string,
        openDate: Date,
        clearedDate: Date,
        count: number,
        lastOccurrence: Date
    }
    */
    columnCfgs: ColumnConfig[] = [{
        header: "ID",
        field: "id"
    },{
        header: "Event Config ID",
        field: "eventConfigId"
    },{
        header: "State",
        field: "state"
    },{
        header: "Instance",
        field: "instance"
    },{
        header: "Information",
        field: "information"
    },
    {
        header: "Last Occurrrence",
        field: "lastOccurrence",
        renderContent(col : ColumnConfig, element : Event){
            if(element.lastOccurrence != undefined){
                var dat = new Date(element.lastOccurrence);
                if(!isNaN(dat.getTime()) && dat.getTime() != 0){
                    return dat.toLocaleString();
                }
            }
            return "";
        },
        defaultSort: true
    },
    {
        header: "Opened",
        field: "openDate",
        renderContent(col : ColumnConfig, element : Event){
            if(element.openDate != undefined){
                var dat = new Date(element.openDate);
                if(!isNaN(dat.getTime()) && dat.getTime() != 0){
                    return dat.toLocaleString();
                }
            }
            return "";
        }
    },
    {
        header: "Cleared",
        field: "clearedDate",
        renderContent(col : ColumnConfig, element : Event){
            if(element.clearedDate != undefined){
                var dat = new Date(element.clearedDate);
                if(!isNaN(dat.getTime()) && dat.getTime() != 0){
                    return dat.toLocaleString();
                }
            }
            return "";
        }
    },
    {
        header: "Count",
        field: "count"
    }
    ];

    buttons: ButtonConfig[] = [];


    gapSize: string = "10px";
    eventOptions = ["All", "Open", "Cleared"];

    selectedState : string = this.eventOptions[0];

    customFilter : CustomEventsFilter;

    @ViewChild(DataTable, { static: true })
    dt : DataTable

    constructor(public dialog: MatDialog, public httpClient: EventsService, public options : OptionsService,  public snackBar: MatSnackBar) {
        var state = this.options.getItem(Events.EVENT_STATE_SELECTION, this.selectedState);
        this.selectedState = state;
        var setState = state;
        if(state == "All"){
            setState = undefined;
        }
        this.customFilter = {
            state : setState
        };
        var me = this;
        this.buttons = [
            {
                icon: "check_circle",
                selection: ButtonConfig.SelectionType.SINGLE,
                title: "Clear Event",
                selectionCondition : function(dt, selected){
                    return selected.length == 0 || selected[0].state == "Cleared";
                },
                click: function(dt, selected){
                    if(selected[0].state == "Cleared"){
                        me.openSnackBar("Event is already cleared", undefined);
                        return;
                    }
                    dialog.open(ConfirmDialog, {
                        width: '600px',
                        data: {
                            "title" : "Clear Event",
                            "message" : "Are you sure you want to clear this event?",
                            "success": function(){
                                selected[0].state = "Cleared";
                                me.httpClient.edit(selected[0])
                                .subscribe(resp => {
                                    me.openSnackBar("Event Cleared!", undefined);
                                    dt.refresh(dt, selected);
                                }, (err) => {
                                   console.log(err);
                                   var message = err.error.message;
                                   if(message == undefined){
                                    message = "Unknown";
                                   }
                                   if(message == "Internal Server Error"){
                                    message = "Server was unable to process your request";
                                   }
                                   var error = "Error creating entry: " + message;
                                   
                                   me.openSnackBar(error, undefined);
                                   dt.refresh(dt, selected);
                                });
                            }
                        }
                    });
                }
            },
        ButtonConfig.createDeleteButton("Delete Event", function(selected){
            return "Are you sure you want to delete this Event?";
        }, function(selected, dt){
            me.httpClient.delete(selected)
            .subscribe(resp => {
                me.openSnackBar("Event Deleted!", undefined);
                dt.refresh(dt, selected);
            }, (err) => {
               console.log(err);
               var message = err.error.message;
               if(message == undefined){
                message = "Unknown";
               }
               if(message == "Internal Server Error"){
                message = "Server was unable to process your request";
               }
               var error = "Error deleting entry: " + message;
               
               me.openSnackBar(error, undefined);
               dt.refresh(dt, selected);
            });
        },dialog)]
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }

    stateChange(){
        if(this.selectedState != "All"){
            this.customFilter.state = this.selectedState;
        }
        else{
            this.customFilter.state = undefined;
        }
        this.options.setItem(Events.EVENT_STATE_SELECTION, this.selectedState);
        this.dt.refresh(undefined, undefined);
    }
}

<h2 mat-dialog-title>{{editType}} Connection</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm">
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="Host" required [(ngModel)]="instance.host" name="connection-edit-host-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="Database" required [(ngModel)]="instance.defaultDb" name="connection-edit-database-field">
        </mat-form-field>
        <mat-checkbox *ngIf="editType == 'Create'" [disabled]="inProgress" name="connection-edit-create-db" [(ngModel)]="instance.createDb">Create Database?
        </mat-checkbox>
        <mat-form-field>
            <input  [disabled]="inProgress" matInput placeholder="Username" [(ngModel)]="instance.user" name="connection-edit-user-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="Password" type="password" [(ngModel)]="instance.password" name="connection-edit-password-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="Port" type="number" [(ngModel)]="instance.port" name="connection-edit-port-field">
        </mat-form-field>
        <mat-checkbox  *ngIf="editType == 'Create'" [disabled]="inProgress" name="connection-edit-create-dm" [(ngModel)]="instance.createTable">Create Tables From Database?
        </mat-checkbox>
        <mat-form-field>
            <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Account" [(ngModel)]="instance.acctId" name="application-edit-acctId-field">
                <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                    {{account.name}} ({{account.acctId}})
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
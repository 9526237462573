<div class="container" fxLayout="column" [fxLayoutGap]="gapSize">
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize" *ngIf="events != undefined">
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100">
            <div card-title>
                Device Map
            </div>
            <div card-body>
                <div class="buttonLayer">
                    <div fxLayout="column" fxLayout.xs="column" [fxLayoutGap]="gapSize" *ngIf="events != undefined">
                        <button mat-mini-fab matTooltip="Reset View" (click)="resetView()" color="warn">
                            <mat-icon aria-hidden="true">home</mat-icon>
                        </button>
                        <button mat-mini-fab matTooltip="Refresh" (click)="refresh()"  color="warn">
                            <mat-icon aria-hidden="true">refresh</mat-icon>
                        </button>
                        <button mat-mini-fab matTooltip="Toggle Marker Labels" (click)="toggleLabels()"  color="warn">
                            <mat-icon aria-hidden="true" *ngIf="!labelsEnabled">label</mat-icon>
                            <mat-icon aria-hidden="true" *ngIf="labelsEnabled">label_off</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="infoPanelLayer" *ngIf="showInfoPanel">
                    <div class="infoPanelContent">
                        <div class="infoPanelButtons">
                            <button mat-icon-button aria-label="close info panel" matToolTip="Close Panel" (click)="closeInfoPanelDlg()">
                                <mat-icon>close</mat-icon>
                              </button>
                        </div>
                        <mat-tab-group class="infoPanelTabs">
                            <mat-tab label="Device">
                                <mat-icon class="device-img">devices</mat-icon>
                                <table class="info-table">
                                    <tr>
                                        <td>Device:</td>
                                        <td><a [routerLink]="['/devices/details',selectedEvent.devEui]">{{selectedEvent.devEui}}</a></td>
                                    </tr>
                                    <tr *ngFor="let det of details">
                                        <td>{{det.label}}:</td>
                                        <td>{{det.value}}</td>
                                    </tr>
                                </table>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
                <google-map [ngClass]="{'insideDetails': device != undefined, 'outsideDetails': device == undefined}" (tilesloaded)="mapReady($event)" #googleMap [latitude]="lat" [longitude]="lng" [fitBounds]="false">
                    <!--<search-map-control></search-map-control> -->
                    <map-marker-clusterer imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                        <div *ngFor="let event of events.data" >
                            <map-marker [options]="markerOptions" *ngIf="hasLocation(event)" (mapClick)="!device && navigateToDetails(event.devEui, $event)" (mapRightclick)="showInfoPanelDlg(event)" [title]="event.devEui" [label]="labelsEnabled ? event.label : ''" [position]="getEventPosition(event)" [clickable]="true"></map-marker>
                        </div>
                    </map-marker-clusterer>
                </google-map>
            </div>
            <div card-toolbar>
                <mat-form-field>
                    <input [disabled]="loading" matInput placeholder="Filter" (keyup.enter)="onKey($event)" [(ngModel)]="filter" name="maps-search">
                    <button mat-button *ngIf="filter && filter != ''" matSuffix mat-icon-button aria-label="Clear" (click)="filter='';onKey()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </senetcard>
    </div>
    <mat-spinner *ngIf="events == undefined && loading" class="body-loading-spinner"></mat-spinner>
</div>
<div class="container" fxLayout="column" [fxLayoutGap]="gapSize">
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100">
            <div card-title>
                Log Configuration
            </div>
            <div card-body>
                <datatable tableId="aesLogConfigurationTable" [dtOptions]="dtOptions" [customFilter]="customFilter"
                    [searchService]="httpClient" [buttonCfg]="buttons" [columnCfg]="columnCfgs">
                    <div dt-custom-controls>

                        <mat-form-field>
                            <mat-label>Ownership</mat-label>
                            <mat-select [(ngModel)]="selectedOwnership" (selectionChange)="ownershipChange()">
                              <mat-option *ngFor="let type of ownershipFilter" [value]="type">
                                {{type}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </datatable>
            </div>
        </senetcard>
    </div>
</div>
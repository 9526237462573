import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { ColumnConfig } from 'aes-datatables';
import { OptionsService } from 'aes-common';
import { User } from './AESUserService';
import { Account } from './AESAccountService';

@Injectable()
export class Utils {
    static DEVICES_API_KEY: string = "";
    static DEVICES_API_ENDPOINT: string = environment.DEVICES_API_ENDPOINT;
    static DECIMAL_PRECISION = 2;
    static APPLICATION_TITLE = "AES Portal";
    static SEN_AUTH_TOKEN_KEY = "SEN_PORTAL_AUTH_TOKEN";

    //Local Storage Keys
    static SEN_DECIMAL_PRECISION_KEY = "SEN_DECIMAL_PRECISION";
    static SEN_SAVED_API_KEY_KEY = "SEN_SAVED_API_KEY";
    static SEN_GEOCODE_FIELD_KEY = "SEN_GEOCODE_FIELD";
    static SEN_ACE_TAB_SPACES_KEY = "SEN_ACE_TAB_SPACES";
    static SEN_ACE_TABS_AS_SPACES_KEY = "SEN_ACE_TABS_AS_SPACES";
    static SEN_HIDDEN_VIEWS_KEY = "SEN_HIDDEN_VIEWS";
    
    static AES_USER_OBJ : User;
    static AES_ACCOUNT_OBJ : Account;

    static truncateContent(content: String, length? :number){
        if(length == undefined){
            length = 16;
        }
        if(content.length > length){
            return content.substring(0, length) + "...";
        }
        return content;
    }

    static renderContent(col: ColumnConfig, element: any){
        var value = element[col.field];
        if(typeof value == "string"){
            var date = new Date(value);
            var dateStr = date.toLocaleString();
            if(dateStr != "Invalid Date"){
                return dateStr;
            }
            return value;
        }
        else if(value == undefined){
            return "";
        }
        else if(typeof value == "number"){
            if(value % 1 != 0 && Utils.DECIMAL_PRECISION != -1)
                return value.toFixed(Utils.DECIMAL_PRECISION);
            else{
                return value;
            }
        }
        else if(value.constructor === ({}).constructor){
           return JSON.stringify(value);
        }
        return value;
    }

    static isAdminUser(){
        var ret = Utils.AES_ACCOUNT_OBJ != undefined && Utils.AES_ACCOUNT_OBJ.acctId == -1;
        return ret;
    }

    static relativeDate(value: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            const intervals = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + i + ' ago'; // singular (1 day ago)
                    } else {
                        return counter + ' ' + i + 's ago'; // plural (2 days ago)
                    }
            }
        }
        return value;
    }
    
    static isAuthTokenValid(options :OptionsService){
        var auth = options.getItem(Utils.SEN_AUTH_TOKEN_KEY, undefined);
        if(auth != undefined && auth != ""){
            var parts = auth.split(";");
            var expires = undefined;
            var session = undefined;
            var created = undefined;
            var maxAge = undefined;
            for(let part of parts){
                if(part.indexOf("Expires=") >= 0){
                    var expParts = part.split("=");
                    expires = new Date(expParts[1]);
                    if(expires < (new Date())){
                        options.setItem(Utils.SEN_AUTH_TOKEN_KEY, "");
                        console.log("Token has expired: " + expires);
                        return false;
                    }
                }
                else if(part.indexOf("SESSIONID=") >= 0){
                    var authParts = part.split("=");
                    session = authParts[1];
                }
                else if(part.indexOf("Created=") >= 0){
                    var createdParts = part.split("=");
                    created = new Date(createdParts[1]);
                }
                else if(part.indexOf("Max-Age=") >= 0){
                    var maxAgeParts = part.split("=");
                    maxAge = parseInt(maxAgeParts[1]);
                }
            }
            if(created != undefined && maxAge != undefined){
                var diff = (new Date()).getTime() - created.getTime();
                if(diff > maxAge){
                    options.setItem(Utils.SEN_AUTH_TOKEN_KEY, "");
                    console.log("Token is too old Age: " + diff + " Max Age: " + maxAge)
                    return false;
                }
            }
            return expires != undefined && session != undefined;
        }
        return false;
    }

    static getAuthToken(options :OptionsService){
        if(!Utils.isAuthTokenValid(options)){
            console.log("No valid token found");
            return undefined;
        }
        var auth = options.getItem(Utils.SEN_AUTH_TOKEN_KEY, undefined);
        if(auth != undefined && auth != ""){
            var parts = auth.split(";");
            for(let part of parts){
                if(part.indexOf("SESSIONID=") >= 0){
                    var authParts = part.split("=");
                    return authParts[1];
                }
            }
        }
        return undefined;
    }

    static setAuthToken(options :OptionsService, token : string){
        if(token != undefined && token != ""){
            token += "; Created=" + (new Date()).toISOString();
        }
        options.setItem(Utils.SEN_AUTH_TOKEN_KEY, token);
    }
}
import { Component, EventEmitter, Output, OnInit } from "@angular/core";
import { UserService } from "../../services/UserService";
import { HttpResponse } from "@angular/common/http";
import { environment } from '../../environments/environment';
import {CookieService} from 'ngx-cookie';
import { OptionsService } from "aes-common";
import { Utils } from "../../services/Utils";

@Component({
    selector: 'login',
    templateUrl: 'login.html',
    styleUrls: ['./login.scss']
})
export class Login implements OnInit {
    @Output()
    public onLogin: EventEmitter<string> = new EventEmitter<string>();

    apiKey: string;
    username: string;
    password: string;
    error: string;
    inProgress: boolean;
    useAPIKey: boolean = environment.USE_API_KEY;
    getSessionInProgress: boolean;

    constructor(public userService : UserService, public options : OptionsService){

    }

    ngOnInit(){
        var me = this;
        if(environment.USE_COOKIES){
            this.getSessionInProgress = true;
            this.userService.getSession().subscribe((res:HttpResponse<any>) =>{
                me.onLogin.emit();
                me.getSessionInProgress = false;
            }, (err) =>{
                me.getSessionInProgress = false;
            })
        }
        else if(Utils.isAuthTokenValid(this.options)){
            this.onLogin.emit();
        }
    }

    login(): void {
        this.error = undefined;
        if(this.apiKey == undefined || this.apiKey == ""){
            var me = this;
            if(me.username == undefined){
                me.error = "A username is required";
                return;
            }
            if(me.password == undefined || me.password == ""){
                me.error = "A password is required";
                return;
            }
            this.inProgress = true;
            this.userService.login(me.username, me.password).subscribe((res : HttpResponse<any>) =>{
                console.log(res);
                me.username = undefined;
                me.password = undefined;
                if(!environment.USE_COOKIES){
                    Utils.setAuthToken(me.options, res.headers.get("x-authentication"));
                }
                me.onLogin.emit();
                me.inProgress = false;
            }, (error) =>{
                me.error = error.error.message;
                if(me.error == undefined || me.error == ""){
                    if(error.status == 403){
                        me.error = "Invalid Username or Password";
                    }
                    else{
                        me.error = "Unable to process your request";
                    }
                }
                me.inProgress = false;
            })
        }
        else{
            this.onLogin.emit(this.apiKey);
        }
    }

    formSubmit(e) {
        e.preventDefault();
        this.login();
    }
}


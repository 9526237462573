import { Component, Input } from "@angular/core";

@Component({
    selector: 'senetcard',
    templateUrl: 'SenetCard.html',
    styleUrls: ['./SenetCard.scss']
})
export class SenetCard {
    @Input()
    public isEditDetailsCard: boolean = false;
}
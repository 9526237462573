import { Component, Input, OnInit, AfterViewInit } from "@angular/core";
import { DetailsConfig } from "./detailscfg";
import { OptionsService } from "../../services/Options";
import { DetailsViewReg } from "./detailsview_reg";
import { Observable, Subject } from "rxjs";
import { GlobalScope } from "../../services/GlobalScope";

@Component({
    selector: 'detailsview',
    templateUrl: 'detailsview.html',
    styleUrls: ['./detailsview.scss']
})
export class DetailsView implements OnInit, AfterViewInit {
    @Input()
    config: DetailsConfig;
    
    opened: boolean = true;
    selection: string;
    sideselection: string;
    views: Array<Object>;
    subviews: Array<DetailsViewReg>;
    infoView: DetailsViewReg = new DetailsViewReg("info", "Information", "info");

    selectionEvt:Subject<string> = new Subject<string>();
    selectionEvt$: Observable<string>;

    sideselectionEvt:Subject<string> = new Subject<string>();
    sideselectionEvt$: Observable<string>;

    constructor(private options: OptionsService){
        this.selectionEvt$ = this.selectionEvt.asObservable();
        this.sideselectionEvt$ = this.sideselectionEvt.asObservable();
    }

    ngOnInit(){
        var defaultView = "";
        if(this.config.getViews().length > 0){
            defaultView = this.config.getViews()[0].getId();
        }
        var v = this.options.getItem(OptionsService.DETAILS_PREFIX + "_" + this.config.viewId, defaultView);
        if(this.config.viewMap[v] != undefined){
            this.setView(this.config.viewMap[v]);
        }
        else if(this.config.viewMap[defaultView] != undefined){
            this.setView(this.config.viewMap[defaultView]);
        }
        this.views = this.config.getViews();

        var defaultSideView = "";
        if(this.config.getSubViews().length > 0){
            defaultSideView = this.config.getSubViews()[0].getId();
        }
        var v = this.options.getItem(OptionsService.DETAILS_PREFIX + "_" + this.config.viewId + "_SIDE", defaultSideView);       
        this.subviews = [];
        this.subviews = this.config.getSubViews();
        this.subviews.unshift(this.infoView);
        for(var key in this.subviews){
            var val = this.subviews[key];
            if(val.getId() == v){
                this.setSubView(val);
                break;
            }
        }
        if(this.subviews.length == 1){
            this.setSubView(this.infoView);
        }
    }
    
    setView(view){
        var id = view.getId();
        if(id == this.selection){
            return;
        }
        this.selection = id;
        this.options.setItem(OptionsService.DETAILS_PREFIX + "_" + this.config.viewId, id);
        console.log("Selection Changed: " + this.selection);
        this.selectionEvt.next(this.selection);
    }

    ngAfterViewInit() {
        //This is to prevent timing issues with the nav views loading
        this.selectionEvt.next(this.selection); 
    }

    setSubView(view){
        var id = view.getId();
        if(id == this.sideselection){
            return;
        }
        this.sideselection = id;
        this.options.setItem(OptionsService.DETAILS_PREFIX + "_" + this.config.viewId + "_SIDE", id);
        this.sideselectionEvt.next(this.sideselection);
    }

    toggleOpen(){
        this.opened = !this.opened;
        GlobalScope.detailsSidePanelChange.next(this.opened);
    }
}
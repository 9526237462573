import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AESDeviceProfileService, DeviceProfile } from "../../services/AESDeviceProfileService";
import { AESDevicesService, Device, DeviceUpdate } from "../../services/AESDevicesService";

@Component({
    selector: 'devicebulkdialog',
    templateUrl: 'devicebulkdialog.html',
    styleUrls: ['./devicebulkdialog.css']
})
export class DevicesBulkEditDialog {

    instance: DeviceUpdate;
    editType: string = "Create";

    inProgress: boolean;
    saveBlocked: boolean;
    error: string;
    profiles : DeviceProfile[];
    profilesMap = {};
    devices : Device[];
    editOwner : boolean = false;
    editDeviceProfile : boolean = false;
    sharedDeviceProfile : number;
    sharedOwner : string;
    sharedDevType : string;
    editDevType : boolean = false;
    multiDevProfile : DeviceProfile = {
        "id" : -1,
        "name" : "Multiple",
        "acctId" : undefined,
        "created" : undefined,
        "desciption" : undefined,
        "lastUpdated" : undefined,
        "periodicPipelineEnabled" : undefined,
        "periodicPipelineId" : undefined,
        "periodicPipelinePeriod" : undefined,
        "uplinkPipelineId" : undefined
    };
    multiProfileAdded : boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DevicesBulkEditDialog>, public serve : AESDevicesService, public profileServe : AESDeviceProfileService) {
        this.devices = data.instances;
        var deviceEuis = [];
        this.sharedDeviceProfile = undefined;
        this.sharedOwner = undefined;
        this.sharedDevType = undefined;
        for(let device of this.devices){
            deviceEuis.push(device.devEui);
            if(this.sharedDeviceProfile == undefined){
                this.sharedDeviceProfile = device.deviceProfile;
            }
            if(device.deviceProfile != this.sharedDeviceProfile){
                this.sharedDeviceProfile = -1;
            }

            if(this.sharedOwner == undefined){
                this.sharedOwner = device.owner;
                if(device.owner == undefined){
                    this.sharedOwner = "";
                    device.owner = "";
                }
            }
            if(device.owner != this.sharedOwner){
                this.sharedOwner = "Multiple";
            }

            if(this.sharedDevType == undefined){
                this.sharedDevType = device.devType;
                if(device.devType == undefined){
                    this.sharedDevType = "";
                    device.devType = "";
                }
            }
            if(device.devType != this.sharedDevType){
                this.sharedDevType = "Multiple";
            }
        }
        this.instance = { 
            devices : deviceEuis,
            deviceProfile : this.sharedDeviceProfile,
            owner : this.sharedOwner,
            devType : this.sharedDevType
        };
        var me = this;
        me.inProgress = true;
        profileServe.search(undefined, undefined, undefined, undefined, undefined, {})
        .subscribe(data => {
            me.profiles = data.data;
            if(me.sharedDeviceProfile == -1){
                me.profiles.splice(0, 0, me.multiDevProfile);
                me.multiProfileAdded = true;
            }
            if(me.profiles == undefined || me.profiles.length == 0){
                me.error = "Unable to load profiles, please try again later or contact an administrator.";
            }
            else{
                me.inProgress = false;
            }
        }, (err) => {
        me.error = "Unable to load profiles, please try again later or contact an administrator.";
        });
    }

    onDeviceProfileEditChange(devProfile){
        if(this.profiles[0].name == "Multiple" && this.multiProfileAdded){
            this.profiles.splice(0, 1);
            this.multiProfileAdded = false;
        }
    }

    onDeviceProfileEditChkChange(checkState){
        if(checkState){
        }
        else{
            if(this.sharedDeviceProfile == -1 && !this.multiProfileAdded){
                this.profiles.splice(0, 0, this.multiDevProfile);
                this.multiProfileAdded = true;
            }
            this.instance.deviceProfile = this.sharedDeviceProfile;
        }
    }

    onOwnerEditChkChange(checkState){
        if(checkState){
            if(this.instance.owner == "Multiple"){
                this.instance.owner = "";
            }
        }
        else{
            this.instance.owner = this.sharedOwner;
        }
    }

    onDevTypeEditChkChange(checkState){
        if(checkState){
            if(this.instance.devType == "Multiple"){
                this.instance.devType = "";
            }
        }
        else{
            this.instance.devType = this.sharedDevType;
        }
    }

    ngAfterViewInit(){
    }

    save() {
        this.error = "";
        var me = this;
        var req = {...this.instance};
        if(this.editDeviceProfile && this.instance.deviceProfile == -1){
            this.error = "A device profile must be selected";
            return;
        }
        else if(!this.editDeviceProfile){
            delete req.deviceProfile;
        }

        if(this.editOwner && this.instance.owner == "Multiple"){
            this.error = "A device profile must be selected";
            return;
        }
        else if(!this.editOwner){
            delete req.owner;
        }

        if(this.editDevType && this.instance.devType == "Multiple"){
            this.error = "A device type must be selected";
            return;
        }
        else if(!this.editDevType){
            delete req.devType;
        }
        
        var obs = this.serve.edit(req);

        this.inProgress = true;
        obs.subscribe(resp => {
            this.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
           console.log(err);
           me.error = err.error.message;
           if(me.error == "Internal Server Error"){
               me.error = "Server was unable to process your request";
           }
           me.inProgress = false;
        });
    }
}
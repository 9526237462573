<div class="container" fxLayout="column" [fxLayoutGap]="gapSize">
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100">
            <div card-title>
                Device Events
            </div>
            <div card-body>
                <datatable #dt tableId="device_events_table" [dtOptions]="dtOptions" [searchService]="httpClient" [buttonCfg]="buttons"
                    [columnCfg]="columnCfgs" [tableHeight]="tableHeight"></datatable>
            </div>
        </senetcard>
    </div>
</div>
<h2 mat-dialog-title>{{editType}} Device</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
        <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm" class="device-edit-form">
            <mat-form-field>
                <input [disabled]="true" matInput placeholder="EUI" required [(ngModel)]="instance.devices[0]" name="device-ID-field">
            </mat-form-field>
            <mat-form-field>
                <input [disabled]="inProgress" matInput placeholder="Name" required [(ngModel)]="instance.name" name="device-name-field">
            </mat-form-field>
            <mat-form-field>
                <input [disabled]="inProgress" matInput placeholder="Type" required [(ngModel)]="instance.devType" name="device-devType-field">
            </mat-form-field>
            <mat-form-field>
                <input [disabled]="inProgress" matInput placeholder="Owner" required [(ngModel)]="instance.owner" name="device-owner-field">
            </mat-form-field>
            <mat-form-field>
                <input [disabled]="inProgress" matInput placeholder="Tags" required [(ngModel)]="instance.tags" name="device-tags-field">
            </mat-form-field>
            <mat-form-field>
                <mat-select [disabled]="inProgress" placeholder="Device Profile" [(ngModel)]="instance.deviceProfile" name="device-edit-deviceProfile-field">
                    <mat-option *ngFor="let profile of profiles" [value]="profile.id">
                        {{profile.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress || saveBlocked" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
<div class="container" fxLayout="column" [fxLayoutGap]="gapSize" [ngClass]="{'hidden': !canShow}">
    <div align="end" class="edit-buttons">
        <button mat-button class="back-button" (click)="handleBack($event)">&lt;&nbsp;Back</button>
        <button class="edit-view-buttons" [disabled]="(inProgress || saveBlocked || !isDirty())" mat-button (click)="save()" cdkFocusInitial>Save</button>
        <button class="edit-view-buttons" mat-button (click)="cancel()">Cancel</button>
    </div>    
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">       
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100" [isEditDetailsCard]="true">            
            <div card-title>
                {{editType}} Look Up{{titleSuffix}}                
            </div>            
            <div class="senet-card-body" card-body>
                <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
                <mat-tab-group #tabGroup (selectedTabChange)="selectedTabChanged($event)">  
                    <mat-tab label="Information" class="lu-tab">
                        <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm" class="edit-tab-form info-tab">
                            <mat-form-field>
                                <input [disabled]="inProgress || editType == 'Edit'" matInput placeholder="Collection ID" required [(ngModel)]="instance.collectionId" name="lu-collectionId-field">
                            </mat-form-field>
                            <mat-form-field>
                                <input [disabled]="inProgress" matInput placeholder="Entry ID" required [(ngModel)]="instance.entryId" name="lu-entryId-field">
                            </mat-form-field>
                        </form>
                    </mat-tab>
                    <mat-tab label="Value" class="lu-tab">
                        <form fxLayout="column" fxLayoutGap="10" #configForm="ngForm" class="edit-tab-form">
                            <senet-ace-editor #pipelineConfigEditor  [(code)]="instance.valueStr"  (codeChange)="onCodeChanged('pipelineConfigEditor')" [isForDialog]="false" [mode]="mode"></senet-ace-editor>
                        </form>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </senetcard>
    </div>
</div>

<div class="container" fxLayout="column" [fxLayoutGap]="gapSize">
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100">
            <div card-title>
                Settings
            </div>
            <div card-body fxLayout="column" fxLayout.xs="column" [fxLayoutGap]="gapSize">
                <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
                <mat-form-field class="settings-form-field">
                    <input matInput placeholder="Number Precision" [disabled]="inProgress" [(ngModel)]="tempPrecision">
                    <button mat-button [disabled]="inProgress" *ngIf="tempPrecision != precision" matSuffix mat-icon-button aria-label="Save" (click)="changePrecision(tempPrecision)">
                            <mat-icon>check</mat-icon>
                    </button>
                    <button mat-button [disabled]="inProgress" *ngIf="tempPrecision != precision" matSuffix mat-icon-button aria-label="Clear" (click)="tempPrecision = precision">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="settings-form-field">
                    <mat-select [disabled]="inProgress" placeholder="Default Device Profile" [(ngModel)]="account.defaultDeviceProfileIdUpdate" name="account-edit-deviceProfile-field">
                        <mat-option *ngFor="let profile of profiles" [value]="profile.id">
                            {{profile.name}}
                        </mat-option>
                    </mat-select>
                    <button mat-button [disabled]="inProgress"  *ngIf="account.defaultDeviceProfileIdUpdate != account.defaultDeviceProfileId" matSuffix mat-icon-button aria-label="Save" (click)="changeDefaultProfile(account.defaultDeviceProfileIdUpdate)">
                        <mat-icon>check</mat-icon>
                    </button>
                    <button mat-button [disabled]="inProgress" *ngIf="account.defaultDeviceProfileIdUpdate != account.defaultDeviceProfileId" matSuffix mat-icon-button aria-label="Clear" (click)="account.defaultDeviceProfileIdUpdate = account.defaultDeviceProfileId">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="settings-form-field">
                    <input matInput placeholder="Geocode Field" [disabled]="inProgress" [(ngModel)]="tempGeocodeField">
                    <button mat-button [disabled]="inProgress" *ngIf="tempGeocodeField != geocodeField" matSuffix mat-icon-button aria-label="Save" (click)="changeGeocodeField(tempGeocodeField)">
                            <mat-icon>check</mat-icon>
                    </button>
                    <button mat-button [disabled]="inProgress" *ngIf="tempGeocodeField != geocodeField" matSuffix mat-icon-button aria-label="Clear" (click)="tempGeocodeField = geocodeField">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field> 
                <mat-form-field class="settings-form-field">
                    <input type="number" matInput placeholder="Code Editor Tab Spaces" [disabled]="inProgress" [(ngModel)]="tempEditorTabSpaceField">
                    <button mat-button [disabled]="inProgress" *ngIf="tempEditorTabSpaceField != editorTabSpaceField" matSuffix mat-icon-button aria-label="Save" (click)="changeEditorTabSpaceField(tempEditorTabSpaceField)">
                            <mat-icon>check</mat-icon>
                    </button>
                    <button mat-button [disabled]="inProgress" *ngIf="tempEditorTabSpaceField != editorTabSpaceField" matSuffix mat-icon-button aria-label="Clear" (click)="tempEditorTabSpaceField = editorTabSpaceField">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-checkbox [disabled]="inProgress" [(ngModel)]="tempEditorTabsAsSpacesField">Use Spaces For Tabs</mat-checkbox>
                <button mat-button [disabled]="inProgress" *ngIf="tempEditorTabsAsSpacesField != editorTabsAsSpacesField" matSuffix mat-icon-button aria-label="Save" (click)="changeEditorTabsAsSpacesField(tempEditorTabsAsSpacesField)">
                        <mat-icon>check</mat-icon>
                </button>
                <button mat-button [disabled]="inProgress" *ngIf="tempEditorTabsAsSpacesField != editorTabsAsSpacesField" matSuffix mat-icon-button aria-label="Clear" (click)="tempEditorTabsAsSpacesField = editorTabsAsSpacesField">
                    <mat-icon>close</mat-icon>
                </button>                                                
            </div>
        </senetcard>
    </div>
</div>
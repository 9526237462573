<div [style.height.px]="tableHeightRen">
    <div class="buttonLine" fxLayout="row">
        <div *ngFor="let butCfg of buttonCfg" class="table-button">
            <button mat-icon-button (click)="clickButton(butCfg)" [matTooltip]="butCfg.title"
                [disabled]="isButtonDisabled(butCfg) || isLoadingResults">
                <mat-icon>{{butCfg.icon}}</mat-icon>
            </button>
        </div>
        <div class="rightControls">
            <div class="dt-custom-controls"><ng-content select="[dt-custom-controls]"></ng-content></div>
            <button *ngIf="dtOptions.columnFiltering" mat-icon-button (click)="clickButton(colFilterBtnCfg)" [matTooltip]="colFilterBtnCfg.title"
                [disabled]="isButtonDisabled(colFilterBtnCfg) || isLoadingResults">
                <mat-icon>{{colFilterBtnCfg.icon}}</mat-icon>
            </button>            
            <mat-form-field *ngIf="dtOptions.filtering" class="filterField">
                <input matInput [(ngModel)]="filterVal" (keyup)="applyFilter($event,$event.target.value)" placeholder="Filter">
            </mat-form-field>
            <button *ngIf="filterVal || columnFilters" mat-icon-button (click)="clearFilters()" matTooltip="Clear Filters"
                [disabled]="isLoadingResults">
                <mat-icon>cancel</mat-icon>
            </button>             
        </div>
    </div>
    <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <div class="table-container" [style.height.px]="tableHeightRen-(43*2)">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container *ngIf="dtOptions.showSelectionColumn"  matColumnDef="select" sticky>
                <th mat-header-cell *matHeaderCellDef [style.width.%]="5">
                    <mat-checkbox *ngIf="dtOptions.multiSelect" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td class="noselect" mat-cell *matCellDef="let row;let i = index;">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? toggleSelection($event, row, i) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container *ngFor="let col of columnCfg" [matColumnDef]="col.field">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!dtOptions.sorting" [disableClear]="true" [style.min-width.px]="100" [style.width.%]="95 / columnCfg.length" [disabled]="col.sortDisabled" [matTooltip]="col.title? col.title: ''"
                    [matTooltipDisabled]="col.title == undefined"> {{col.header}} <mat-icon class="filter-active" *ngIf="columnFilters && columnFilters[col.field] != undefined && columnFilters[col.field] != ''">filter_alt</mat-icon></th>
                <td class="noselect" mat-cell *matCellDef="let element"><dt-col [col]="col" [element]="element"></dt-col></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="viewCols; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: viewCols;let i = index;" [ngClass]="{selected: selection.isSelected(row)}" (click)="toggleSelection($event, row, i)"></tr>

        </table>
        <div *ngIf="dataSource == undefined || dataSource.data.length == 0" class="noDataDiv">
            <b *ngIf="filterVal == '' || filterVal == undefined">No Data</b>
            <b *ngIf="filterVal != '' && filterVal != undefined" [style.color]="'red'">No Results for Filter {{filterVal}}</b>
        </div>
    </div>
    <mat-paginator [length]="getLength()" [pageSize]="dtOptions.pageSize" [showFirstLastButtons]="true"></mat-paginator>
</div>
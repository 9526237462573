import { Component, Inject } from "@angular/core";
import { EventsConfigService, EventComparisons, EventThresholdDataType } from "../../services/EventsConfigService";
import { Utils } from "../../services/Utils";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";
import { AESAccountService, Account } from "../../services/AESAccountService";

@Component({
    selector: 'event-config-create-edit',
    templateUrl: 'event-config-create-edit.html',
    styleUrls: ['./event-config-create-edit.css']
})
export class EventConfigurationEditDialog {

    instance: any;
    editType: string = "Create";

    inProgress: boolean;
    enableExecuteFunctionBlock: boolean;
    error: string;
    emailList: string[];
    mode: string = "ace/mode/json";
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    comparisons = EventComparisons;
    compKeys = Object.getOwnPropertyNames(this.comparisons);
    dataTypes = [EventThresholdDataType.Number, EventThresholdDataType.String, EventThresholdDataType.Boolean, EventThresholdDataType.Date];
    accounts: Account[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EventConfigurationEditDialog>, public httpClient: EventsConfigService, private accountSearch: AESAccountService) {
        var inst = data.instance;
        if (inst != undefined) {
            this.instance = { ...inst };
            this.editType = "Edit";
            for(let condition of this.instance.conditions){
                if(condition.overrideMsg == undefined){
                    condition.overrideMsg = {
                        msg : this.getDefaultMsg(condition)
                    };
                }
                if(condition.dataType == EventThresholdDataType.Boolean){
                    condition.threshold = "" + condition.threshold;
                }
            }
        }
        else {
            this.instance = {
                "state": true,
                "conditions": [{
                    "overrideMsg" : {}
                }]
            };
        }
        if (this.instance.emailList) {
            this.emailList = this.instance.emailList.split(",");
        }
        else {
            this.emailList = [];
        }
        this.enableExecuteFunctionBlock = this.instance.executeFunctionBlock != undefined && this.instance.executeFunctionBlock != "";
        if(this.enableExecuteFunctionBlock){
            this.instance.executeFunctionBlockStr = JSON.stringify(this.instance.executeFunctionBlock, null, 4);
        }
        else{
            this.instance.executeFunctionBlockStr = "{}";
        }
        if(Utils.isAdminUser()){
            var me = this;
            this.inProgress = true;
            accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
            .subscribe(data => {
                me.accounts = data.data;
                if(me.accounts == undefined || me.accounts.length == 0){
                    me.error = "Unable to load accounts, please try again later or contact an administrator.";
                }
                else{
                    me.inProgress = false;
                }
            }, (err) => {
                me.error = "Unable to load accounts, please try again later or contact an administrator.";
            });
        }
    }

    isAdmin(){
        return Utils.isAdminUser();
    }

    toggleExecuteFunctionBlock(){
        this.instance.executeFunctionBlocktr = "{}";
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        var v = (value || '').trim();
        if (v && this.emailList.indexOf(v) < 0) {
            this.emailList.push(v);
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(email: string): void {
        const index = this.emailList.indexOf(email);

        if (index >= 0) {
            this.emailList.splice(index, 1);
        }
    }

    removeOption(index) {
        this.instance.conditions.splice(index, 1);
    }

    addOption(){
        this.instance.conditions.push({
            "overrideMsg" : {}
        });
    }

    dataTypeChange(condition)
    {
        if(condition.comparison){
            var comp = this.comparisons[condition.comparison];
            if(comp.allowedTypes != undefined && comp.allowedTypes.indexOf(condition.dataType) < 0){
                condition.comparison = undefined;
            }
        }
        condition.threshold = undefined;
        if(condition.overrideMsg == undefined){
            condition.overrideMsg = {};
        }
        condition.overrideMsg.msg = this.getDefaultMsg(condition);
        if(condition.dataType == EventThresholdDataType.Boolean){
            condition.threshold = "false";
        }
        else if(condition.dataType == EventThresholdDataType.Date){
            condition.relativeDate = false;
            condition.threshold = new Date();
        }
    }

    fieldChange(condition)
    {
        if(condition.overrideMsg == undefined){
            condition.overrideMsg = {};
        }
        condition.overrideMsg.msg = this.getDefaultMsg(condition);
    }

    isDisabledComp(condition){
        if(condition.comparison){
            var comp = this.comparisons[condition.comparison];
            return comp.noInput;
        }
        return false;
    }

    handleRelativeDate(relative, condition){
        if(relative){
            condition.relativeDateScope = "minute";
        }
    }

    getDefaultMsg(condition){
        if(condition.comparison == undefined){
            return "";
        }
        var comp = condition.comparison.toLowerCase();
        if(comp == "exists"){
            return condition.field + " contains a value: [[value]]";
        }
        else if(comp == "not exists"){
            return  condition.field + " does not have a value";
        }
        else if(comp == "<" || comp == ">" || comp == ">=" || comp == "<=" || comp == "=" || comp == "!="){
            if(comp == "<"){
                return  condition.field + " was less than the given threshold [[threshold]]. Value: [[value]]";
            }
            else if(comp == ">"){
                return condition.field + " was greater than the given threshold [[threshold]]. Value: [[value]]";
            }
            else if(comp == "<="){
                return condition.field + " was less than or equal to the given threshold [[threshold]]. Value: [[value]]";
            }
            else if(comp == ">="){
                return condition.field + " was greater than or equal to the given threshold [[threshold]]. Value: [[value]]";
            }
            else if(comp == "="){
                return condition.field + " was equal to the given threshold [[threshold]]. Value: [[value]]";
            }
            else if(comp == "!="){
                return condition.field + " was not equal to the given threshold [[threshold]]. Value: [[value]]";
            }
        }
        else if((comp == "~=" || comp == "!~=") && condition.dataType == EventThresholdDataType.String){
            if(comp == "~="){
                return condition.field + " contains the given string [[threshold]]. Value: [[value]]";
            }
            else if(comp == "!~="){
                return condition.field + " does not contains the given string [[threshold]]. Value: [[value]]";
            }
        }
    }

    save() {
        var obs;
        this.error = "";
        if (this.instance.id == undefined || this.instance.id == '') {
            this.error = "A ID is required";
            return;
        }
        var me = this;
        for(let comp of this.instance.conditions){
            if(comp.field == undefined){
                this.error = "A condition is missing the field to be compared with";
                return;
            }

            if(comp.dataType == undefined){
                this.error = "A condition is missing a data type"
                return;
            }

            if(comp.comparison == undefined){
                this.error = "A condition is missing a comparison operation"
                return;
            }
            if(comp.dataType == EventThresholdDataType.Boolean){
                if(typeof(comp.threshold) == "string"){
                    comp.threshold = comp.threshold == "true"
                }
                else if(comp.threshold != undefined){
                    comp.threshold = comp.threshold;
                }
                else{
                    comp.threshold = false;
                }
            }
            if(!this.isDisabledComp(comp) && comp.threshold == undefined){
                this.error = "A condition is missing a threshold value";
                return;
            }
        }
        if(this.enableExecuteFunctionBlock){
            if(this.instance.executeFunctionBlockStr != undefined && this.instance.executeFunctionBlockStr != "" && this.instance.executeFunctionBlockStr != "{}"){
                var js = JSON.parse(this.instance.executeFunctionBlockStr);
                if(js.ID == undefined || js.ID == ""){
                    this.error = "Trigger Function Block requires an ID";
                    return;
                }
    
                this.instance.executeFunctionBlock = js;
            }
            else{
                this.error = "Trigger Function Block configuration is required";
                return;
            }
        }
        else{
            this.instance.executeFunctionBlock = null;
        }


        this.instance.emailList = this.emailList.join(",");

        if (this.editType == "Create") {
            obs = this.httpClient.create(this.instance);
        }
        else {
            obs = this.httpClient.edit(this.instance);
        }
        this.inProgress = true;
        obs.subscribe(resp => {
            this.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
            console.log(err);
            me.error = err.error.message;
            if (me.error == "Internal Server Error") {
                me.error = "Server was unable to process your request";
            }
            me.inProgress = false;
        });
    }
}
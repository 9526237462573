import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface Codec {
    id: number;
    name: string;
    desciption: string;
    codecCode: string;
    lastUpdated: Date;
    created: Date;
    downlinkCode: boolean;
    acctId: number;
}
@Injectable()
export class AESCodecService implements DataTableSearchService<Codec>, CRUDService<Codec>{

    constructor(private http: HttpClient) { }

    create(object: any): Observable<Message> {
        const href = 'devices/codecs';
        var params = new HttpParams();
        var requestUrl = `${href}`

        return this.http.post<Message>(requestUrl, object, {
            params : params
        });
    }
    edit(object: any): Observable<Message> {
        const href = 'devices/codecs';
        var params = new HttpParams();
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object, {
            params : params
        });
    }
    get(id): Observable<Codec> {
        const href = 'devices/codecs';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id.toString());
        }

        return this.http.get<Codec>(requestUrl, {
            params : params
        });
    }

    delete(object: Codec): Observable<Message> {
        throw new Error("Not Implemented");
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any): Observable<SenetData<Codec>> {
        const href = 'devices/codecs';
        var offset = page * limit
        var requestUrl = `${href}`;

        var params = new HttpParams();

        if(sort != undefined && sort != ""){
            params = params.set("sort", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortDir", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }

        if(customFilter && customFilter.acctId){
            params = params.set("acctId", customFilter.acctId);
        }

        if(customFilter && customFilter.fields && customFilter.fields.length > 0){
            params = params.set("fields", customFilter.fields.join(","));
        }

        return this.http.get<SenetData<Codec>>(requestUrl, {
            params : params
        });
    }
}
import { Component, Input, OnInit, Host } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DetailsNavView } from "aes-common";
import { Device } from "../../../../services/AESDevicesService";

@Component({
    selector: 'device-events-panel',
    templateUrl: 'devicedetails_events.html',
    styleUrls: ['./devicedetails_events.scss']
})
export class DeviceDetailsEventsPanel implements OnInit {

    @Input()
    public device: Device;

    parent: DetailsNavView;

    constructor(public http: HttpClient,@Host() parent: DetailsNavView){
        this.parent = parent;
    }

    ngOnInit(){
    }
}

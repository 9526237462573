import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ColumnConfig } from "../../columncfg";

@Component({
    selector: 'tablesettings',
    templateUrl: 'tablesettings.html',
    styleUrls: ['./tablesettings.css']
})
export class TableSettingsDialog{
    
    viewCols: any[];

    constructor(@Inject(MAT_DIALOG_DATA) public data:any, public dialogRef: MatDialogRef<TableSettingsDialog>){
        var cols : ColumnConfig[] = data.columns;
        var vCols : string[] = data.viewColumns;
        this.viewCols = [];
        for (var key in cols){
            var val = cols[key];
            var isChecked = false;
            for(var k2 in vCols){
                var v2 = vCols[k2];
                if(val.field == v2){
                    isChecked = true;
                    break;
                }
            }
            this.viewCols.push({"header": val.header, "checked": isChecked, "field": val.field});
        }
    }

    resetSettings(){
        this.dialogRef.close("RESET");
    }

    saveSettings(){
        this.dialogRef.close(this.viewCols)
    }
}
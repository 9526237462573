import { Component, Input, Output, EventEmitter } from "@angular/core";
import {GoogleMap} from '@angular/google-maps';

@Component({
    selector: 'search-map-control',
    templateUrl: 'search-map-control.html',
    styleUrls: ['./search-map-control.scss']
})
export class SearchMapControl {

    showInput : boolean;
    @Output()
    search : EventEmitter<any> = new EventEmitter();

    @Input() map : GoogleMap;

    constructor() {
    }

    click(){

    }
    
    ngOnInit() {
        this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById("Map-Search"));
    }
}

import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface SMTPConnection {
    connectionId: number;
    host: string;
    username: string;
    passwordEnc: string;
    sender: string;
    port: number;
    created: number;
    updated: number;
    acctId: number;
}
@Injectable()
export class AESSMTPConnectionService implements DataTableSearchService<SMTPConnection>, CRUDService<SMTPConnection>{

    constructor(private http: HttpClient) { }

    create(object: SMTPConnection): Observable<Message> {
        const href = 'connections/smtp';
        var params = new HttpParams();
        var requestUrl = `${href}`

        return this.http.post<Message>(requestUrl, object, {
            params : params
        });
    }
    edit(object: SMTPConnection): Observable<Message> {
        const href = 'connections/smtp';
        var params = new HttpParams();
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object, {
            params : params
        });
    }
    delete(object: SMTPConnection): Observable<Message> {
        const href = 'connections/smtp';
        var params = new HttpParams().set("connectionId", object.connectionId.toString());
        var requestUrl = `${href}`

        return this.http.delete<Message>(requestUrl, {
            params : params
        });
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any): Observable<SenetData<SMTPConnection>> {
        const href = 'connections/smtp';
        var offset = page * limit
        var requestUrl = `${href}`;

        var params = new HttpParams();
        var requestUrl = `${href}`;
        if(sort != undefined && sort != ""){
            params = params.set("sort", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortDir", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }

        if(customFilter && customFilter.acctId != undefined){
            params = params.set("acctId", customFilter.acctId);
        }

        return this.http.get<SenetData<SMTPConnection>>(requestUrl, {
            params : params
        });
    }
}
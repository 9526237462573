<div class="container" fxLayout="column" [fxLayoutGap]="gapSize" [ngClass]="{'hidden': !canShow}">
    <div align="end" class="edit-buttons">
        <button mat-button class="back-button" (click)="handleBack($event)">&lt;&nbsp;Back</button>
        <button class="edit-view-buttons" [disabled]="(inProgress || saveBlocked || !isDirty())" mat-button (click)="save()" cdkFocusInitial>Save</button>
        <button class="edit-view-buttons" mat-button (click)="cancel()">Cancel</button>
    </div>    
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">       
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100" [isEditDetailsCard]="true">            
            <div card-title>
                {{editType}} Device Profile{{titleSuffix}}                
            </div>            
            <div class="senet-card-body" card-body>
                <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
                <mat-tab-group #tabGroup (selectedTabChange)="selectedTabChanged($event)">  
                    <mat-tab label="Information">
                        <form fxLayout="column" fxLayoutGap="10" #infoForm="ngForm" class="edit-tab-form info-tab-form">
                            <mat-form-field *ngIf="editType == 'Edit'">
                                <input [disabled]="true" matInput placeholder="ID" required [(ngModel)]="instance.id" name="deviceprofile-ID-field">
                            </mat-form-field>
                            <mat-form-field>
                                <input [disabled]="inProgress || (isShared && !isAdmin())" matInput placeholder="Name" required [(ngModel)]="instance.name" name="deviceprofile-name-field">
                            </mat-form-field>
                            <mat-form-field>
                                <input [disabled]="inProgress || (isShared && !isAdmin())" matInput placeholder="Description" [(ngModel)]="instance.description" name="deviceprofile-description-field">
                            </mat-form-field>
                            <mat-form-field *ngIf="isAdmin()">
                                <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Account" [(ngModel)]="instance.acctId" (ngModelChange)="handleAccountChange($event)" name="deviceprofile-acctId-field">
                                    <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                                        {{account.name}}<span *ngIf="account.acctId != -1"> ({{account.acctId}})</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-select [disabled]="inProgress || (isShared && !isAdmin())" placeholder="Uplink Pipeline" [(ngModel)]="instance.uplinkPipelineId" name="deviceprofile-edit-uplinkPipeline-field">
                                    <mat-option *ngFor="let pipeline of pipelines" [value]="pipeline.id">
                                        {{pipeline.id}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-select [disabled]="inProgress || (isShared && !isAdmin())" placeholder="Codec" [(ngModel)]="instance.codecId" name="deviceprofile-edit-codec-field">
                                    <mat-option *ngFor="let codec of codecs" [value]="codec.id">
                                        {{codec.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-checkbox [disabled]="inProgress || (isShared && !isAdmin())" placeholder="Periodic Pipeline Enabled" [(ngModel)]="instance.periodicPipelineEnabled"
                                                name="deviceprofile-config-edit-periodic-pipeline-enabled">Periodic Pipeline Enabled</mat-checkbox>
                            <mat-form-field *ngIf="instance.periodicPipelineEnabled">
                                <input [disabled]="inProgress || !instance.periodicPipelineEnabled || (isShared && !isAdmin())" matInput placeholder="Periodic Pipeline Interval" type="number" [(ngModel)]="instance.periodicPipelinePeriod" name="deviceprofile-periodicPipelinePeriod-field">
                            </mat-form-field>
                            <mat-form-field *ngIf="instance.periodicPipelineEnabled">
                                <mat-select [disabled]="inProgress || !instance.periodicPipelineEnabled || (isShared && !isAdmin())" placeholder="Periodic Pipeline" [(ngModel)]="instance.periodicPipelineId" name="deviceprofile-edit-periodicPipeline-field">
                                    <mat-option *ngFor="let pipeline of pipelines" [value]="pipeline.id">
                                        {{pipeline.id}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </form>
                    </mat-tab>
                    <mat-tab label="Default Device Data">
                        <form fxLayout="column" fxLayoutGap="10" #functionBlockForm="ngForm" class="edit-tab-form">
                            <senet-ace-editor #deviceProfileDefDataEditor (codeChange)="onCodeChanged()" [isForDialog]="false" [(code)]="instance.defaultDeviceDataStr" [mode]="mode"></senet-ace-editor>
                        </form>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </senetcard>
    </div>
</div>

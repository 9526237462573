import { HostListener, Component, Input, OnInit, OnChanges, ViewChild, AfterViewInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ColumnConfig, DataTable, DataTableOptions } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { Device } from "../../../services/AESDevicesService";
import {DeviceEventsService, DeviceEvent} from "../../../services/AESDeviceEventsService";
import { DevDataDialog } from "../../../dialogs/devdatadialog/devdatadialog";
import { ViewRegistration } from "aes-common";
import { Utils } from "../../../services/Utils";
import { MatDialog } from "@angular/material/dialog";
import { T } from "@angular/cdk/keycodes";
import { isTemplateExpression } from "typescript";

@Component({
    template: "<a [routerLink]=\"['/devices/details',element.devEui]\">{{element.devEui}}</a>"
})
export class EventDeviceLinkRow{
    @Input()
    element: DeviceEvent;

    @Input()
    col: ColumnConfig;
}

@Component({
    template: '<a (click)="showDeviceData()">{{element.devType}}</a>'
})
export class DevDataDialogCell{
    @Input()
    element: DeviceEvent;

    @Input()
    col: ColumnConfig;

    dialogRef: any;

    @HostListener("click") showDeviceData(){
        console.log("Clicked showDeviceData");
    
        if(!this.dialogRef)
            this.dialogRef = this.dialog.open(DevDataDialog, {
            width: '650px',
            data: {element: this.element}
          });
      
          this.dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.dialogRef = undefined;
          });        
    } 
    constructor(public dialog: MatDialog){
    }
}

@Component({
    selector: 'device-events',
    templateUrl: 'events.html',
    styleUrls: ['./events.scss']
})
export class AESDeviceEvents implements OnInit, OnChanges, AfterViewInit {
    static view = new ViewRegistration("Device Events", "/devices/events", "events", [], function(){
        return !Utils.isAdminUser();
    });

    dtOptions:DataTableOptions  = {pageSize : 100, showSelectionColumn: false, columnFiltering: true, filtering: false };

    @Input()
    public device: Device;

    gapSize: string = "10px";

    tableHeight: number;

    
    @ViewChild(DataTable, { static: false })dt : DataTable;

    columnCfgs: ColumnConfig[] = [{
        header: "Time",
        field: "txtime",
        defaultSort: true,
        dataType: 'date',
        filterable: true,        
        renderContent: function(col: ColumnConfig, element: DeviceEvent){
            var date = new Date(element.txtime);
            return date.toLocaleString();
        }
    },{
        header: "Device",
        field: "devEui",
        filterable: true,
        contentComponent: EventDeviceLinkRow
    },{
        header: "Type",
        field: "devType",
        filterable: true,
        contentComponent: DevDataDialogCell
    },{
        header: "Owner",
        field: "owner",
        filterable: true,
        visible: false,
        renderContent: Utils.renderContent
    },{
        header: "Location",
        field: "deviceLocation",
        visible: false,
        renderContent: Utils.renderContent
    },{
        header: "Device Class",
        field: "deviceClass",
        filterable: true,
        visible: false,
        renderContent: Utils.renderContent
    }];

    buttons: ButtonConfig[] = [];

    constructor(private route: ActivatedRoute, public dialog: MatDialog, public httpClient: DeviceEventsService){
        let devEui = this.route.snapshot.params["devEui"];
        let euiCol = this.columnCfgs.find(item => item.field === 'devEui');
        if(euiCol && devEui){
            euiCol.contentComponent = undefined;
        }
    }

    ngOnInit(){
        if(this.device){
            this.tableHeight = 190;
        }
        if(this.device != undefined){
            this.httpClient.setDeviceEui(this.device.devEui);
        }
        else{
            this.httpClient.setDeviceEui(undefined);
        }
    }

    ngOnChanges() {
        if(this.device != undefined){
            this.httpClient.setDeviceEui(this.device.devEui);
        }
        else{
            this.httpClient.setDeviceEui(undefined);
        }
        this.dtOptions = {pageSize : 100, showSelectionColumn: false, columnFiltering: true, filtering: false };
    }

    ngAfterViewInit(){
        if(this.device != undefined){
            this.httpClient.setDeviceEui(this.device.devEui);
        }
        else{
            this.httpClient.setDeviceEui(undefined);
        }
        this.dtOptions = {pageSize : 100, showSelectionColumn: false, columnFiltering: true, filtering: false };
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AESSQLConnectionService, SQLConnection } from "../../services/AESSQLConnectionService";
import { AESUserAPIKeysService } from "../../services/AESUserAPIKeysService";
import { AESUserService, User } from "../../services/AESUserService";

@Component({
    selector: 'userapikeydialog',
    templateUrl: 'userapikeydialog.html',
    styleUrls: ['./userapikeydialog.css']
})
export class UserAPIKeyEditDialog {

    instance: any;
    editType: string = "Create";

    inProgress: boolean;
    users: User[];
    connections : SQLConnection[];
    connectionMap : any;
    error: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UserAPIKeyEditDialog>, private userService:AESUserAPIKeysService, private userSearch: AESUserService, private connectionSearch: AESSQLConnectionService) {
        var inst = data.instance;
        var me = this;
        if (inst != undefined) {
            this.instance = { ...inst };
            this.editType = "Edit";
            this.inProgress = true;
            this.instance.connectionId =  parseInt(this.instance.connectionId);
            userSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
            .subscribe(data => {
                me.users = data.data;
                if(me.users == undefined || me.users.length == 0){
                    me.error = "Unable to load users, please try again later or contact an administrator.";
                }
                else{
                    connectionSearch.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : me.instance.acctId})
                    .subscribe(data => {
                        me.connections = data.data;
                        me.connectionMap = {};
                        if(me.connections == undefined || me.connections.length == 0){
                            me.error = "Unable to load connections, please try again later or contact an administrator.";
                        }
                        else{
                            for(let connection of me.connections){
                                me.connectionMap["" + connection.connectionId] = connection;
                            }
                            if(me.instance.connectionId == undefined){
                                me.instance.connectionId = data.data[0].connectionId;
                            }
                            me.inProgress = false;
                        }
                    }, (err) => {
                        me.error = "Unable to load connections, please try again later or contact an administrator.";
                    });
                }
            }, (err) => {
                me.error = "Unable to load users, please try again later or contact an administrator.";
            });

        }
        else{
            this.instance = {};
            this.inProgress = true;
            userSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
            .subscribe(data => {
                me.users = data.data;
                if(me.users == undefined || me.users.length == 0){
                    me.error = "Unable to load users, please try again later or contact an administrator.";
                }
                else{
                    if(me.instance.username == undefined){
                        me.instance.username = data.data[0].username;
                    }
                    me.inProgress = false;
                }
            }, (err) => {
            me.error = "Unable to load users, please try again later or contact an administrator.";
            });
        }

    }

    save() {
        var obs;
        this.error = "";
        if(this.instance.username == undefined || this.instance.username == ''){
            this.error = "A username is required";
            return;
        }
        /*if((this.instance.password == undefined || this.instance.password == '') && this.editType == "Create"){
            this.error = "A password is required";
            return;
        }*/
        var me = this;

        if(this.editType == "Create"){
            obs = this.userService.create(this.instance);
        }
        else{
            obs = this.userService.edit(this.instance);
        }

        this.inProgress = true;
        obs.subscribe(resp => {
            this.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
           console.log(err);
           me.error = err.error.message;
           if(me.error == "Internal Server Error"){
               me.error = "Server was unable to process your request";
           }
           me.inProgress = false;
        });
    }
}
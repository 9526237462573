import { Component, Input, HostListener, ViewChild } from "@angular/core";
import { ViewRegistration } from "aes-common";
import { ColumnConfig, DataTableOptions, DataTable } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { AESApplicationService, Application } from "../../services/AESApplicationService";
import { ApplicationEditDialog } from "../../dialogs/applicationsdialog/applicationsdialog";
import { Utils } from "../../services/Utils";

@Component({
    template: '<a (click)="showEditor($event, element)">{{element.appEui}}</a>'
})
export class ShowApplicationEditorCell{
    @Input()
    element: Application;

    @Input()
    col: ColumnConfig;

    dialogRef: any;

    @HostListener("click")showEditor(evt, element){
        evt.stopPropagation();
        if(!this.dialogRef){
            //this.table.buttons[1].click(this.table.dt, [element]);
            this.dialogRef = this.dialog.open(ApplicationEditDialog, {
                width: '600px',
                data: {
                    "instance": element
                }
            });
    
            this.dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    this.table.dt.refresh(this.table.dt, [element]);
                }
                this.dialogRef = undefined;
            });            
            console.log("Called click handler");
        }

    }
    constructor(public dialog: MatDialog, public table: Applications){
    }    
}

@Component({
    selector: 'applications',
    templateUrl: 'applications.html',
    styleUrls: ['./applications.scss']
})
export class Applications {
    static view = new ViewRegistration("Applications", "/configurations/applications", "device_hub", [], function(){
        return !Utils.isAdminUser();
    });

    dtOptions:DataTableOptions  = {pageSize : 100, showSelectionColumn: false, multiSelect: false};
    @ViewChild(DataTable, { static: true })
    dt : DataTable    
    columnCfgs: ColumnConfig[] = [{
        header: "App",
        field: "appEui",
        defaultSort: true,
        contentComponent: ShowApplicationEditorCell
    },{
        header: "Description",
        field: "description",
    },{
        header: "Mode",
        field: "mode"
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: AESApplicationService) {
        var me = this;
        this.buttons = [ButtonConfig.createAddButton("Create Application", function(dt, selected){
            const dialogRef = me.dialog.open(ApplicationEditDialog, {
                width: '600px',
                data: {}
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }), ButtonConfig.createEditButton("Edit Application", function(dt, selected){
            const dialogRef = me.dialog.open(ApplicationEditDialog, {
                width: '600px',
                data: {
                    "instance": selected[0]
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        })];
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

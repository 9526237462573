export interface ColumnConfig {
    header: string;
    title?: string;
    visible?: boolean;
    filterable?:boolean;
    field: string;
    defaultSort?: boolean;
    sortDisabled?: boolean;
    renderContent?(col:ColumnConfig, element:any);
    contentComponent?: any;
    dataType?:string;
    listValues?:string[];
}
export enum ColumnDataType {
    STRING = "string",
    NUMBER = "number",
    DATE = "date",
    BOOLEAN = "boolean",
    LIST = "list"
}
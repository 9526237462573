<h2 mat-dialog-title>{{editType}} Connection</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm">
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="URL" required [(ngModel)]="instance.url" name="connection-edit-host-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="User" [(ngModel)]="instance.username" autocomplete="off" name="connection-edit-user-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" type="password" matInput placeholder="Password" autocomplete="off" [(ngModel)]="instance.password" name="connection-edit-password-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="Header 1" [(ngModel)]="instance.header1" name="connection-edit-header1-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" type="password" matInput placeholder="Value 1" [(ngModel)]="instance.value1" name="connection-edit-value1-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="Header 2" [(ngModel)]="instance.header2" name="connection-edit-header2-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" type="password" matInput placeholder="Value 2" [(ngModel)]="instance.value2" name="connection-edit-value2-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="Downlink Path" [(ngModel)]="instance.downlinkPath" name="connection-edit-downlinkPath-field">
        </mat-form-field>
        <mat-form-field *ngIf="isAdminUser()">
            <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Account" [(ngModel)]="instance.acctId" name="connection-edit-acctId-field">
                <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                    {{account.name}} ({{account.acctId}})
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select [disabled]="inProgress" placeholder="HTTP Methods" [(ngModel)]="instance.method" name="connection-edit-http-method-field">
                <mat-option *ngFor="let method of methods" [value]="method">
                    {{method}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
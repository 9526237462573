<div class="login-container" fxLayout="row" fxLayout.xs="column" *ngIf="!getSessionInProgress">
    <div class="left-half">

    </div>
    <div class="login-form-container" fxLayoutAlign="space-around center">
        <div fxLayout="column" fxLayoutGap="20px" >
            <div>
                <img class="logo" src="../../assets/img/logo.png" />
            </div>
            <span *ngIf="error" style="color: red;">{{error}}</span>
            <form class="login-form">
                <table class="" cellspacing="0">
                    <tr *ngIf="useAPIKey">
                        <td>
                            <mat-form-field class="">
                                <input matInput placeholder="API Key" [(ngModel)]="apiKey" name="login_apikey">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr *ngIf="!useAPIKey">
                        <td>
                            <mat-form-field class="">
                                <input matInput placeholder="Username"  [disabled]="inProgress" required [(ngModel)]="username" name="login_username">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr *ngIf="!useAPIKey">
                        <td>
                            <mat-form-field class="">
                                <input matInput placeholder="Password"  [disabled]="inProgress" required type="password" [(ngModel)]="password" name="login_password">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td><button mat-raised-button color="primary" [disabled]="inProgress || ((password == '' || username == '' || username == undefined || password == undefined) && !useAPIKey) || (apiKey == undefined && useAPIKey)" (click)="formSubmit($event)">Login</button></td>
                    </tr>
                </table>
            </form>
        </div>
    </div>
</div>
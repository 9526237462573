import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {FormControl} from '@angular/forms';
import { Utils } from "../../services/Utils";
import { Account, AESAccountService } from "../../services/AESAccountService";
import { AESSystemSettingsService } from "../../services/AESSystemSettingsService";
import { AESUserService, User } from "../../services/AESUserService";
import { GlobalScope } from "aes-common";
import { HighlightSpanKind } from "typescript";

@Component({
    selector: 'systemsettingsdialog',
    templateUrl: 'systemsettingsdialog.html',
    styleUrls: ['./systemsettingsdialog.css']
})
export class SystemSettingsEditDialog {

    instance: any;
    editType: string = "Create";

    inProgress: boolean;
    error: string;

    accounts: Account[];
    users : User[];
    views : String[];
    acctIdSet : boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SystemSettingsEditDialog>, private sysSettingsService:AESSystemSettingsService, private accountSearch: AESAccountService, private userSearch : AESUserService) {
        var inst = data.instance;
        this.views = GlobalScope.getRegisteredViews().sort();
        if (inst != undefined) {
            this.instance = { ...inst };
            this.editType = "Edit";
            this.acctIdSet = this.instance.acctId != undefined;
        }
        else{
            this.instance = {};
        }
        if(this.instance.id == 'SEN_HIDDEN_VIEWS'){
            this.instance.value = this.instance.value ? this.instance.value.split(',') : [];
        }
        
        this.instance.valueStr = this.instance.value;
        if(typeof this.instance.value === 'object' && this.instance.value !== null){
            this.instance.valueStr = JSON.stringify(this.instance.value, undefined, 4);
        }
        var me = this;
        accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
        .subscribe(data => {
            me.accounts = data.data;
            me.accounts.splice(0, 0, {"name" : "None", "acctId" : -2, "connectionType" : "", "creationDate" : null, "desciption" : "", "defaultConnectionId": -1});
            if(me.accounts == undefined || me.accounts.length == 0){
                me.error = "Unable to load accounts, please try again later or contact an administrator.";
            }
            else{
                if(me.instance.acctId == undefined){
                    me.instance.acctId = data.data[0].acctId;
                }
                if(me.instance.acctId != -2){
                    userSearch.search(undefined, undefined, undefined, undefined, undefined, {acctId : me.instance.acctId})
                    .subscribe(data => {
                        me.users = data.data;
                        me.users.splice(0, 0, {"username" : "None", acctId : -1, connectionType : "", creationDate : undefined, defaultConnectionId : -1, email : "", passwordEnc : "", role : ""})
                        if(me.users == undefined || me.users.length == 0){
                            me.error = "Unable to load users, please try again later or contact an administrator.";
                        }
                        else{
                            if(me.instance.username == undefined){
                                me.instance.username = data.data[0].username;
                            }
                            me.inProgress = false;
                        }
                    }, (err) => {
                    me.error = "Unable to load users, please try again later or contact an administrator.";
                    });
                }
                else{
                    me.inProgress = false;
                }
            }
        }, (err) => {
        me.error = "Unable to load accounts, please try again later or contact an administrator.";
        });
        this.processAccountUpdate = this.processAccountUpdate.bind(this);
    }

    isAdminUser(){
        return Utils.isAdminUser();
    }

    processAccountUpdate(acctId){
        var me = this;
        me.error = "";
        if(acctId != -2){
            me.inProgress = true;
            this.userSearch.search(undefined, undefined, undefined, undefined, undefined, {acctId : me.instance.acctId})
            .subscribe(data => {
                me.users = data.data;
                me.users.splice(0, 0, {"username" : "None", acctId : -1, connectionType : "", creationDate : undefined, defaultConnectionId : -1, email : "", passwordEnc : "", role : ""})
                if(me.users == undefined || me.users.length == 0){
                    me.error = "Unable to load users, please try again later or contact an administrator.";
                }
                else{
                    me.instance.username = data.data[0].username;
                    me.inProgress = false;
                }
            }, (err) => {
            me.error = "Unable to load users, please try again later or contact an administrator.";
            });
        }
    }

    save() {
        var obs;
        this.error = "";
        var me = this;
        if(this.instance.id == undefined || this.instance.id == "")
        {
            this.error = "ID is requred";
            return;
        }
        if(this.instance.acctId == -2){
            this.instance.acctId = null;
        }
        if(this.instance.username == "None"){
            this.instance.username = null;
        }

        if(this.instance.valueStr != undefined){
            try{
                if(this.instance.id == Utils.SEN_HIDDEN_VIEWS_KEY){
                    this.instance.value = this.instance.value.toString();
                }else{
                    this.instance.value = JSON.parse(this.instance.valueStr);
                }
                
            }
            catch(e){
                this.instance.value = this.instance.valueStr;
            }
        }
        if(this.editType == "Create"){
            obs = this.sysSettingsService.create(this.instance);
        }
        else{
            obs = this.sysSettingsService.edit(this.instance);
        }
        this.instance.connectionType = "HTTP";
        this.inProgress = true;
        obs.subscribe(resp => {
            this.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
           console.log(err);
           me.error = err.error.message;
           if(me.error == "Internal Server Error"){
               me.error = "Server was unable to process your request";
           }
           me.inProgress = false;
        });
    }
}
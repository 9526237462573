import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AESAccountService } from "../../services/AESAccountService";
import { AESDeviceProfileService, DeviceProfile } from "../../services/AESDeviceProfileService";
import { AESSMTPConnectionService, SMTPConnection } from "../../services/AESSMTPConnectionService";
import { AESSQLConnectionService, SQLConnection } from "../../services/AESSQLConnectionService";

@Component({
    selector: 'accountdialog',
    templateUrl: 'accountdialog.html',
    styleUrls: ['./accountdialog.css']
})
export class AccountEditDialog {

    instance: any;
    connection: any;
    connectionMap : any;
    editType: string = "Create";

    inProgress: boolean;
    connections: SQLConnection[];
    profiles: DeviceProfile[];
    smtpConnections: SMTPConnection[];
    error: string;

    mode: string = "ace/mode/json";
    configFullScreen: boolean = false;
    actionsFullScreen: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AccountEditDialog>, public connectionSearch:AESSQLConnectionService, private accountService :AESAccountService, public profileServe : AESDeviceProfileService, public smtpServe : AESSMTPConnectionService) {
        var inst = data.instance;
        var me = this;
        if (inst != undefined) {
            this.instance = { ...inst };
            this.connection = {};
            this.editType = "Edit";
            this.inProgress = true;
            this.instance.defaultConnectionId =  parseInt(this.instance.defaultConnectionId);
            connectionSearch.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : me.instance.acctId})
            .subscribe(data => {
                me.connections = data.data;
                me.connectionMap = {};
                if(me.connections == undefined || me.connections.length == 0){
                    me.error = "Unable to load connections, please try again later or contact an administrator.";
                }
                else{
                    for(let connection of me.connections){
                        if(connection.connectionId == me.instance.defaultConnectionId){
                            var tempcon = { ...connection};
                            
                            for(var key in tempcon){
                                me.connection[key] = tempcon[key];
                            }
                        }
                        me.connectionMap["" + connection.connectionId] = connection;
                    }
                    if(me.instance.connectionId == undefined){
                        me.instance.connectionId = data.data[0].connectionId;
                    }
                    profileServe.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : me.instance.acctId})
                    .subscribe(data => {
                        me.profiles = data.data;
                        if(me.profiles == undefined || me.profiles.length == 0){
                            me.error = "Unable to load profiles, please try again later or contact an administrator.";
                        }
                        else{
                            smtpServe.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : me.instance.acctId})
                            .subscribe(data => {
                                me.smtpConnections = data.data;
                                if(me.smtpConnections == undefined || me.smtpConnections.length == 0){
                                    me.error = "Unable to load SMTP Connections, please try again later or contact an administrator.";
                                }
                                else{
                                    me.inProgress = false;
                                }
                            }, (err) => {
                            me.error = "Unable to load SMTP Connections, please try again later or contact an administrator.";
                            });
                        }
                    }, (err) => {
                    me.error = "Unable to load profiles, please try again later or contact an administrator.";
                    });
                }
            }, (err) => {
            me.error = "Unable to load connections, please try again later or contact an administrator.";
            });
        }
        else{
            this.instance = {};
            this.connection = {};
            this.inProgress = true;
            smtpServe.search(undefined, undefined, undefined, undefined, undefined, {})
            .subscribe(data => {
                me.smtpConnections = data.data;
                if(me.smtpConnections == undefined || me.smtpConnections.length == 0){
                    me.error = "Unable to load SMTP Connections, please try again later or contact an administrator.";
                }
                else{
                    me.instance.smtpConnectionId = 0;
                    me.inProgress = false;
                }
            }, (err) => {
            me.error = "Unable to load SMTP Connections, please try again later or contact an administrator.";
            });
        }
        if(this.instance.connectionType == undefined){
            this.instance.connectionType = "SQL";
        }
        this.handleConnectionChange = this.handleConnectionChange.bind(this);
    }

    handleConnectionChange(event){
        var connection = { ...this.connectionMap["" + event]};
        
        for(var key in connection){
            this.connection[key] = connection[key];
        }
    }

    save() {
        var obs;
        this.error = "";
        if(this.instance.acctId == undefined || this.instance.acctId == ''){
            this.error = "An Account ID is required";
            return;
        }
        if(this.instance.name == undefined || this.instance.name == ''){
            this.error = "A name is required";
            return;
        }
        if(this.connection.host == undefined || this.connection.host == ''){
            this.error = "A host is required";
            return;
        }
        if((this.connection.defaultDb == undefined || this.connection.defaultDb == '')){
            this.error = "A Database is required";
            return;
        }

        this.connection.connectionType = "SQL";
        var me = this;
        var request = {
            "account" : this.instance,
            "connection" : this.connection
        }
        if(this.editType == "Create"){
            obs = this.accountService.create(request);
        }
        else{
            obs = this.accountService.edit(request);
        }

        this.inProgress = true;
        obs.subscribe(resp => {
            this.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
           console.log(err);
           me.error = err.error.message;
           if(me.error == "Internal Server Error"){
               me.error = "Server was unable to process your request";
           }
           me.inProgress = false;
        });
    }
}
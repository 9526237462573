<h2 mat-dialog-title>{{editType}} Portal User</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm">
        <mat-form-field>
            <input [disabled]="inProgress || editType == 'Edit'" matInput placeholder="Username" required [(ngModel)]="instance.username" name="user-edit-username-field">
        </mat-form-field>
<!--         <mat-form-field *ngIf="editType != 'Edit'">
            <input [disabled]="inProgress" matInput placeholder="Password" type="password" required [(ngModel)]="instance.password" name="user-edit-password-field">
        </mat-form-field> -->
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="Email" required [(ngModel)]="instance.email" name="user-edit-email-field">
        </mat-form-field>
        <mat-form-field>
            <mat-select [disabled]="inProgress" placeholder="Role" [(ngModel)]="instance.role" name="user-edit-role-field">
                <mat-option *ngFor="let role of roles" [value]="role.value">
                    {{role.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Account" [(ngModel)]="instance.acctId" name="user-edit-acctId-field">
                <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                    {{account.name}} ({{account.acctId}})
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
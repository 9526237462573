<h2 mat-dialog-title>Send Command</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <mat-spinner *ngIf="loading" class="body-loading-spinner"></mat-spinner>
    <div *ngIf="!loading">
        <div *ngIf="!showMessages">
            <form fxLayout="column" fxLayoutGap="10" #commandSelect="ngForm">
                <mat-form-field>
                    <mat-select [disabled]="inProgress" placeholder="Command" [(ngModel)]="selectedCommand" name="dev-command-select" (ngModelChange)="setParamKeys()">
                        <mat-option *ngFor="let source of commands" [value]="source">
                            {{source}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
            <form *ngIf="currentCommand() != undefined" fxLayout="column" fxLayoutGap="10" #commandConfig="ngForm">
                <div *ngIf="currentCommand().description">
                    <label>Description</label>
                    <p>{{currentCommand().description}}</p>
                </div>
                <mat-form-field *ngFor="let key of paramKeys" [ngClass]="{'next-field' : paramKeys.indexOf(key) > 0}">
                    <mat-select *ngIf="currentParams()[key].type == 'boolean'" [disabled]="inProgress" [placeholder]="key" [required]="currentParams()[key].required" [(ngModel)]="commandInstance.parameters[key]"  [name]="'dev-command-' + key">
                        <mat-option [value]="'true'">
                            true
                        </mat-option>
                        <mat-option [value]="'false'">
                            false
                        </mat-option>
                    </mat-select>
                    <input *ngIf="currentParams()[key].type != 'boolean'" [disabled]="inProgress" [type]="getParamType(currentParams()[key])" [required]="currentParams()[key].required" matInput [placeholder]="key" [min]="currentParams()[key].min" [max]="currentParams()[key].max" [(ngModel)]="commandInstance.parameters[key]" [name]="'dev-command-' + key">
                    <mat-hint *ngIf="currentParams()[key].description">{{currentParams()[key].description}}</mat-hint>
                </mat-form-field>
            </form>
        </div>
        <div *ngIf="showMessages">
            <h3>Response</h3>
            <mat-list dense>
                <mat-list-item *ngFor="let message of messages" [innerHTML]="message"></mat-list-item>
            </mat-list>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close *ngIf="!showMessages">Cancel</button>
    <button mat-button mat-dialog-close *ngIf="showMessages">Close</button>
    <button [disabled]="inProgress" mat-button (click)="execute()" *ngIf="!showMessages" cdkFocusInitial>Execute</button>
</mat-dialog-actions>
<mat-sidenav-container [ngClass]="{'sidenav-container': visible, 'sidenav-container-hidden': !visible}" color="primary" autosize>
    <mat-sidenav *ngIf="visible"  mode="side" [opened]="opened" class="senet-sidenav" [style.width.px]="!miniMode ? 200: 60">
        <mat-nav-list  (mouseleave)="currentlyOver = ''">
            <ng-container *ngFor="let viewRegistration of links">
                <div *ngIf="viewRegistration.isViewVisible()">
                    <mat-list-item (click)="navigateToLink(viewRegistration, $event)" [matTooltip]="viewRegistration.title" [matTooltipPosition]="'right'"
                        [matTooltipDisabled]="!miniMode" [ngClass]="{'active': isActive(viewRegistration),'active-main': !subViewActive(viewRegistration) && isActive(viewRegistration)}">
                        <mat-icon *ngIf="miniMode">{{viewRegistration.icon}}</mat-icon>
                        <span *ngIf="!miniMode">
                            {{viewRegistration.title}}
                        </span>
                        <mat-icon class="mini-icon" (click)="currentlyOver = viewRegistration.viewRegistration" *ngIf="viewRegistration.subViews != undefined && viewRegistration.subViews.length > 0">{{viewRegistration.selected? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
                    </mat-list-item>
                    <mat-nav-list [ngClass]="{'subnav-mini': miniMode,'subnav': !miniMode}"  *ngIf="shouldShowChildren(viewRegistration)">
                        <div *ngFor="let subView of viewRegistration.subViews">
                            <mat-list-item (click)="navigateToLink(subView, $event)" [matTooltip]="subView.title" *ngIf="subView.isViewVisible()"
                                [matTooltipPosition]="'right'" [matTooltipDisabled]="!miniMode" [ngClass]="{'full-active': checkFullActive(subView), 'active': isActive(viewRegistration)}">
                                <mat-icon *ngIf="miniMode">{{subView.icon}}</mat-icon>
                                <span *ngIf="!miniMode">
                                    {{subView.title}}
                                </span>
                            </mat-list-item>
                        </div>
                    </mat-nav-list>
                </div>
            </ng-container>
        </mat-nav-list>
        <button mat-icon-button (click)="toggleSize()" class="toggle-button" [ngClass]="miniMode? 'toggled': 'not-toggled'">
            <mat-icon aria-label="Shrink Side" *ngIf="!miniMode">chevron_left</mat-icon>
            <mat-icon aria-label="Grow Side" *ngIf="miniMode">chevron_right</mat-icon>
        </button>
    </mat-sidenav>
    <mat-sidenav-content class="senet-sidenav-content">
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
import { Component, Input, HostListener, ViewChild } from "@angular/core";
import { ViewRegistration } from "aes-common";
import { ColumnConfig, DataTableOptions, DataTable, ColumnDataType } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { AESSMTPConnectionService, SMTPConnection } from "../../../services/AESSMTPConnectionService";
import { SMTPConnectionEditDialog } from "../../../dialogs/smtpconnectiondialog/smtpconnectiondialog";
import { Utils } from "../../../services/Utils";

@Component({
    template: '<a (click)="showEditor($event, element)">{{element.host}}</a>'
})
export class ShowSMTPConnectionEditorCell{
    @Input()
    element: SMTPConnection;

    @Input()
    col: ColumnConfig;

    dialogRef: any;

    @HostListener("click")showEditor(evt, element){
        evt.stopPropagation();
        if(!this.dialogRef){
            //this.table.buttons[1].click(this.table.dt, [element]);
            this.dialogRef = this.dialog.open(SMTPConnectionEditDialog, {
                width: '600px',
                data: {
                    "instance": element
                }
            });
    
            this.dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    this.table.dt.refresh(this.table.dt, [element]);
                }
                this.dialogRef = undefined;
            });            
            console.log("Called click handler");
        }

    }
    constructor(public dialog: MatDialog, public table: AESSMTPConnections){
    }    
}

@Component({
    selector: 'smtpconnections',
    templateUrl: 'smtpconnections.html',
    styleUrls: ['./smtpconnections.scss']
})
export class AESSMTPConnections {
    static view = new ViewRegistration("SMTP", "/connections/smtp", "mail", [], Utils.isAdminUser);
    dtOptions:DataTableOptions  = {pageSize : 100, showSelectionColumn: false, multiSelect: false};
    @ViewChild(DataTable, { static: true })
    dt : DataTable    
    columnCfgs: ColumnConfig[] = [{
        header: "ID",
        field: "connectionId"
    },{
        header: "Host",
        field: "host",
        defaultSort: true,
        contentComponent: ShowSMTPConnectionEditorCell
    },{
        header: "Created",
        field: "created",
        defaultSort: true,
        dataType: ColumnDataType.DATE,
        filterable: true,
        renderContent: function (col: ColumnConfig, element) {
            var date = new Date(element.created);
            return date.toLocaleString();
        }       
    },{
        header: "Updated",
        field: "updated",
        defaultSort: true,
        dataType: ColumnDataType.DATE,
        filterable: true,
        renderContent: function (col: ColumnConfig, element) {
            var date = new Date(element.updated);
            return date.toLocaleString();
        }       
    },{
        header: "User",
        field: "username",
    }, {
        header: "Sender",
        field: "sender"
    },{
        header: "Account",
        field: "acctId"
    },{
        header: "Port",
        field: "port",
        visible: false
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: AESSMTPConnectionService) {
        var me = this;
        this.buttons = [ButtonConfig.createAddButton("Create SMTP Connection", function(dt, selected){
            const dialogRef = me.dialog.open(SMTPConnectionEditDialog, {
                width: '600px',
                data: {}
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }), ButtonConfig.createEditButton("Edit SMTP Connection", function(dt, selected){
            const dialogRef = me.dialog.open(SMTPConnectionEditDialog, {
                width: '600px',
                data: {
                    "instance": selected[0]
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        })];
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

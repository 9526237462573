import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface LogConfiguration {
    id: string;
    expires: Date;
    created: Date;
    updated: Date;
    filterField: string;
    filterValue: string;
    acctId: number;
    logLevel: number;
}

export interface CustomLogConfigurationFilter {
    acctId? : any
}

@Injectable()
export class AESLogConfigurationService implements DataTableSearchService<LogConfiguration>, CRUDService<LogConfiguration>{

    public static FUNCTION_BLOCK_TYPES : string[] = ["FunctionBlock", "Decoder"]
    public static LOG_CONFIG_OWNERSHIP_FILTER = "LOG_CONFIG_OWNERSHIP_FILTER";

    constructor(private http: HttpClient) { }

    create(object: LogConfiguration): Observable<Message> {
        const href = 'logs/config';
        var requestUrl = `${href}`

        return this.http.post<Message>(requestUrl, object);
    }
    edit(object: LogConfiguration): Observable<Message> {
        const href = 'logs/config';
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object);
    }
    delete(object: LogConfiguration): Observable<Message> {
        const href ='logs/config';
        var params = new HttpParams().set("id", object.id);
        var requestUrl = `${href}`

        return this.http.delete<Message>(requestUrl, {
            params : params
        });
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: CustomLogConfigurationFilter, columns: any): Observable<SenetData<LogConfiguration>> {
        const href = 'logs/config';
        
        var offset = page * limit
        var requestUrl = `${href}`;

        var params = new HttpParams();

        if(sort != undefined && sort != ""){
            params = params.set("sort", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortDir", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }

        if(customFilter && customFilter.acctId != undefined){
            params = params.set("acctId", customFilter.acctId);
        }

        if (columns != undefined) {
            params = params.set("columns", columns);
        }   
        
        return this.http.get<SenetData<LogConfiguration>>(requestUrl, {
            params : params
        });
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Account } from './AESAccountService';
import { User } from './AESUserService';

@Injectable()
export class UserService {

    constructor(private http: HttpClient) {
    }

    getUserFromServer() : Observable<User>{
        const href = 'users';
        var requestUrl = `${href}`;
        var params = new HttpParams().set("currentUser", true);

        return this.http.get<User>(requestUrl, {
            "params" : params
        });
    }

    getAccountFromServer() : Observable<Account>{
        const href = 'accounts';
        var requestUrl = `${href}`;
        var params = new HttpParams().set("currentAccount", true);

        return this.http.get<Account>(requestUrl, {
            "params" : params
        });
    }

    updateAccount(object) : Observable<any>{
        const href = 'accounts';
        var requestUrl = `${href}`

        return this.http.put<any>(requestUrl, object);
    }

    getItemFromServer(id : string) : Observable<any>{
        const href = 'users/settings';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id);
        }
        return this.http.get<any>(requestUrl, { params : params });
    }

    deleteItemFromServer(id : string) : Observable<any>{
        const href = 'users/settings';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id);
        }
        return this.http.delete<any>(requestUrl, { params : params });
    }

    getAllIds(prefix?: string) : Observable<any>{
        const href = 'users/settings';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        params.set("fields", "id");
        if(prefix != undefined){
            params = params.set("prefix", prefix);
        }
        return this.http.get<any>(requestUrl, { params : params });
    }

    setItemOnServer(id : string, value : any) : Observable<any>{
        const href = 'users/settings';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id);
        }
        return this.http.post<any>(requestUrl, {
            "value": value
        }, { params : params });
    }

    getAccountItemFromServer(id : string) : Observable<any>{
        const href = 'accounts/settings';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id);
        }
        return this.http.get<any>(requestUrl, { params : params });
    }

    setAccountItemOnServer(id : string, value : any) : Observable<any>{
        const href = 'accounts/settings';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id);
        }
        return this.http.post<any>(requestUrl, {
            "value": value
        }, { params : params });
    }
    
    getUser(callback){
        var sub = this.getUserFromServer();
        sub.subscribe((resp) => {
            var userObj = resp;
            callback(userObj);
        }, (err) =>{
            callback(err);
        }
        );
    }

    getItem(id : string, defaultVal: any, callback){
        var me = this;
        var sub = this.getItemFromServer(id);
        sub.subscribe((resp) => {
            var val = resp[id];
            if(val == undefined){
                val = defaultVal;
                if(defaultVal != undefined){
                    me.setItem(id, defaultVal);
                }
            }
            callback(val);
        }, (err) =>{
            callback(defaultVal);
            if(defaultVal != undefined){
                me.setItem(id, defaultVal);
            }
        }
        );
    }

    setItem(id : string, value: any){
        if(value != undefined){
            value = value.toString();
        }
        else{
            value = "";
        }
        var sub = this.setItemOnServer(id, value);
        sub.subscribe(() =>{

        }, (err) => {
            console.error("Unable to save setting, " + err);
        });
    }

    getAccountItem(id : string, defaultVal: any, callback){
        var sub = this.getAccountItemFromServer(id);
        var me = this;
        sub.subscribe((resp) => {
            var val = resp[id];
            if(val == undefined){
                val = defaultVal;
                if(val != undefined){
                    me.setAccountItem(id, val);
                }
            }
            callback(val);
        }, (err) =>{
            callback(defaultVal);
            if(defaultVal != undefined){
                me.setAccountItem(id, defaultVal);
            }
        }
        );
    }

    setAccountItem(id : string, value: any){
        if(value != undefined){
            value = value.toString();
        }
        else{
            value = "";
        }
        var sub = this.setAccountItemOnServer(id, value);
        sub.subscribe(() =>{

        }, (err) => {
            console.error("Unable to save setting, " + err);
        });
    }

    setItemAsync(id : string, value: any) :Observable<any>{
        if(value != undefined){
            value = value.toString();
        }
        else{
            value = "";
        }
        var sub = this.setItemOnServer(id, value);
        return sub;
    }

    parseBoolean(val){
        if(typeof val == 'boolean'){
            return val;
        }
        if(typeof val == 'string'){
            val = val.toLowerCase();
            return val == "true";
        }
        else val != undefined;
    }

    login(username : string, password : string) : Observable<any>{
        const href = 'users/session';
        var requestUrl = `${href}`;
        return this.http.post<any>(requestUrl, {
            "username" : username,
            "password": password
        }, {observe: 'response'});
    }

    logout() : Observable<any>{
        const href = 'users/session';
        var requestUrl = `${href}`;
        return this.http.delete<any>(requestUrl);
    }

    getSession() : Observable<any>{
        const href = 'users/session';
        var requestUrl = `${href}`;
        return this.http.get<any>(requestUrl);
    }
}
import { Component, HostListener, Input, ViewChild } from "@angular/core";
import { ViewRegistration } from "aes-common";
import { ColumnConfig } from "aes-datatables";
import { ButtonConfig, DataTableOptions, DataTable } from "aes-datatables";
import { User, AESUserService } from "../../services/AESUserService";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Utils } from "../../services/Utils";
import { UserEditDialog } from "../../dialogs/userdialog/userdialog";

@Component({
    template: '<a (click)="showEditor($event, element)">{{element.username}}</a>'
})
export class ShowUsersEditorCell{
    @Input()
    element: User;

    @Input()
    col: ColumnConfig;

    dialogRef: any;

    @HostListener("click")showEditor(evt, element){
        evt.stopPropagation();
        if(!this.dialogRef){
            //this.table.buttons[1].click(this.table.dt, [element]);
            this.dialogRef = this.dialog.open(UserEditDialog, {
                width: '600px',
                data: {
                    "instance": element
                }
            });
    
            this.dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    this.table.dt.refresh(this.table.dt, [element]);
                }
                this.dialogRef = undefined;
            });            
            console.log("Called click handler");
        }

    }
    constructor(public dialog: MatDialog, public table: AESUsers){
    }    
}

@Component({
    template: "<a [routerLink]=\"['/connections/sql/details/',element.defaultConnectionId]\">{{element.defaultConnectionId}}</a>"
})
export class UserConnectionLinkRow{
    @Input()
    element: User;

    @Input()
    col: ColumnConfig;
}

@Component({
    selector: 'aesusers',
    templateUrl: 'aesusers.html',
    styleUrls: ['./aesusers.scss']
})

export class AESUsers {
    static view = new ViewRegistration("Portal Users", "/admin/users", "assignment_ind", [], Utils.isAdminUser);

    dtOptions:DataTableOptions = {pageSize : 100, showSelectionColumn: false, multiSelect: false};

    @ViewChild(DataTable, { static: true })
    dt : DataTable

    columnCfgs: ColumnConfig[] = [{
        header: "User",
        field: "username",
        defaultSort: true,
        contentComponent: ShowUsersEditorCell
    },{
        header: "Email",
        field: "email",
    },{
        header: "Account",
        field: "acctId"
    }, {
        header: "Role",
        title: "Role",
        field: "role",
        renderContent: function(col: ColumnConfig, element: User){
            var role = element.role;
            if(role == "USER"){
                return "User";
            }
            else if(role == "ADMIN"){
                return "Admin";
            }
            return role;
        }
    }, {
        header: "Creation",
        title: "Creation",
        field: "creationDate",
        renderContent: function(col: ColumnConfig, element: User){
            console.log()
            var date = new Date(element.creationDate);
            return date.toLocaleString();
        }
    }, {
        header: "Connection",
        title: "The Default Connection ID to use",
        field: "defaultConnectionId",
        visible: false,
        contentComponent: UserConnectionLinkRow
    }, {
        header: "Connection Type",
        title: "The Default Connection Type",
        field: "connectionType",
        visible: false
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: AESUserService,  public snackBar: MatSnackBar) {
        var me = this;
        this.buttons = [ButtonConfig.createAddButton("Create User", function(dt, selected){
            const dialogRef = me.dialog.open(UserEditDialog, {
                width: '600px',
                data: {}
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }), ButtonConfig.createEditButton("Edit User", function(dt, selected){
            const dialogRef = me.dialog.open(UserEditDialog, {
                width: '600px',
                data: {
                    "instance": selected[0]
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }), ButtonConfig.createDeleteButton("Delete User", function(selected){
            return "Are you sure you want to delete user " + selected.username + "?";
        }, function(selected, dt){
            me.httpClient.delete(selected)
            .subscribe(resp => {
                me.openSnackBar(selected.username + " Deleted!", undefined);
                dt.refresh(dt, selected);
            }, (err) => {
               console.log(err);
               var message = err.error.message;
               if(message == undefined){
                message = "Unknown";
               }
               if(message == "Internal Server Error"){
                message = "Server was unable to process your request";
               }
               var error = "Error deleting entry: " + message;
               
               me.openSnackBar(error, undefined);
               dt.refresh(dt, selected);
            });
        },dialog)];
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

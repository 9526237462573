import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AesDatatablesModule } from 'aes-datatables';
import { AesCommonModule, SenetInterceptor } from 'aes-common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Utils } from '../services/Utils';
import { AESDevicesService } from '../services/AESDevicesService';
import { AESDevices, DeviceLinkRow, LnsDeviceLink} from '../views/devices/devices';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { Login } from '../views/login/login';
import { ChartModule } from 'angular2-chartjs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MccColorPickerModule } from 'material-community-components/color-picker';
import {MatAutocompleteModule} from '@angular/material/autocomplete'; 
import {MatBadgeModule} from '@angular/material/badge'; 
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button'; 
import {MatButtonToggleModule} from '@angular/material/button-toggle'; 
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatCardModule} from '@angular/material/card'; 
import {MatChipsModule} from '@angular/material/chips'; 
import {MatDatepickerModule} from '@angular/material/datepicker'; 
import {MatDialogModule} from '@angular/material/dialog'; 
import {MatDividerModule} from '@angular/material/divider'; 
import {MatExpansionModule} from '@angular/material/expansion'; 
import {MatGridListModule} from '@angular/material/grid-list'; 
import {MatInputModule} from '@angular/material/input'; 
import {MatIconModule} from '@angular/material/icon'; 
import {MatListModule} from '@angular/material/list'; 
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar'; 
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
import {MatRadioModule} from '@angular/material/radio';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core'; 
import {MatSelectModule} from '@angular/material/select'; 
import {MatSidenavModule} from '@angular/material/sidenav'; 
import {MatSlideToggleModule} from '@angular/material/slide-toggle'; 
import {MatSliderModule} from '@angular/material/slider'; 
import {MatSnackBarModule} from '@angular/material/snack-bar'; 
import {MatSortModule} from '@angular/material/sort'; 
import {MatStepperModule} from '@angular/material/stepper'; 
import {MatTableModule} from '@angular/material/table'; 
import {MatTabsModule} from '@angular/material/tabs'; 
import {MatToolbarModule} from '@angular/material/toolbar'; 
import {MatTooltipModule} from '@angular/material/tooltip'; 
import {MatTreeModule} from '@angular/material/tree'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetails } from '../views/devices/devicedetails/devicedetails';
import { DeviceDetailsEventsPanel } from '../views/devices/devicedetails/eventsview/devicedetails_events';
import { DeviceDetailsMapsPanel } from '../views/devices/devicedetails/mapssview/devicedetails_maps';
import { DeviceDetailsDynamicStatePanel } from '../views/devices/devicedetails/dynamicstateview/devicedetails_dynamicstate';
import { DeviceDetailsConfiguredStatePanel } from '../views/devices/devicedetails/configuredstateview/devicedetails_configuredstate';
import { AESMaps } from '../views/map/map';
import { AESDeviceEvents, EventDeviceLinkRow,DevDataDialogCell } from '../views/devices/events/events';
import { SearchMapControl } from '../views/map/controls/search-map-control';
import { AESSettings } from '../views/settings/settings';
import { DeviceCommandDialog } from '../dialogs/device-command/device-command';
import { DevDataDialog } from '../dialogs/devdatadialog/devdatadialog';
import { AuthInterceptor } from '../services/AuthInterceptor';
import { AppStateService } from '../services/AppStateService';
import { EventsConfigService } from '../services/EventsConfigService';
import { EventsConfiguration } from '../views/events/event-config/event-config';
import { EventConfigurationEditDialog } from '../dialogs/event-config-create-edit/event-config-create-edit';
import { EventsService } from '../services/EventsService';
import { Events } from '../views/events/events';
import { GoogleMapsModule } from '@angular/google-maps';
import { DeviceEventsService } from '../services/AESDeviceEventsService';
import { UserService } from '../services/UserService';
import { AESCodecService } from '../services/AESCodecService';
import { AESRemoteFunctionBlocksService } from '../services/AESRemoteFunctionBlocksService';
import { AESPipelinesService } from '../services/AESPipelinesService';
import { AESPipelines, ShowPipelineDiagramDialogCell, PipelineDetailsLink, ShowPipelinesEditorCell } from '../views/pipelines/pipelines';
import { AESRemoteFunctionBlocks, ShowRemoteFunctionBlockEditorCell, FunctionBlockDetailsLink} from '../views/pipelines/remote-function-blocks/remote-function-blocks';
import { AESCodecs, ShowCodecEditorCell } from '../views/pipelines/codecs/codecs';
import { PipelineDetails } from '../views/pipelines/pipeline-details/pipeline-details';
import { FunctionBlockDetails } from '../views/pipelines/remote-function-blocks/function-block-details/function-block-details';
import { FunctionBlockPipelinesPanel } from '../views/pipelines/remote-function-blocks/function-block-details/function-block-pipelines/function-block-pipelines';
import { CookieModule } from 'ngx-cookie';
import { AESDeviceProfileService } from '../services/AESDeviceProfileService';
import { AESDeviceProfiles, PipelineCellLink, PipelinePeriodicCellLink, ShowDeviceProfileEditorCell } from '../views/device-profiles/device-profiles';
import { DeviceProfileDetails} from '../views/device-profiles/device-profile-details/device-profile-details';
import { DeviceEditDialog } from '../dialogs/devicedialog/devicedialog';
import { AESLogsConfiguration } from '../views/logs/logconfiguration/logconfiguration';
import { AESLogsService } from '../services/AESLogsService';
import { AESLogs, LogDetailsLink, LogPipelineLink, LogDeviceLink} from '../views/logs/logs';
import { LogDetails } from '../views/logs/log-details/log-details';
import { LogConfigurationDialog } from '../dialogs/logconfigurationdialog/logconfigurationdialog';
import { AESLogConfigurationService } from '../services/AESLogConfigurationService';
import { APIDocs } from '../views/docs/apidocs/apidocs';
import { AESAccountAPIKeys } from '../views/apikeys/accountapikeys/accountapikeys';
import { AESUserAPIKeys } from '../views/apikeys/userapikeys/userapikeys';
import { AESAccountAPIKeysService } from '../services/AESAccountAPIKeysService';
import { AESUserAPIKeysService } from '../services/AESUserAPIKeysService';
import { AESLookUp, ShowLookUpEditorCell } from '../views/metadata/lookup/lookup';
import { LookUpDetails } from '../views/metadata/lookup/lookup-details/lookup-details';
import { AESLookUpService } from '../services/AESLookUpService';
import { CodecDetails } from '../views/pipelines/codecs/codec-details/codec-details';
import { AESUserService } from '../services/AESUserService';
import { AESAccountService } from '../services/AESAccountService';
import { AESUsers, ShowUsersEditorCell, UserConnectionLinkRow } from '../views/users/aesusers';
import { UserEditDialog } from '../dialogs/userdialog/userdialog';
import { AESHTTPConnectionService } from '../services/AESHTTPConnectionService';
import { AESSQLConnectionService } from '../services/AESSQLConnectionService';
import { AESSMTPConnectionService } from '../services/AESSMTPConnectionService';
import { UserAPIKeyEditDialog } from '../dialogs/userapikeydialog/userapikeydialog';
import { AccountAPIKeyEditDialog } from '../dialogs/accountapikeydialog/accountapikeydialog';
import { AccountEditDialog } from '../dialogs/accountdialog/accountdialog';
import { AESAccounts, ShowAccountEditorCell } from '../views/accounts/accounts';
import { Applications, ShowApplicationEditorCell } from '../views/applications/applications';
import { ApplicationEditDialog } from '../dialogs/applicationsdialog/applicationsdialog';
import { SQLConnections, SQLLinkRow } from '../views/connections/sqlconnections';
import { SQLConnectionEditDialog } from '../dialogs/sqlconnectiondialog/sqlconnectiondialog';
import { SMTPConnectionEditDialog } from '../dialogs/smtpconnectiondialog/smtpconnectiondialog';
import { AESSMTPConnections, ShowSMTPConnectionEditorCell } from '../views/connections/smtpconnections/smtpconnections';
import { HTTPConnections, ShowHTTPConnectionEditorCell } from '../views/connections/httpconnections/httpconnections';
import { HTTPConnectionEditDialog } from '../dialogs/httpconnectiondialog/httpconnectiondialog';
import { AESApplicationService } from '../services/AESApplicationService';
import { AESAdminAccountAPIKeys } from '../views/apikeys/accountapikeys/adminapikeys';
import { AESAdminUserAPIKeys } from '../views/apikeys/userapikeys/adminuserapikeys';
import { ConnectionDetails } from '../views/connections/connectiondetails/connectiondetails';
import { ConnectionDetailsInfoPanel } from '../views/connections/connectiondetails/infoview/connectiondetails_Info';
import { DevicesBulkEditDialog } from '../dialogs/devicebulkdialog/devicebulkdialog';
import { AdminHTTPConnections, ShowAdminHTTPConnectionEditorCell } from '../views/connections/httpconnections/adminhttpconnections/adminhttpconnections';
import { AdminApplications, ShowAdminApplicationEditorCell } from '../views/applications/adminapplications/adminapplications';
import { AESSystemSettings, AESSystemSettingValueCell, ShowAESSystemSettingsEditorCell } from '../views/system-settings/system-settings';
import { AESSystemSettingsService } from '../services/AESSystemSettingsService';
import { SystemSettingsEditDialog } from '../dialogs/systemsettingsdialog/systemsettingsdialog';


export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  { provide: HTTP_INTERCEPTORS, useClass: SenetInterceptor, multi: true}
]

@NgModule({
  declarations: [
    AppComponent,
    AESDevices,
    Login,
    DeviceLinkRow,
    DeviceDetails,
    DeviceDetailsEventsPanel,
    DeviceDetailsMapsPanel,
    DeviceDetailsDynamicStatePanel,
    DeviceDetailsConfiguredStatePanel,
    AESMaps,
    AESDeviceEvents,
    EventDeviceLinkRow,
    DevDataDialogCell,
    LnsDeviceLink,
    SearchMapControl,
    AESSettings,
    DevDataDialog,
    DeviceCommandDialog,
    EventsConfiguration,
    EventConfigurationEditDialog,
    Events,
    AESPipelines,
    AESRemoteFunctionBlocks,
    FunctionBlockDetails,
    FunctionBlockPipelinesPanel,
    AESCodecs,
    PipelineDetails,
    ShowPipelineDiagramDialogCell,
    ShowPipelinesEditorCell,
    PipelineDetailsLink,
    ShowRemoteFunctionBlockEditorCell,
    ShowCodecEditorCell,
    AESDeviceProfiles,
    ShowDeviceProfileEditorCell,
    DeviceProfileDetails,
    PipelineCellLink,
    FunctionBlockDetailsLink,
    DeviceEditDialog,
    AESLogs,
    LogDetails,
    LogDetailsLink,
    LogDeviceLink,
    LogPipelineLink,
    AESLogsConfiguration,
    LogConfigurationDialog,
    APIDocs,
    PipelinePeriodicCellLink,
    AESAccountAPIKeys,
    AESUserAPIKeys,
    ShowLookUpEditorCell,
    AESLookUp,
    LookUpDetails,
    CodecDetails,
    AESUsers,
    ShowUsersEditorCell,
    UserConnectionLinkRow,
    UserEditDialog,
    UserAPIKeyEditDialog,
    AccountAPIKeyEditDialog,
    AESAccounts,
    AccountEditDialog,
    ShowAccountEditorCell,
    Applications,
    ShowApplicationEditorCell,
    ApplicationEditDialog,
    SQLConnections,
    SQLConnectionEditDialog,
    SQLLinkRow,
    SMTPConnectionEditDialog,
    AESSMTPConnections,
    ShowSMTPConnectionEditorCell,
    HTTPConnections,
    ShowHTTPConnectionEditorCell,
    HTTPConnectionEditDialog,
    AESAdminAccountAPIKeys,
    AESAdminUserAPIKeys,
    ConnectionDetails,
    ConnectionDetailsInfoPanel,
    DevicesBulkEditDialog,
    AdminApplications,
    AdminHTTPConnections,
    ShowAdminHTTPConnectionEditorCell,
    ShowAdminApplicationEditorCell,
    AESSystemSettings,
    ShowAESSystemSettingsEditorCell,
    SystemSettingsEditDialog,
    AESSystemSettingValueCell
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    ChartModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    FormsModule,
    ReactiveFormsModule,
    AesDatatablesModule,
    AesCommonModule,
    GoogleMapsModule,
    MccColorPickerModule.forRoot({
      empty_color: 'transparent',
      used_colors: ['#000000', '#FFF555'],
      selected_icon: 'check',
      disable_selected_icon: false,
      enable_alpha_selector: false
    }),
    CookieModule.withOptions()
  ],
  providers: [
    httpInterceptorProviders, 
    Utils, 
    AESDevicesService, 
    UserService, 
    AppStateService, 
    EventsConfigService, 
    EventsService, 
    DeviceEventsService, 
    AESCodecService, 
    AESRemoteFunctionBlocksService, 
    AESPipelinesService, 
    AESDeviceProfileService,
    AESLogConfigurationService,
    AESLogsService,
    AESAccountAPIKeysService,
    AESUserAPIKeysService,
    AESLookUpService,
    AESUserService,
    AESAccountService,
    AESHTTPConnectionService,
    AESSQLConnectionService,
    AESSMTPConnectionService,
    AESApplicationService,
    AESSystemSettingsService
  ],
  entryComponents: [
    DeviceLinkRow, 
    EventDeviceLinkRow, 
    DevDataDialogCell, 
    LnsDeviceLink, 
    DeviceCommandDialog, 
    EventConfigurationEditDialog,
    ShowPipelineDiagramDialogCell, 
    ShowPipelinesEditorCell,
    ShowDeviceProfileEditorCell,
    PipelineDetailsLink,
    FunctionBlockDetailsLink,
    ShowRemoteFunctionBlockEditorCell, 
    ShowCodecEditorCell,
    DeviceEditDialog,
    ShowLookUpEditorCell,
    LogDetailsLink,
    LogDeviceLink,
    LogPipelineLink,
    ShowUsersEditorCell,
    UserConnectionLinkRow,
    UserEditDialog,
    UserAPIKeyEditDialog,
    AccountAPIKeyEditDialog,
    AccountEditDialog,
    ShowAccountEditorCell,
    ShowApplicationEditorCell,
    ApplicationEditDialog,
    SQLLinkRow,
    SQLConnectionEditDialog,
    ShowSMTPConnectionEditorCell,
    SMTPConnectionEditDialog,
    ShowHTTPConnectionEditorCell,
    HTTPConnectionEditDialog,
    DevicesBulkEditDialog,
    LogConfigurationDialog,
    ShowAdminApplicationEditorCell,
    ShowAdminHTTPConnectionEditorCell,
    ShowAESSystemSettingsEditorCell,
    SystemSettingsEditDialog,
    AESSystemSettingValueCell
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

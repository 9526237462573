import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Utils } from "../../services/Utils";
import { Account, AESAccountService } from "../../services/AESAccountService";
import { AESApplicationService } from "../../services/AESApplicationService";
import { HTTPConnection, AESHTTPConnectionService } from "../../services/AESHTTPConnectionService";

@Component({
    selector: 'applicationsdialog',
    templateUrl: 'applicationsdialog.html',
    styleUrls: ['./applicationsdialog.css']
})
export class ApplicationEditDialog {

    instance: any;
    editType: string = "Create";

    inProgress: boolean;
    error: string;

    connections: HTTPConnection[];
    accounts: Account[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ApplicationEditDialog>, private applicationService:AESApplicationService, public connectionSearch:AESHTTPConnectionService, private accountSearch: AESAccountService) {
        var inst = data.instance;
        if (inst != undefined) {
            this.instance = { ...inst };
            this.editType = "Edit";
        }
        else{
            this.instance = {};
        }

        if(this.instance.connectionType == undefined){
            this.instance.connectionType = "HTTP";
        }

        if(this.instance.mode == undefined){
            this.instance.mode = "Application Provider";
        }
        
        if(this.instance.apiKeyEnc != undefined){
            this.instance.apiKey = "Placeholder no peaking";
        }

        var me = this;
        if(Utils.isAdminUser()){
            accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
            .subscribe(data => {
                me.accounts = data.data;
                if(me.accounts == undefined || me.accounts.length == 0){
                    me.error = "Unable to load accounts, please try again later or contact an administrator.";
                }
                else{
                    if(me.instance.acctId == undefined){
                        me.instance.acctId = data.data[0].acctId;
                    }
                    connectionSearch.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : me.instance.acctId})
                    .subscribe(data => {
                        me.connections = data.data;
                        if(me.connections == undefined || me.connections.length == 0){
                            me.error = "Unable to load connections, please try again later or contact an administrator.";
                        }
                        else{
                            me.inProgress = false;

                            if(me.instance.connectionId == undefined){
                                me.instance.connectionId = data.data[0].connectionId;
                            }

                        }
                    }, (err) => {
                    me.error = "Unable to load connections, please try again later or contact an administrator.";
                    });
                }
            }, (err) => {
            me.error = "Unable to load accounts, please try again later or contact an administrator.";
            });
        }
        this.processAccountUpdate = this.processAccountUpdate.bind(this);
    }

    isAdminUser(){
        return Utils.isAdminUser();
    }

    processAccountUpdate(acctId){
        var me = this;
        me.error = "";
        this.connectionSearch.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : acctId})
        .subscribe(data => {
            me.connections = data.data;
            if(me.connections == undefined || me.connections.length == 0){
                me.error = "Unable to load connections, please try again later or contact an administrator.";
            }
            else{
                me.inProgress = false;
                me.instance.connectionId = data.data[0].connectionId;

            }
        }, (err) => {
        me.error = "Unable to load connections, please try again later or contact an administrator.";
        });
    }

    save() {
        var obs;
        this.error = "";
        var me = this;
        if(this.instance.apiKey == "Placeholder no peaking"){
            this.instance.apiKey = undefined;
        }
        else if(this.instance.apiKey == undefined || this.instance.apiKey == ""){
            if(this.instance.mode != "Custom"){
                this.error = "An API key is required for " + this.instance.mode + " mode";
                return;
            }
        }
        if(this.editType == "Create"){
            obs = this.applicationService.create(this.instance);
        }
        else{
            obs = this.applicationService.edit(this.instance);
        }
        this.instance.connectionType = "HTTP";
        this.inProgress = true;
        obs.subscribe(resp => {
            this.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
           console.log(err);
           me.error = err.error.message;
           if(me.error == "Internal Server Error"){
               me.error = "Server was unable to process your request";
           }
           me.inProgress = false;
        });
    }
}
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'confirmdialog',
    templateUrl: 'confirmdialog.html',
    styleUrls: ['./confirmdialog.css']
})
export class ConfirmDialog {
    message: string;
    title: string;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConfirmDialog>) {
        this.message = data.message;
        this.title = data.title;
    }
    confirm() {
        if(this.data.success){
            this.data.success();
        }
        this.dialogRef.close("Success");
    }
}
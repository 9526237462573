import { Component, Input, HostListener, ViewChild } from "@angular/core";
import { OptionsService, ViewRegistration } from "aes-common";
import {ActivatedRoute, Router} from '@angular/router';
import { ColumnConfig, DataTable, DataTableOptions } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AESCodecService, Codec } from "../../../services/AESCodecService";

@Component({
    template: "<a [routerLink]=\"['/configurations/codecs/details',element.id]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.name}}</a>"
})
export class ShowCodecEditorCell{
    @Input()
    element: Codec;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    }  
}

@Component({
    selector: 'codecs',
    templateUrl: 'codecs.html',
    styleUrls: ['./codecs.scss']
})
export class AESCodecs {
    static view = new ViewRegistration("Codecs", "/configurations/codecs", "emoji_symbols", []);
    dtOptions:DataTableOptions = {pageSize : 100, sorting: false, showSelectionColumn: false, multiSelect: false};

    customFilter : any = {
        "fields" : [
            "name",
            "description",
            "lastUpdated",
            "acctId",
            "id"
        ]
    }
    @ViewChild(DataTable, { static: true })
    dt : DataTable
    columnCfgs: ColumnConfig[] = [{
        header: "Name",
        field: "name",
        contentComponent: ShowCodecEditorCell
    },{
        header: "Description",
        field: "description"
    },{
        header: "Last Updated",
        field: "lastUpdated",
        renderContent: function(col: ColumnConfig, element: Codec){
            var date = new Date(element.lastUpdated);
            return date.toLocaleString();
        }
    },{
        header: "Shared",
        field: "acctId",
        renderContent(col, element: Codec) {
            if(element.acctId == undefined){
                return "Shared";
            }
            return "Owned";
        },
    },{
        header: "ID",
        field: "id",
        defaultSort: true

    },];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: AESCodecService,  public snackBar: MatSnackBar, 
        public options: OptionsService, private router: Router) {
        var me = this;
        this.buttons = [ButtonConfig.createAddButton("Create Codec", function(dt, selected){
            me.router.navigateByUrl("configurations/codecs/details/-1");
            /*
            const dialogRef = me.dialog.open(CodecsEditDialog, {
                width: '600px',
                data: {}
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
            */
        }), ButtonConfig.createEditButton("Edit Codec", function(dt, selected){
            me.router.navigateByUrl("configurations/codecs/details/" + selected[0].id);
            /*
            const dialogRef = me.dialog.open(CodecsEditDialog, {
                width: '600px',
                data: {
                    "instance": selected[0]
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
            */
        })];
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

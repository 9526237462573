import { NgModule } from '@angular/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete'; 
import {MatBadgeModule} from '@angular/material/badge'; 
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button'; 
import {MatButtonToggleModule} from '@angular/material/button-toggle'; 
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatCardModule} from '@angular/material/card'; 
import {MatChipsModule} from '@angular/material/chips'; 
import {MatDatepickerModule} from '@angular/material/datepicker'; 
import {MatDialogModule} from '@angular/material/dialog'; 
import {MatDividerModule} from '@angular/material/divider'; 
import {MatExpansionModule} from '@angular/material/expansion'; 
import {MatGridListModule} from '@angular/material/grid-list'; 
import {MatInputModule} from '@angular/material/input'; 
import {MatIconModule} from '@angular/material/icon'; 
import {MatListModule} from '@angular/material/list'; 
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar'; 
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
import {MatRadioModule} from '@angular/material/radio';
import {MatRippleModule} from '@angular/material/core'; 
import {MatSelectModule} from '@angular/material/select'; 
import {MatSidenavModule} from '@angular/material/sidenav'; 
import {MatSlideToggleModule} from '@angular/material/slide-toggle'; 
import {MatSliderModule} from '@angular/material/slider'; 
import {MatSnackBarModule} from '@angular/material/snack-bar'; 
import {MatSortModule} from '@angular/material/sort'; 
import {MatStepperModule} from '@angular/material/stepper'; 
import {MatTableModule} from '@angular/material/table'; 
import {MatTabsModule} from '@angular/material/tabs'; 
import {MatToolbarModule} from '@angular/material/toolbar'; 
import {MatTooltipModule} from '@angular/material/tooltip'; 
import {MatTreeModule} from '@angular/material/tree'; 
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AesCommonModule } from 'aes-common';
import { TableSettingsDialog } from './datatable/dialogs/tablesettings/tablesettings';
import { ColumnFilterDialog } from './datatable/dialogs/columnfilters/columnfilters';
import { DataTable } from './datatable/datatable';
import { DataTablesColumn } from './datatable/datatablescolumn';

@NgModule({
  declarations: [TableSettingsDialog, ColumnFilterDialog, DataTable, DataTablesColumn],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    AesCommonModule
  ],
  entryComponents: [
    TableSettingsDialog,
    ColumnFilterDialog

  ],
  exports: [DataTable, DataTablesColumn]
})
export class AesDatatablesModule { }

import { Component, Input, ViewContainerRef, ViewChild, ComponentRef, OnDestroy, Compiler, ComponentFactoryResolver, OnInit } from "@angular/core";
import { ColumnConfig } from "./columncfg";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
    selector: 'dt-col',
    template: `<div>
            <div *ngIf="!col.contentComponent && content != undefined">
                <span [innerHTML]="content"></span>
            </div>
            <div #container></div>
        </div>`
})
export class DataTablesColumn implements OnInit, OnDestroy {
    @Input()
    col: ColumnConfig;

    @Input()
    element: any;

    @ViewChild('container', { read: ViewContainerRef, static: true })
    container: ViewContainerRef;

    private componentRef: ComponentRef<any>;

    content: SafeHtml;

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private compiler: Compiler,
        private sanitizer:DomSanitizer){
    }


    private createComponent(tmpCmp) {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
        let factory = this.componentFactoryResolver.resolveComponentFactory(tmpCmp);
        this.componentRef = this.container.createComponent(factory)
        this.componentRef.instance.col = this.col;
        this.componentRef.instance.element = this.element;
    }

    ngOnInit(){
        if(this.col.contentComponent != undefined){
            this.createComponent(this.col.contentComponent);
        }
        else{
            this.content = this.sanitizer.bypassSecurityTrustHtml(this.col.renderContent(this.col, this.element));
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }
}

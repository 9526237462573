import { Component, Input } from "@angular/core";
import { OptionsService } from "../../../services/Options";

@Component({
    selector: 'detailsimg',
    templateUrl: 'detailsimg.html',
    styleUrls: ['./detailsimg.scss']
})
export class DetailsImagePanel {
    constructor(private options: OptionsService){
    }
}
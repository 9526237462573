import { Component, Input, HostListener, ViewChild } from "@angular/core";
import { ViewRegistration } from "aes-common";
import { ColumnConfig } from "aes-datatables";
import { ButtonConfig, DataTableOptions, DataTable } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Account, AESAccountService } from "../../services/AESAccountService";
import { AccountEditDialog } from "../../dialogs/accountdialog/accountdialog";
import { Utils } from "../../services/Utils";

@Component({
    template: '<a (click)="showEditor($event, element)">{{element.name}}</a>'
})
export class ShowAccountEditorCell{
    @Input()
    element: Account;

    @Input()
    col: ColumnConfig;

    dialogRef: any;

    @HostListener("click")showEditor(evt, element){
        evt.stopPropagation();
        if(!this.dialogRef){
            //this.table.buttons[1].click(this.table.dt, [element]);
            this.dialogRef = this.dialog.open(AccountEditDialog, {
                width: '600px',
                data: {
                    "instance": element
                }
            });
    
            this.dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    this.table.dt.refresh(this.table.dt, [element]);
                }
                this.dialogRef = undefined;
            });            
            console.log("Called click handler");
        }

    }
    constructor(public dialog: MatDialog, public table: AESAccounts){
    }    
}

@Component({
    selector: 'aesaccounts',
    templateUrl: 'accounts.html',
    styleUrls: ['./accounts.scss']
})

export class AESAccounts {
    static view = new ViewRegistration("Accounts", "/admin/accounts", "supervisor_account", [], Utils.isAdminUser);

    dtOptions:DataTableOptions = {pageSize : 100, showSelectionColumn: false, multiSelect: false};
    @ViewChild(DataTable, { static: true })
    dt : DataTable
    columnCfgs: ColumnConfig[] = [{
        header: "ID",
        field: "acctId",
        defaultSort: true,
    },{
        header: "name",
        field: "name",
        contentComponent: ShowAccountEditorCell
    }, {
        header: "Description",
        field: "description"
    }, {
        header: "Creation",
        title: "Creation",
        field: "creationDate",
        renderContent: function(col: ColumnConfig, element: Account){
            console.log()
            var date = new Date(element.creationDate);
            return date.toLocaleString();
        }
    },{
        header: "Default Device Profile",
        title: "The Default Device Profile ID to use",
        field: "defaultDeviceProfileId"
    }, {
        header: "Connection",
        title: "The Default Connection ID to use",
        field: "defaultConnectionId",
        visible: false
    }, {
        header: "Connection Type",
        title: "The Default Connection Type",
        field: "connectionType",
        visible: false
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: AESAccountService,  public snackBar: MatSnackBar) {
        var me = this;
        this.buttons = [ButtonConfig.createAddButton("Create Account", function(dt, selected){
            const dialogRef = me.dialog.open(AccountEditDialog, {
                width: '600px',
                data: {}
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }), ButtonConfig.createEditButton("Edit Account", function(dt, selected){
            const dialogRef = me.dialog.open(AccountEditDialog, {
                width: '600px',
                data: {
                    "instance": selected[0]
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        })];
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GlobalScope } from "aes-common";
import { Utils } from "../../services/Utils";
import { Account, AESAccountService } from "../../services/AESAccountService";
import { AESHTTPConnectionService } from "../../services/AESHTTPConnectionService";

@Component({
    selector: 'httpconnectiondialog',
    templateUrl: 'httpconnectiondialog.html',
    styleUrls: ['./httpconnectiondialog.css']
})
export class HTTPConnectionEditDialog {

    instance: any;
    editType: string = "Create";

    inProgress: boolean;
    error: string;
    methods: any[];

    accounts: Account[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<HTTPConnectionEditDialog>, private connectionSearch:AESHTTPConnectionService, private accountSearch: AESAccountService) {
        var inst = data.instance;
        if (inst != undefined) {
            this.instance = { ...inst };
            this.editType = "Edit";
        }
        else{
            this.instance = {};
        }
        
        if(this.instance.value1Enc != undefined){
            this.instance.value1 = "Placeholder no peaking";
        }

        if(this.instance.value2Enc != undefined){
            this.instance.value2 = "Placeholder no peaking";
        }

        this.methods = GlobalScope.HTTPMethods;
        if(this.instance.method == undefined){
            this.instance.method = "POST";
        }
        if(this.instance.host != undefined){
            var url = "";
            if(this.instance.https){
                url += "https://"
            }
            else{
                url += "http://"
            }
            url += this.instance.host;
            if(this.instance.port != undefined){
                if((this.instance.https && this.instance.port != 443) || (!this.instance.https && this.instance.port != 80)){
                    url += ":" + this.instance.port;
                }
            }
            this.instance.url = url;
        }
        var me = this;
        if(Utils.isAdminUser()){
            this.inProgress = true;
            accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
            .subscribe(data => {
                me.accounts = data.data;
                if(me.accounts == undefined || me.accounts.length == 0){
                    me.error = "Unable to load accounts, please try again later or contact an administrator.";
                }
                else{
                    me.inProgress = false;
                    if(me.instance.acctId == undefined){
                        me.instance.acctId = data.data[0].acctId;
                    }
                }
            }, (err) => {
            me.error = "Unable to load accounts, please try again later or contact an administrator.";
            });
        }
    }

    isAdminUser(){
        return Utils.isAdminUser();
    }

    save() {
        var obs;
        this.error = "";
        
        if(this.instance.url == ""){
            this.error = "A URL is required";
            return;
        }
        var url = new URL(this.instance.url);
        console.log(url);
        this.instance.https = url.protocol === "https:";
        this.instance.host = url.hostname;
        this.instance.port = url.port;
        if(this.instance.port == undefined || isNaN(this.instance.port) || this.instance.port == ""){
            if(this.instance.https){
                this.instance.port = 443;
            }
            else{
                this.instance.port = 80;
            }
        }
        if(this.instance.host == undefined || this.instance.host == ''){
            this.error = "A host is required";
            return;
        }
        if(this.instance.value1 == "Placeholder no peaking"){
            this.instance.value1 = undefined;
        }
        if(this.instance.value2 == "Placeholder no peaking"){
            this.instance.value2 = undefined;
        }
        var me = this;
        this.instance.connectionType = "HTTP";
        if(this.editType == "Create"){
            obs = this.connectionSearch.create(this.instance);
        }
        else{
            obs = this.connectionSearch.edit(this.instance);
        }
        this.inProgress = true;
        obs.subscribe(resp => {
            this.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
           if(err && err.error && err.error.message){
            console.log(err);
            me.error = err.error.message;
            if(me.error == "Internal Server Error"){
                me.error = "Server was unable to process your request";
            }
        }
        else{
            me.error = "Unable to process request"
        }
           me.inProgress = false;
        });
    }
}
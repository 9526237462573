import { Component, Input, HostListener, ViewChild } from "@angular/core";
import { ViewRegistration } from "aes-common";
import { ColumnConfig, DataTableOptions, DataTable } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { HTTPConnection, AESHTTPConnectionService } from "../../../services/AESHTTPConnectionService";
import { HTTPConnectionEditDialog } from "../../../dialogs/httpconnectiondialog/httpconnectiondialog";
import { MatDialog } from "@angular/material/dialog";
import { Utils } from "../../../services/Utils";

@Component({
    template: '<a (click)="showEditor($event, element)">{{element.host}}</a>'
})
export class ShowHTTPConnectionEditorCell{
    @Input()
    element: HTTPConnection;

    @Input()
    col: ColumnConfig;

    dialogRef: any;

    @HostListener("click")showEditor(evt, element){
        evt.stopPropagation();
        if(!this.dialogRef){
            //this.table.buttons[1].click(this.table.dt, [element]);
            this.dialogRef = this.dialog.open(HTTPConnectionEditDialog, {
                width: '600px',
                data: {
                    "instance": element
                }
            });
    
            this.dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    this.table.dt.refresh(this.table.dt, [element]);
                }
                this.dialogRef = undefined;
            });            
            console.log("Called click handler");
        }

    }
    constructor(public dialog: MatDialog, public table: HTTPConnections){
    }    
}

@Component({
    selector: 'httpconnections',
    templateUrl: 'httpconnections.html',
    styleUrls: ['./httpconnections.scss']
})
export class HTTPConnections {
    static view = new ViewRegistration("HTTP", "/configurations/http", "rss_feed", [], function(){
        return !Utils.isAdminUser();
    });
    dtOptions:DataTableOptions  = {pageSize : 100, showSelectionColumn: false, multiSelect: false};
    @ViewChild(DataTable, { static: true })
    dt : DataTable    
    columnCfgs: ColumnConfig[] = [{
        header: "ID",
        field: "connectionId"
    },{
        header: "Host",
        field: "host",
        defaultSort: true,
        contentComponent: ShowHTTPConnectionEditorCell
    },{
        header: "User",
        field: "username",
    }, {
        header: "Header 1",
        field: "header1",
        visible: false
    },{
        header: "Header 2",
        field: "header2",
        visible: false
    },{
        header: "Type",
        field: "dataType",
        renderContent: function(col: ColumnConfig, element: HTTPConnection){
            if(element.dataType){
                return element.dataType;
            }
            return "URL Params";
         }
    }, {
        header: "HTTPS",
        field: "https",
        renderContent: function(col: ColumnConfig, element: HTTPConnection){
           if(element.https){
               return "Yes";
           }
           return "No";
        }
    },{
        header: "Method",
        field: "method",
        renderContent: function(col: ColumnConfig, element: HTTPConnection){
            if(element.method){
                return element.method;
            }
            return "POST";
         }
    },{
        header: "Port",
        field: "port",
        visible: false
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: AESHTTPConnectionService) {
        var me = this;
        this.buttons = [ButtonConfig.createAddButton("Create HTTP Connection", function(dt, selected){
            const dialogRef = me.dialog.open(HTTPConnectionEditDialog, {
                width: '600px',
                data: {}
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }), ButtonConfig.createEditButton("Edit HTTP Connection", function(dt, selected){
            const dialogRef = me.dialog.open(HTTPConnectionEditDialog, {
                width: '600px',
                data: {
                    "instance": selected[0]
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        })];
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

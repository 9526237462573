import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";

export interface Logs {
    id : string,
    time : Date,
    type : string,
    containerId : string,
    caller : string,
    msg : string,
    line : string,
    requestId : string,
    pipeline : string,
    device : string;
    application : string;
    acctId?: number;
}

@Injectable()
export class AESLogsService implements DataTableSearchService<Logs>{

    constructor(private http: HttpClient) { }
    get(id): Observable<Logs> {
        const href = 'logs';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id.toString());
        }

        return this.http.get<Logs>(requestUrl, {
            params : params
        });
    }  
    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any, columns: any): Observable<SenetData<Logs>> {
        const href = 'logs';
        var offset = page * limit;
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(sort != undefined && sort != ""){
            params = params.set("sortField", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortOrder", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }
        if (columns != undefined) {
            params = params.set("columns", columns);
        }
        if (customFilter != undefined && customFilter.showSystemDiags != undefined) {
            params = params.set("showSystemDiags", customFilter.showSystemDiags);
        }
        
        return this.http.get<SenetData<Logs>>(requestUrl, { params : params });
    }
}
import { Component, Input } from "@angular/core";
import { ConfirmDialog, ViewRegistration, GlobalScope } from "aes-common";
import {ActivatedRoute} from '@angular/router';
import { ColumnConfig, DataTableOptions, DataTable } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { AESDevicesService, Device } from "../../services/AESDevicesService";
import { Utils } from "../../services/Utils";
import { DeviceCommandDialog } from "../../dialogs/device-command/device-command";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DeviceEditDialog } from "../../dialogs/devicedialog/devicedialog";
import { DevicesBulkEditDialog } from "../../dialogs/devicebulkdialog/devicebulkdialog";
import { environment } from "src/environments/environment";

@Component({
    template: "<a [routerLink]=\"['/devices/details',element.devEui]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.devEui}}</a>"
})
export class DeviceLinkRow {
    @Input()
    element: Device;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    }    
}

@Component({
    template: '<a target="_blank" href="https://mgmt.senetco.io/#/devices/details?devEui={{element.devEui}}&view=Transactions">{{element.devType}}</a>'
})
export class LnsDeviceLink{
    @Input()
    element: Device;

    @Input()
    col: ColumnConfig;
}

export class DevicesNode {
    static view = new ViewRegistration("Devices", "", "devices", [], function(){
        return !Utils.isAdminUser();
    });
}

@Component({
    selector: 'devices',
    templateUrl: 'devices.html',
    styleUrls: ['./devices.scss']
})
export class AESDevices {
    static view = new ViewRegistration("Devices", "/devices/devices", "devices", [], function(){
        return !Utils.isAdminUser();
    });
    dtOptions: DataTableOptions = { pageSize: 100, showSelectionColumn: false, multiSelect: true, columnFiltering: true, filtering: false };

    columnCfgs: ColumnConfig[] = [{
        header: "Device",
        field: "devEui",
        filterable: true,
        defaultSort: true,
        contentComponent: DeviceLinkRow
    }, {
        header: "Last Uplink Time",
        field: "lastUplinkTime",
        dataType: 'date',
        filterable: true,
        renderContent: function (col: ColumnConfig, element: Device) {
            var date = new Date(element.lastUplinkTime);
            return date.toLocaleString();
        }
    },{
        header: "Type",
        field: "devType",
        filterable: true,
        contentComponent: LnsDeviceLink
    },{
        header: "Name",
        field: "name",
        filterable: true,
        visible: true,
        renderContent: Utils.renderContent
    },{
        header: "Created",
        field: "dateCreated",
        visible: false,
        renderContent: Utils.renderContent
    },{
        header: "Owner",
        field: "owner",
        filterable: true,
        visible: false,
        renderContent: Utils.renderContent
    },{
        header: "Application",
        field: "application",
        filterable: true,
        visible: true,
        renderContent: Utils.renderContent
    },{
        header: "Profile",
        field: "deviceProfileName",
        filterable: true,
        visible: true,
        renderContent: function (col: ColumnConfig, element: Device) {
            if(element.deviceProfileName){
                return element.deviceProfileName
            }
            return element.deviceProfile
        }
    },{
        header: "Profile ID",
        field: "deviceProfile",
        filterable: true,
        visible: false,
        renderContent: Utils.renderContent
    },{
        header: "Location",
        field: "deviceLocation",
        filterable: true,
        visible: false,
        renderContent: Utils.renderContent
    },{
        header: "Device Class",
        field: "deviceClass",
        filterable: true,
        visible: false,
        renderContent: Utils.renderContent
    },{
        header: "Tags",
        field: "tags",
        filterable: true,
        visible: true,
        renderContent: Utils.renderContent
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: AESDevicesService, public snackBar: MatSnackBar) {
        this.buttons = [];
        var me = this;
        if(!GlobalScope.isViewHidden("Device Commands")){
            this.buttons.push({
                title: "Execute Command", icon: "add_to_home_screen", "selection": ButtonConfig.SelectionType.SINGLE, click: (dt, selected) => {
                    const dialogRef = this.dialog.open(DeviceCommandDialog, {
                        width: '600px',
                        data: {
                            "device" : selected[0]
                        }
                    });
                    var me = this;
                    dialogRef.afterClosed().subscribe((result) =>{
                        if(result == "SUCCESS"){
                            dt.refresh(dt, selected);
                        }
                    });
                }
            });
        }

        this.buttons.push(
        {
            icon: "create",
            selection: ButtonConfig.SelectionType.MULTI,
            title: "Edit Device(s) Configuration",
            click: function(dt, selected){
                if(selected.length > environment.MAX_BULK_DEVICE_OPERATIONS){
                    me.openSnackBar("Can only do bulk operations on " + environment.MAX_BULK_DEVICE_OPERATIONS + " Devices.", undefined);
                    return;
                }
                if(selected.length == 1){
                    const dialogRef = me.dialog.open(DeviceEditDialog, {
                        width: '600px',
                        data: {
                            "instance": selected[0]
                        }
                    });
            
                    dialogRef.afterClosed().subscribe(result => {
                        if (result == "SUCCESS") {
                            dt.refresh(dt, selected);
                        }
                    });
                }
                else{
                    const dialogRef = me.dialog.open(DevicesBulkEditDialog, {
                        width: '600px',
                        data: {
                            "instances": selected
                        }
                    });
            
                    dialogRef.afterClosed().subscribe(result => {
                        if (result == "SUCCESS") {
                            dt.refresh(dt, selected);
                        }
                    });
                }
            }
        },{
            icon: "delete",
            selection: ButtonConfig.SelectionType.MULTI,
            title: "Delete Device(s)",
            click: function(dt, selected){
                if(selected.length > environment.MAX_BULK_DEVICE_OPERATIONS){
                    me.openSnackBar("Can only do bulk operations on " + environment.MAX_BULK_DEVICE_OPERATIONS + " Devices.", undefined);
                    return;
                }
                var message = "Are you sure you want to delete this Device(s)?";
                var devices = [];
                for(let device of selected){
                    devices.push(device.devEui);
                }
                dialog.open(ConfirmDialog, {
                    width: '600px',
                    data: {
                        "title" : "Delete Device(s)",
                        "message" : message,
                        "success": function(){
                        me.httpClient.delete(devices)
                        .subscribe(resp => {
                                me.openSnackBar("Device(s) Deleted!", undefined);
                                dt.refresh(dt, selected);
                            }, (err) => {
                                console.log(err);
                                var message = err.error.message;
                                if(message == undefined){
                                    message = "Unknown";
                                }
                                if(message == "Internal Server Error"){
                                    message = "Server was unable to process your request";
                                }
                                var error = "Error deleting entry: " + message;
                                
                                me.openSnackBar(error, undefined);
                                dt.refresh(dt, selected);
                        });
                        }
                    }
                });
            }
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

<h2 mat-dialog-title>{{editType}} Application</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm" autocomplete="off">
        <mat-form-field>
            <input [disabled]="inProgress || editType == 'Edit'" matInput placeholder="EUI" required [(ngModel)]="instance.appEui" autocomplete="new-password" name="application-edit-appeui-field">
        </mat-form-field>
        <mat-form-field>
            <textarea [disabled]="inProgress" matInput placeholder="Description" required [(ngModel)]="instance.description" name="application-edit-description-field"></textarea>
        </mat-form-field>
        <mat-form-field>
            <mat-select [disabled]="inProgress" placeholder="Mode" [(ngModel)]="instance.mode" name="application-edit-mode">
                <mat-option value="Application Provider">
                    Application Provider
                </mat-option>
                <mat-option value="Developer">
                    Developer
                </mat-option>
                <mat-option value="Custom" *ngIf="isAdminUser()">
                    Custom
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="instance.mode != 'Custom'">
            <input [disabled]="inProgress" type="password" matInput placeholder="API Key" required [(ngModel)]="instance.apiKey" name="application-edit-apiKey" autocomplete="new-password">
        </mat-form-field>
        <mat-form-field *ngIf="isAdminUser() && instance.mode == 'Custom'">
            <mat-select [disabled]="inProgress" placeholder="Connection" [(ngModel)]="instance.connectionId" name="application-edit-connection-field">
                <mat-option *ngFor="let connection of connections" [value]="connection.connectionId">
                    {{connection.host}}{{connection.downlinkPath}} ({{connection.connectionId}})
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isAdminUser()">
            <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Account" (ngModelChange)="processAccountUpdate($event)" [(ngModel)]="instance.acctId" name="application-edit-acctId-field">
                <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                    {{account.name}} ({{account.acctId}})
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
import { Component, Input } from "@angular/core";
import { ViewRegistration } from "aes-common";
import { ColumnConfig, DataTableOptions } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { AESSQLConnectionService, SQLConnection } from "../../services/AESSQLConnectionService";
import { SQLConnectionEditDialog } from "../../dialogs/sqlconnectiondialog/sqlconnectiondialog";
import { MatDialog } from "@angular/material/dialog";
import { Utils } from "../../services/Utils";

@Component({
    template: "<a [routerLink]=\"['details',element.connectionId]\">{{element.host}}</a>"
})
export class SQLLinkRow{
    @Input()
    element: SQLConnection;

    @Input()
    col: ColumnConfig;
}

@Component({
    selector: 'sqlconnections',
    templateUrl: 'sqlconnections.html',
    styleUrls: ['./sqlconnections.scss']
})
export class SQLConnections {
    static view = new ViewRegistration("SQL", "/connections/sql", "power", [], Utils.isAdminUser);

    dtOptions:DataTableOptions  = {pageSize : 100, showSelectionColumn: false, multiSelect: false};

    columnCfgs: ColumnConfig[] = [{
        header: "ID",
        field: "connectionId"
    },{
        header: "Host",
        field: "host",
        defaultSort: true,
        contentComponent: SQLLinkRow
    },{
        header: "User",
        field: "user",
    }, {
        header: "Database",
        field: "defaultDb"
    },{
        header: "Account",
        field: "acctId"
    }, {
        header: "Creation",
        title: "Creation",
        field: "creationDate",
        renderContent: function(col: ColumnConfig, element: SQLConnection){
            var date = new Date(element.creationDate);
            return date.toLocaleString();
        }
    },{
        header: "Port",
        field: "port",
        visible: false
    },{
        header: "Connection",
        title: "The Default Connection ID to use",
        field: "defaultConnectionId",
        visible: false
    }, {
        header: "Connection Type",
        title: "The Default Connection Type",
        field: "connectionType",
        visible: false
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: AESSQLConnectionService) {
        var me = this;
        this.buttons = [ButtonConfig.createAddButton("Create SQL Connection", function(dt, selected){
            const dialogRef = me.dialog.open(SQLConnectionEditDialog, {
                width: '600px',
                data: {}
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }), ButtonConfig.createEditButton("Edit SQL Connection", function(dt, selected){
            const dialogRef = me.dialog.open(SQLConnectionEditDialog, {
                width: '600px',
                data: {
                    "instance": selected[0]
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        })];
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}

<div class="container" fxLayout="column" [fxLayoutGap]="gapSize">
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100">
            <div card-title>
                Event History
            </div>
            <div card-body>
                <datatable #eventsTable tableId="eventConfigTable" [dtOptions]="dtOptions" [customFilter]="customFilter"
                    [searchService]="httpClient" [buttonCfg]="buttons" [columnCfg]="columnCfgs">
                    <div dt-custom-controls>
                        <mat-form-field>
                            <mat-label>State</mat-label>
                            <mat-select [(ngModel)]="selectedState" (selectionChange)="stateChange()">
                              <mat-option *ngFor="let state of eventOptions" [value]="state">
                                {{state}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </datatable>
            </div>
        </senetcard>
    </div>
</div>
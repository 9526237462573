import { Component } from "@angular/core";
import { ViewRegistration } from "aes-common";
import { Utils } from "../../../services/Utils";
import { AESUserAPIKeys } from "./userapikeys";

@Component({
    selector: 'userapikeys',
    templateUrl: 'userapikeys.html',
    styleUrls: ['./userapikeys.scss']
})
export class AESAdminUserAPIKeys extends AESUserAPIKeys {
    static view =  new ViewRegistration("User API Keys", "/configurations/userapikeys", "vpn_key", [], Utils.isAdminUser);
}
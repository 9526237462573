import { Component, Inject} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ColumnConfig, DataTableOptions, DataTable } from "aes-datatables";

@Component({
    selector: 'devdatadialog',
    templateUrl: 'devdatadialog.html',
    styleUrls: ['./devdatadialog.css']
})

export class DevDataDialog {
    dynamicStateOptions: DataTableOptions = { pageSize: 100, multiSelect: true, localSearch: true, 
        localSort: true, showSelectionColumn: false};

        dynamicStateColumnCfgs: ColumnConfig[] = [{
        header: "Property",
        field: "key",
        defaultSort: true
    },{
        header: "Value",
        field: "value"
    },{
        header: "Last Updated",
        field: "lastUpdated",
        renderContent: function (col: ColumnConfig, row) {
            var date = new Date(row.lastUpdated);
            return date.toLocaleString();
        }
    }]
    
    dynamicState: {key: string, value: any}[];

    configuredState: {key: string, value: any}[];
    
    configuredStateOptions: DataTableOptions = { pageSize: 100, multiSelect: true, localSearch: true, 
        localSort: true, showSelectionColumn: false };
    
    configuredStateColumnCfgs: ColumnConfig[] = [{
        header: "Property",
        field: "key",
        defaultSort: true
    },{
        header: "Value",
        field: "value"
    }];

    gapSize: string = "10px";

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DevDataDialog>) {
        this.dynamicState = [];
        this.configuredState = [];
        let dynamicStateProps = data.element['dynamicState'];
        let configuredStateProps = data.element['configuredState'];
        for(const key in dynamicStateProps){
            let row = {key: key, value: dynamicStateProps[key]['value'], lastUpdated: dynamicStateProps[key]['updated']}
            this.dynamicState.push(row);
        }
        for(const key in configuredStateProps){
            let row = {key: key, value: configuredStateProps[key]['value']}
            this.configuredState.push(row);
        }
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }    
}
import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { GlobalScope, ViewRegistration } from 'aes-common';
import { OptionsService } from 'aes-common';
import { HttpClientModule } from '@angular/common/http';
import { DevicesNode } from '../views/devices/devices';
import { AESDevices } from '../views/devices/devices';
import { DeviceDetails } from '../views/devices/devicedetails/devicedetails';
import { AESMaps } from '../views/map/map';
import { AESDeviceEvents } from '../views/devices/events/events';
import { AESSettings } from '../views/settings/settings';
import { EventsConfiguration } from '../views/events/event-config/event-config';
import { EventsNode } from '../views/events/events';
import { Events } from '../views/events/events';
import { MetadataNode } from '../views/metadata/metadata';
import { AESPipelines } from '../views/pipelines/pipelines';
import { PipelineDetails } from '../views/pipelines/pipeline-details/pipeline-details';
import { AESRemoteFunctionBlocks } from '../views/pipelines/remote-function-blocks/remote-function-blocks';
import { FunctionBlockDetails } from '../views/pipelines/remote-function-blocks/function-block-details/function-block-details';
import { AESCodecs } from '../views/pipelines/codecs/codecs';
import { CodecDetails } from '../views/pipelines/codecs/codec-details/codec-details';
import { AESDeviceProfiles } from '../views/device-profiles/device-profiles';
import { DeviceProfileDetails } from '../views/device-profiles/device-profile-details/device-profile-details';
import { AESLogs } from '../views/logs/logs';
import { LogDetails } from '../views/logs/log-details/log-details';
import { AESLogsConfiguration } from '../views/logs/logconfiguration/logconfiguration';
import { APIDocs } from '../views/docs/apidocs/apidocs';
import { AESAccountAPIKeys } from '../views/apikeys/accountapikeys/accountapikeys';
import { AESUserAPIKeys } from '../views/apikeys/userapikeys/userapikeys';
import { AESUsers } from '../views/users/aesusers';
import { Utils } from '../services/Utils';
import { AESAccounts } from '../views/accounts/accounts';
import { SQLConnections } from '../views/connections/sqlconnections';
import { Applications } from '../views/applications/applications';
import { AESSMTPConnections } from '../views/connections/smtpconnections/smtpconnections';
import { AESAdminAccountAPIKeys } from '../views/apikeys/accountapikeys/adminapikeys';
import { AESAdminUserAPIKeys } from '../views/apikeys/userapikeys/adminuserapikeys';
import { ConnectionDetails } from '../views/connections/connectiondetails/connectiondetails';
import { AESLookUp } from '../views/metadata/lookup/lookup';
import { LookUpDetails } from '../views/metadata/lookup/lookup-details/lookup-details';
import { AdminHTTPConnections } from '../views/connections/httpconnections/adminhttpconnections/adminhttpconnections';
import { AdminApplications } from '../views/applications/adminapplications/adminapplications';
import { AESSystemSettings } from '../views/system-settings/system-settings';


export class ExtensionsNode {
    static view = new ViewRegistration("Configurations", "", "extension", []);
}
export class DiagnosticsNode{
    static view = new ViewRegistration("Diagnostics", "", "dynamic_form", []);
}
export class DocsNode{
    static view = new ViewRegistration("API Documentation", "", "library_books", []);
}
export class AdminNode {
    static view = new ViewRegistration("Admin", "", "manage_accounts", [], Utils.isAdminUser);
}
export class ConnectionsNode{
    static view = new ViewRegistration("Connections", "", "dynamic_form", [], Utils.isAdminUser);
}
export const routes: Routes = [
    //{ path: 'dashboard', component: AESMainDashboard},
    { path: 'admin', component: AdminNode, title: AdminNode.view.title },
    { path: 'admin/users', component: AESUsers, title: AESUsers.view.title },
    { path: 'admin/accounts', component: AESAccounts, title: AESAccounts.view.title },
    { path: 'admin/accountapikeys', component: AESAdminAccountAPIKeys, title: AESAdminAccountAPIKeys.view.title },
    { path: 'admin/userapikeys', component: AESAdminUserAPIKeys, title: AESAdminUserAPIKeys.view.title },
    { path: 'admin/syssettings', component: AESSystemSettings, title: AESSystemSettings.view.title },
    { path: 'connections', component: ConnectionsNode, title: ConnectionsNode.view.title },
    { path: 'connections/sql', component: SQLConnections, title: SQLConnections.view.title },
    { path: 'connections/sql/details/:connectionId', component: ConnectionDetails},
    { path: 'connections/http', component: AdminHTTPConnections, title: AdminHTTPConnections.view.title },
    { path: 'connections/applications', component: AdminApplications, title: AdminApplications.view.title },
    { path: 'connections/smtp', component: AESSMTPConnections, title: AESSMTPConnections.view.title },
    { path: 'devices', component: DevicesNode, title: DevicesNode.view.title },
    { path: 'devices/devices', component: AESDevices, title: AESDevices.view.title },
    { path: 'devices/events', component: AESDeviceEvents, title: AESDeviceEvents.view.title },
    { path: 'devices/details/:devEui', component: DeviceDetails},//Locally set in view
    { path: 'devices/map', component: AESMaps, title: AESMaps.view.title},
    { path: 'configurations', component: ExtensionsNode, title: ExtensionsNode.view.title },
    { path: 'configurations/deviceprofiles', component: AESDeviceProfiles, title: AESDeviceProfiles.view.title },
    { path: 'configurations/deviceprofiles/details/:id', component: DeviceProfileDetails },
    { path: 'configurations/pipelines', component: AESPipelines, title: AESPipelines.view.title},
    { path: 'configurations/pipelines/details/:id', component: PipelineDetails},
    { path: 'configurations/pipelines/details/:id/:acctId', component: PipelineDetails},
    { path: 'configurations/functionblocks', component: AESRemoteFunctionBlocks, title: AESRemoteFunctionBlocks.view.title},
    { path: 'configurations/functionblocks/details/:id', component: FunctionBlockDetails},
    { path: 'configurations/functionblocks/details/:id/:acctId', component: FunctionBlockDetails},
    { path: 'configurations/codecs', component: AESCodecs, title: AESCodecs.view.title},
    { path: 'configurations/codecs/details/:id', component: CodecDetails},    
    { path: 'configurations/accountapikeys', component: AESAccountAPIKeys, title: AESAccountAPIKeys.view.title},
    { path: 'configurations/userapikeys', component: AESUserAPIKeys, title: AESUserAPIKeys.view.title},
    { path: 'configurations/applications', component: Applications, title: Applications.view.title },
    { path: 'settings', component: AESSettings, title: 'Settings'}, //No view defined
    { path: 'events', component: EventsNode, title: EventsNode.view.title},
    { path: 'events/history', component: Events, title: Events.view.title},
    { path: 'events/configuration', component: EventsConfiguration, title: EventsConfiguration.view.title},
    { path: 'metadata', component: MetadataNode, title: MetadataNode.view.title},
    { path: "metadata/lookup", component: AESLookUp, title: AESLookUp.view.title},
    { path: "metadata/lookup/details/:id", component: LookUpDetails},
    { path: 'diagnostics', component: DiagnosticsNode, title: DiagnosticsNode.view.title },
    { path: 'diagnostics/logs', component: AESLogs, title: AESLogs.view.title},
    { path: 'diagnostics/logs/details/:id', component: LogDetails},
    { path: 'diagnostics/logconfig', component: AESLogsConfiguration, title: AESLogsConfiguration.view.title},
    { path: 'docs', component: DocsNode, title: DocsNode.view.title},
    { path: 'docs/apidocs', component: APIDocs, title: APIDocs.view.title},
    {
        path: '',
        redirectTo: '/devices/devices',
        pathMatch: 'full'
    }
];

const backListViews = [];

@NgModule({
    imports: [RouterModule.forRoot(routes), HttpClientModule],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(private router: Router, private rootScope:GlobalScope, private options: OptionsService) {
        var lastView = options.getItem(OptionsService.SENET_LASTVIEW_KEY, "");
        if(window.location == undefined || window.location.pathname == "/"){
            router.navigateByUrl(lastView);
        }
        router.events.subscribe((val) => {
            if(val instanceof NavigationEnd){ //Show loading indicator on nav
                //rootScope.startLoading();
                if(backListViews.indexOf(val.url) < 0){
                    options.setItem(OptionsService.SENET_LASTVIEW_KEY, val.url);
                }
            }
            else{
                //rootScope.stopLoading();
            }
        });
      }
 }

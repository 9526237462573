<h2 mat-dialog-title>{{editType}} Devices</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
        <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm" class="devices-edit-form">
            <div class="chk-box-div">
                <div [fxFlex]="100" fxFlex.xs="100">
                    <div [fxFlex]="18" fxFlex.xs="18" class="check-box-div">
                        <mat-checkbox [disabled]="inProgress" placeholder="Edit Owner" [(ngModel)]="editOwner" (ngModelChange)="onOwnerEditChkChange($event)"
                                name="devices-edit-owner-chk"></mat-checkbox>
                    </div>
                    <mat-form-field  [fxFlex]="82" fxFlex.xs="82">
                        <input [disabled]="inProgress || !editOwner" matInput placeholder="Owner" [(ngModel)]="instance.owner" name="devices-owner-field">
                    </mat-form-field>
                </div>
            </div>
            <div class="chk-box-div">
                <div [fxFlex]="100" fxFlex.xs="100">
                    <div [fxFlex]="18" fxFlex.xs="18" class="check-box-div">
                        <mat-checkbox [disabled]="inProgress" placeholder="Edit Type" [(ngModel)]="editDevType" (ngModelChange)="onDevTypeEditChkChange($event)"
                                name="devices-edit-devType-chk"></mat-checkbox>
                    </div>
                    <mat-form-field  [fxFlex]="82" fxFlex.xs="82">
                        <input [disabled]="inProgress || !editDevType" matInput placeholder="Type" [(ngModel)]="instance.devType" name="devices-devType-field">
                    </mat-form-field>
                </div>
            </div>
            <div class="chk-box-div">
                <div [fxFlex]="100" fxFlex.xs="100">
                    <div [fxFlex]="18" fxFlex.xs="18" class="check-box-div">
                        <mat-checkbox [disabled]="inProgress" placeholder="Edit Device Profile" [(ngModel)]="editDeviceProfile" (ngModelChange)="onDeviceProfileEditChkChange($event)"
                                name="devices-edit-device-profile-chk"></mat-checkbox>
                    </div>
                    <mat-form-field  [fxFlex]="82" fxFlex.xs="82">
                        <mat-select [disabled]="inProgress || !editDeviceProfile" placeholder="Device Profile" [(ngModel)]="instance.deviceProfile" name="devices-edit-deviceProfile-field" (ngModelChange)="onDeviceProfileEditChange($event)">
                            <mat-option *ngFor="let profile of profiles" [value]="profile.id">
                                {{profile.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress || saveBlocked" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
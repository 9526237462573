import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface Event {
    id: number,
    eventConfigId : string,
    owner: string,
    state: string,
    information: string,
    openDate: string,
    clearedDate: string,
    count: number,
    lastOccurrence: string
}


export interface CustomEventsFilter {
    state? : string
}

@Injectable()
export class EventsService implements DataTableSearchService<Event>,CRUDService<Event>{

    create(object: Event): Observable<Message> {
        throw new Error("Not Supported");
    }
    edit(object: Event): Observable<Message> {
        const href = 'events';
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object);
    }
    delete(object: Event): Observable<Message> {
        const href ='events';
        var params = new HttpParams().set("id", object.id);
        var requestUrl = `${href}`

        return this.http.delete<Message>(requestUrl, {
            params : params
        });
    }
    

    constructor(private http: HttpClient) { }
    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: CustomEventsFilter): Observable<SenetData<Event>> {
        const href = 'events';
        var offset = page * limit;
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(sort != undefined){
            params = params.set("sortField", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortOrder", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }
        if(customFilter){
            if(customFilter.state){
                params = params.set("state", customFilter.state);
            }
        }
        return this.http.get<SenetData<Event>>(requestUrl, { params : params });
    }

    
    getOpenEvents(sort: string, order: string,limit: number): Observable<SenetData<Event>> {
        const href = 'events';
        var requestUrl = `${href}`;
        var params = new HttpParams().set("state", "Open");
        if(sort != undefined){
            params.set("sortField", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortOrder", order);          
        }

        return this.http.get<SenetData<Event>>(requestUrl, { params : params });
    }
}
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class GlobalScope {
    static loading:Subject<boolean> = new Subject<boolean>();
    static loading$ = GlobalScope.loading.asObservable();
    static autoRefreshVal: boolean = false;
    static autoRefresh:Subject<boolean> = new Subject<boolean>();
    static autoRefresh$ = GlobalScope.autoRefresh.asObservable();
    static sideNavChange:Subject<boolean> = new Subject<boolean>();
    static sideNavChange$ = GlobalScope.sideNavChange.asObservable();
    static detailsSidePanelChange:Subject<boolean> = new Subject<boolean>();
    static detailsSidePanelChange$ = GlobalScope.detailsSidePanelChange.asObservable();
    static outstandingLoading:number = 0;
    static refreshInterval: number = 30000;
    static ACE_THEME = "ace/theme/eclipse";

    static HTTPMethods = [
        "GET", "POST", "PUT", "OPTION", "DELETE", "HEAD", "PATCH"
    ];

    static registeredViews: String[] = ['Device Commands'];
    static hiddenViews: String[] = [];

    constructor() {
    }

    static addRegisteredView(title:String){
        if(title !== 'System Settings' && title !== 'Admin'){
            if(GlobalScope.registeredViews.indexOf(title) == -1){
                GlobalScope.registeredViews.push(title);
            }
        }
    }
    static getRegisteredViews(){
        return GlobalScope.registeredViews;
    }
    static setHiddenViews(hiddenViews:String[]){
        GlobalScope.hiddenViews = hiddenViews;
    }
    static isViewHidden(viewTitle){
        let hidden = GlobalScope.hiddenViews.indexOf(viewTitle) > -1;
        return hidden;
    }
    startLoading() {
        GlobalScope.outstandingLoading += 1;
        if(GlobalScope.outstandingLoading == 1){
            GlobalScope.loading.next(true);
        }
    }

    stopLoading() {
        GlobalScope.outstandingLoading -= 1;
        if(GlobalScope.outstandingLoading < 0){
            GlobalScope.outstandingLoading = 0;
        }
        if(GlobalScope.outstandingLoading == 0){
            GlobalScope.loading.next(false);
        }
    }
}

import { Observable, throwError } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { AppStateService } from './AppStateService';
import { GlobalScope, OptionsService } from 'aes-common';
import { Utils } from './Utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../environments/environment';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public stateService: AppStateService, public globalScope: GlobalScope, public snackBar: MatSnackBar, public options: OptionsService) {
    this.handleError = this.handleError.bind(this);    
  }

  private handleError(error: Response | any) {
    console.error(error);
    if(!(error.url.indexOf("/users/session") >= 0)){
      if (error.status == 403 || error.status == 0) {
        console.log(error.status);
        this.snackBar.open("You are no longer authorized. Please login again", '', {
          duration: 10000
        });
        this.stateService.triggerLogout();
      }
    }
    return throwError(error);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(request);
    if(request.url.indexOf("versionInfo.json") >= 0){
      return next.handle(request)
      .pipe(
        catchError(this.handleError)
      );
    }
    if(request.url.indexOf(Utils.DEVICES_API_ENDPOINT) < 0){
      var url = Utils.DEVICES_API_ENDPOINT;
      if(!url.endsWith("/")){
         url += "/"
      }
      if(request.url.startsWith("/")){
        url += request.url.substring(1);
      }
      else{
        url += request.url;
      }
      request = request.clone({
        url: url
      });
    }
    if(Utils.DEVICES_API_KEY){
      request = request.clone({
        headers: new HttpHeaders({
          "x-api-key": Utils.DEVICES_API_KEY
        })
      });
    }
    else if(!environment.USE_COOKIES && Utils.isAuthTokenValid(this.options)){
      request = request.clone({
        headers: new HttpHeaders({
          "x-authentication": Utils.getAuthToken(this.options)
        })
      });
    }
    else{
      request = request.clone({
        withCredentials: true
      });
    }
    return next.handle(request)
      .pipe(
        catchError(this.handleError)
      );
  }
}
import { Component, Input, Host } from "@angular/core";
import { DetailsView } from "../detailsview";
import { Subscription } from "rxjs";

@Component({
    selector: 'detailsinfopnl',
    templateUrl: 'detailsinfopnl.html',
    styleUrls: ['./detailsinfopnl.scss']
})
export class DetailsInfoPanel {

    @Input()
    public id: any;

    public selection: any;

    parent: DetailsView

    subscription: Subscription;
    
    constructor(@Host() parent: DetailsView){
        this.parent = parent;
        this.selection = parent.selection;
    }

    ngOnInit(){
        this.parent.sideselectionEvt$.subscribe(selection => {
            this.selection = selection;
        });
    }

    ngOnDestroy(){
        if(this.subscription){
             this.subscription.unsubscribe();
        }
    }
}